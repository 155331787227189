import { joinPaths } from '~/core/ts/util/string'
import { addLanguageToURL, translate } from '~/core/ts/util/translate'

import { useSessionLocation } from './useSessionLocation'
import { useFetchProxy } from './useFetchProxy'

import { Promotion } from '~/core/ts/entity/Promotion'
import { useLangSelector } from './useLangSelector'
import { Tag } from '~/core/ts/entity/Tag'

import config from '~/core/ts/server-config'
import { useContext } from './useContext'

export function useLocatedLinks() {
  const runtimeConfig = useRuntimeConfig()

  const sessionLocation = useSessionLocation()
  const context = useContext()

  const { currentLang } = useLangSelector()

  const christmasPromo = ref<Tag | null>(null)
  const valentinesPromo = ref<Tag | null>(null)

  async function fetchChristmasPromo() {
    const staticChristmas = Promotion.getChristmasSpecial(currentLang)

    if (config.promos.isChristmas) {
      switch (context.type.value) {
        case 'dynamic':
        case 'redirections': {
          christmasPromo.value = staticChristmas
          return
        }
      }

      const chrst = await useFetchProxy<Promotion>(
        '/api/promotions/promotion-by-uuid',
        {
          method: 'post',
          body: {
            uuid : staticChristmas.uuid,
            lang: currentLang,
          }
        }
      )

      christmasPromo.value = chrst
    }
  }

  async function fetchValentinesPromo() {
    if (config.promos.isValentines) {
      const staticValentines = Promotion.getValentines(currentLang)
      switch (context.type.value) {
        case 'dynamic':
        case 'redirections': {
          valentinesPromo.value = staticValentines
          return
        }
      }

      const valentines = await useFetchProxy<Promotion>(
        '/api/promotions/promotion-by-uuid',
        {
          method: 'post',
          body: {
            uuid : staticValentines.uuid,
            lang: currentLang,
          }
        }
      )

      valentinesPromo.value = valentines
    }
  }

  const baseSpaLink = computed(() => {
    const defaultURL = addLanguageToURL(translate(runtimeConfig.public.urls.spasBaseUrlByLang), currentLang)
    if (!sessionLocation.storedLocation.value) {
      return defaultURL
    }

    if (!sessionLocation.storedLocation.value.urls.spas.url) {
      return defaultURL
    }

    return sessionLocation.storedLocation.value.urls.spas.url
  })

  const baseGetAwayLink = computed(() => {
    const defaultURL = addLanguageToURL(translate(runtimeConfig.public.urls.getAwayBaseUrlByLang), currentLang)

    if (!sessionLocation.storedLocation.value) {
      return defaultURL
    }

    if (!sessionLocation.storedLocation.value.urls.getAways.url) {
      return defaultURL
    }

    return sessionLocation.storedLocation.value.urls.getAways.url
  })

  const promoBlackFridayLink = computed(() => {
    const promoBase = translate(runtimeConfig.public.urls.promotionBaseUrlByLang)
    const defaultURL = addLanguageToURL(joinPaths(promoBase, 'black-friday'), currentLang)

    const locationInSession = sessionLocation.storedLocation.value
    if (!locationInSession) {
      return defaultURL
    }

    const blackFriday = Promotion.getBlackFriday()

    const promoURLInfo = locationInSession.urls.promotions[blackFriday.uuid]
    if (!promoURLInfo) {
      return defaultURL
    }

    if (!promoURLInfo.count) {
      return defaultURL
    }

    return promoURLInfo.url
  })

  const promoChristmasLink = computed(() => {
    const chrtPromo = christmasPromo.value
    if (!chrtPromo) {
      return ''
    }

    const promoBase = translate(runtimeConfig.public.urls.promotionBaseUrlByLang)
    const defaultURL = addLanguageToURL(joinPaths(promoBase, chrtPromo.slug), currentLang)

    const locationInSession = sessionLocation.storedLocation.value
    if (!locationInSession) {
      return defaultURL
    }

    const promoURLInfo = locationInSession.urls.promotions[chrtPromo.uuid]
    if (!promoURLInfo) {
      return defaultURL
    }

    if (!promoURLInfo.count) {
      return defaultURL
    }

    return promoURLInfo.url
  })

  const promoValentinesLink = computed(() => {
    const vlntnsPromo = valentinesPromo.value
    if (!vlntnsPromo) {
      return ''
    }

    const promoBase = translate(runtimeConfig.public.urls.promotionBaseUrlByLang)
    const defaultURL = addLanguageToURL(joinPaths(promoBase, vlntnsPromo.slug), currentLang)

    const locationInSession = sessionLocation.storedLocation.value
    if (!locationInSession) {
      return defaultURL
    }

    const promoURLInfo = locationInSession.urls.promotions[vlntnsPromo.uuid]
    if (!promoURLInfo) {
      return defaultURL
    }

    if (!promoURLInfo.count) {
      return defaultURL
    }

    return promoURLInfo.url
  })

  return {
    fetchChristmasPromo,
    fetchValentinesPromo,

    baseSpaLink,
    baseGetAwayLink,

    promoBlackFridayLink,
    promoChristmasLink,
    promoValentinesLink,
  }
}
