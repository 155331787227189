
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as indexOgEmz8D99fMeta } from "/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as buyerMAXBgtO8k3Meta } from "/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/checkout/buyer.vue?macro=true";
import { default as paymentWeWLlqL1pCMeta } from "/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/checkout/payment.vue?macro=true";
import { default as confirmationDigp0H1wxvMeta } from "/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/confirmation.vue?macro=true";
import { default as kohawRxhKx19Meta } from "/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/ko.vue?macro=true";
import { default as startnd7U58m5KMMeta } from "/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkoutS4RYYDumr9Meta } from "/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as buyerLNg7OabQy0Meta } from "/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/checkout/buyer.vue?macro=true";
import { default as paymentzEDujp2tcoMeta } from "/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/checkout/payment.vue?macro=true";
import { default as confirmation26JZQI4AsMMeta } from "/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/confirmation.vue?macro=true";
import { default as challenge8CQCZhFbcZMeta } from "/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/redsys/challenge.vue?macro=true";
import { default as startKVvgCARaoBMeta } from "/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as index1xrNFehNfOMeta } from "/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/index.vue?macro=true";
import { default as GetAwayList_46pagetXlt50kBGrMeta } from "/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue?macro=true";
import { default as GetAwayDetail_46pagezm2QR8BuUSMeta } from "/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue?macro=true";
export default [
  {
    name: indexOgEmz8D99fMeta?.name ?? "lang",
    path: indexOgEmz8D99fMeta?.path ?? "/:lang?",
    props: indexOgEmz8D99fMeta?.props ?? false,
    meta: indexOgEmz8D99fMeta || {},
    alias: indexOgEmz8D99fMeta?.alias || [],
    redirect: indexOgEmz8D99fMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: buyerMAXBgtO8k3Meta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyerMAXBgtO8k3Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyerMAXBgtO8k3Meta?.props ?? false,
    meta: buyerMAXBgtO8k3Meta || {},
    alias: buyerMAXBgtO8k3Meta?.alias || [],
    redirect: buyerMAXBgtO8k3Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/checkout/buyer.vue")
  },
  {
    name: paymentWeWLlqL1pCMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: paymentWeWLlqL1pCMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: paymentWeWLlqL1pCMeta?.props ?? false,
    meta: paymentWeWLlqL1pCMeta || {},
    alias: paymentWeWLlqL1pCMeta?.alias || [],
    redirect: paymentWeWLlqL1pCMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/checkout/payment.vue")
  },
  {
    name: confirmationDigp0H1wxvMeta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmationDigp0H1wxvMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmationDigp0H1wxvMeta?.props ?? false,
    meta: confirmationDigp0H1wxvMeta || {},
    alias: confirmationDigp0H1wxvMeta?.alias || [],
    redirect: confirmationDigp0H1wxvMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/confirmation.vue")
  },
  {
    name: kohawRxhKx19Meta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: kohawRxhKx19Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: kohawRxhKx19Meta?.props ?? false,
    meta: kohawRxhKx19Meta || {},
    alias: kohawRxhKx19Meta?.alias || [],
    redirect: kohawRxhKx19Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/ko.vue")
  },
  {
    name: startnd7U58m5KMMeta?.name ?? "lang-purchase-start",
    path: startnd7U58m5KMMeta?.path ?? "/:lang?/purchase/start",
    props: startnd7U58m5KMMeta?.props ?? false,
    meta: startnd7U58m5KMMeta || {},
    alias: startnd7U58m5KMMeta?.alias || [],
    redirect: startnd7U58m5KMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkoutS4RYYDumr9Meta?.name ?? "lang-service-booking-checkout",
    path: checkoutS4RYYDumr9Meta?.path ?? "/:lang?/service-booking/checkout",
    props: checkoutS4RYYDumr9Meta?.props ?? false,
    meta: checkoutS4RYYDumr9Meta || {},
    alias: checkoutS4RYYDumr9Meta?.alias || [],
    redirect: checkoutS4RYYDumr9Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: buyerLNg7OabQy0Meta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyerLNg7OabQy0Meta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyerLNg7OabQy0Meta?.props ?? false,
    meta: buyerLNg7OabQy0Meta || {},
    alias: buyerLNg7OabQy0Meta?.alias || [],
    redirect: buyerLNg7OabQy0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/checkout/buyer.vue")
  },
  {
    name: paymentzEDujp2tcoMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: paymentzEDujp2tcoMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: paymentzEDujp2tcoMeta?.props ?? false,
    meta: paymentzEDujp2tcoMeta || {},
    alias: paymentzEDujp2tcoMeta?.alias || [],
    redirect: paymentzEDujp2tcoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/checkout/payment.vue")
  },
  {
    name: confirmation26JZQI4AsMMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmation26JZQI4AsMMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmation26JZQI4AsMMeta?.props ?? false,
    meta: confirmation26JZQI4AsMMeta || {},
    alias: confirmation26JZQI4AsMMeta?.alias || [],
    redirect: confirmation26JZQI4AsMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/confirmation.vue")
  },
  {
    name: challenge8CQCZhFbcZMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challenge8CQCZhFbcZMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challenge8CQCZhFbcZMeta?.props ?? false,
    meta: challenge8CQCZhFbcZMeta || {},
    alias: challenge8CQCZhFbcZMeta?.alias || [],
    redirect: challenge8CQCZhFbcZMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/redsys/challenge.vue")
  },
  {
    name: startKVvgCARaoBMeta?.name ?? "lang-voucher-start",
    path: startKVvgCARaoBMeta?.path ?? "/:lang?/voucher/start",
    props: startKVvgCARaoBMeta?.props ?? false,
    meta: startKVvgCARaoBMeta || {},
    alias: startKVvgCARaoBMeta?.alias || [],
    redirect: startKVvgCARaoBMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: index1xrNFehNfOMeta?.name ?? "index",
    path: index1xrNFehNfOMeta?.path ?? "/",
    props: index1xrNFehNfOMeta?.props ?? false,
    meta: index1xrNFehNfOMeta || {},
    alias: index1xrNFehNfOMeta?.alias || [],
    redirect: index1xrNFehNfOMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/pages/index.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 0",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 1",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/a-coruna/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 2",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/a-coruna/a-coruna/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 3",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/a-coruna/cee/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 4",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/a-coruna/ferrol/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 5",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/a-coruna/muxia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 6",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/a-coruna/naron/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 7",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/a-coruna/santiago-de-compostela/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 8",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/a-coruna/teo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 9",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alava/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 10",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alava/laguardia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 11",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alava/vitoria-gasteiz/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 12",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/albacete/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 13",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/albacete/almansa/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 14",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/albacete/casas-ibanez/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 15",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alicante/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 16",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alicante/algorfa/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 17",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alicante/alguena/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 18",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alicante/alicante/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 19",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alicante/altea/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 20",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alicante/benidorm/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 21",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alicante/benitachell/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 22",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alicante/busot/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 23",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alicante/calp/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 24",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alicante/crevillent/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 25",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alicante/denia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 26",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alicante/elche/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 27",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alicante/finestrat/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 28",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alicante/guardamar-del-segura/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 29",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alicante/l-alfas-del-pi/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 30",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alicante/la-nucia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 31",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alicante/mutxamel/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 32",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alicante/orihuela/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 33",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alicante/rojales/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 34",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alicante/teulada/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 35",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/alicante/torrevieja/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 36",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/almeria/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 37",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/almeria/almeria/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 38",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/almeria/el-ejido/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 39",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/almeria/mojacar/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 40",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/almeria/nijar/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 41",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/almeria/pechina/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 42",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/almeria/roquetas-de-mar/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 43",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/almeria/vera/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 44",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/almeria/vicar/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 45",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/andalucia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 46",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/aragon/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 47",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/asturias/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 48",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/asturias/cabranes/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 49",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/asturias/cangas-del-narcea/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 50",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/asturias/gijon/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 51",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/asturias/langreo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 52",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/asturias/llanes/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 53",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/asturias/navia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 54",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/asturias/norena/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 55",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/asturias/onis/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 56",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/asturias/oviedo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 57",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/asturias/parres/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 58",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/asturias/soto-del-barco/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 59",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/asturias/villaviciosa/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 60",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/aveiro/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 61",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/aveiro/anadia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 62",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/aveiro/castelo-de-paiva/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 63",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/aveiro/espinho/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 64",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/aveiro/luso/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 65",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/aveiro/murtosa/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 66",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/avila/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 67",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/avila/hoyos-del-espino/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 68",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/avila/la-torre/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 69",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/azores/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 70",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/azores/angra-do-heroismo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 71",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/azores/furnas/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 72",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/azores/ponta-delgada/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 73",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/badajoz/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 74",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/badajoz/merida/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 75",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/badajoz/puebla-de-sancho-perez/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 76",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/barcelona/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 77",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/barcelona/arenys-de-mar/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 78",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/barcelona/barcelona/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 79",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/barcelona/berga/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 80",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/barcelona/caldes-de-montbui/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 81",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/barcelona/calella/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 82",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/barcelona/castelldefels/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 83",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/barcelona/granollers/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 84",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/barcelona/l-hospitalet-de-llobregat/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 85",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/barcelona/la-garriga/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 86",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/barcelona/mataro/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 87",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/barcelona/palafolls/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 88",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/barcelona/pineda-de-mar/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 89",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/barcelona/sant-boi-de-llobregat/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 90",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/barcelona/sant-esteve-sesrovires/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 91",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/barcelona/sant-fruitos-de-bages/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 92",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/barcelona/santa-susanna/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 93",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/barcelona/seva/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 94",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/barcelona/sitges/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 95",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/barcelona/vallromanes/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 96",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/barcelona/vilafranca-del-penedes/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 97",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/barcelona/vilassar-de-dalt/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 98",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/barcelona/vilassar-de-mar/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 99",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/beja/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 100",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/beja/vila-nova-de-milfontes/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 101",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/braga/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 102",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/braga/braga/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 103",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/braga/guimaraes/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 104",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/burgos/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 105",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/burgos/aranda-de-duero/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 106",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/burgos/fresnillo-de-las-duenas/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 107",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/burgos/fuentespina/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 108",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/caceres/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 109",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/caceres/caceres/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 110",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/caceres/hervas/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 111",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/caceres/talayuela/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 112",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cadiz/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 113",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cadiz/benalup-casas-viejas/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 114",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cadiz/cadiz/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 115",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cadiz/chiclana-de-la-frontera/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 116",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cadiz/chipiona/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 117",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cadiz/conil-de-la-frontera/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 118",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cadiz/el-puerto-de-santa-maria/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 119",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cadiz/jerez-de-la-frontera/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 120",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cadiz/medina-sidonia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 121",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cadiz/novo-sancti-petri/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 122",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cadiz/rota/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 123",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cadiz/san-roque/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 124",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cadiz/tarifa/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 125",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cadiz/vejer-de-la-frontera/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 126",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/canarias/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 127",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cantabria/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 128",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cantabria/cabuerniga/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 129",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cantabria/comillas/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 130",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cantabria/corvera-de-toranzo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 131",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cantabria/entrambasaguas/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 132",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cantabria/marina-de-cudeyo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 133",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cantabria/medio-cudeyo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 134",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cantabria/noja/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 135",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cantabria/penarrubia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 136",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cantabria/potes/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 137",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cantabria/puente-viesgo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 138",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cantabria/ruesga/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 139",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cantabria/santander/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 140",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cantabria/santillana-del-mar/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 141",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cantabria/suances/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 142",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cantabria/torrelavega/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 143",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/castellon/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 144",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/castellon/alcala-de-xivert/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 145",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/castellon/la-vilavella/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 146",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/castellon/montanejos/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 147",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/castellon/oropesa-del-mar/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 148",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/castellon/peniscola/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 149",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/castellon/vinaros/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 150",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/castelo-branco/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 151",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/castelo-branco/covilha/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 152",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/castelo-branco/fundao/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 153",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/castilla-la-mancha/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 154",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/castilla-y-leon/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 155",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cataluna/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 156",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/ceuta/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 157",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/ceuta/ceuta/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 158",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/ciudad-autonoma-de-ceuta/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 159",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/ciudad-real/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 160",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/ciudad-real/alcazar-de-san-juan/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 161",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/ciudad-real/almagro/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 162",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/ciudad-real/ciudad-real/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 163",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/ciudad-real/torrenueva/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 164",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/coimbra/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 165",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/coimbra/arganil/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 166",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/coimbra/coimbra/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 167",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/coimbra/figueira-da-foz/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 168",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/comunidad-autonoma-de-cantabria/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 169",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/comunidad-autonoma-de-la-rioja/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 170",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/comunidad-de-madrid/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 171",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/comunidad-foral-de-navarra/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 172",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/comunidad-valenciana/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 173",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cordoba/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 174",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cordoba/cabra/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 175",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cordoba/cordoba/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 176",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cordoba/villaharta/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 177",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cuenca/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 178",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/cuenca/belmonte/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 179",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/distrito-de-aveiro/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 180",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/distrito-de-beja/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 181",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/distrito-de-braga/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 182",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/distrito-de-castelo-branco/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 183",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/distrito-de-coimbra/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 184",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/distrito-de-evora/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 185",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/distrito-de-faro/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 186",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/distrito-de-guarda/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 187",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/distrito-de-leiria/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 188",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/distrito-de-lisboa/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 189",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/distrito-de-oporto/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 190",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/distrito-de-portalegre/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 191",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/distrito-de-santarem/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 192",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/distrito-de-setubal/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 193",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/distrito-de-viana-do-castelo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 194",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/distrito-de-vila-real/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 195",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/distrito-de-viseu/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 196",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/evora/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 197",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/evora/arraiolos/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 198",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/evora/evora/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 199",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/evora/vila-vicosa/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 200",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/extremadura/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 201",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/faro/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 202",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/faro/albufeira/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 203",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/faro/almancil/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 204",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/faro/altura/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 205",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/faro/alvor/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 206",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/faro/armacao-de-pera/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 207",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/faro/carvoeiro/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 208",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/faro/estombar/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 209",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/faro/faro/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 210",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/faro/ferragudo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 211",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/faro/lagos/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 212",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/faro/monchique/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 213",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/faro/monte-gordo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 214",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/faro/olhao/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 215",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/faro/olhos-de-agua/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 216",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/faro/parchal/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 217",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/faro/pera/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 218",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/faro/porches/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 219",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/faro/portimao/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 220",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/faro/quarteira/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 221",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/faro/tavira/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 222",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/faro/vila-nova-de-cacela/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 223",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/faro/vilamoura/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 224",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 225",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/antigua/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 226",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/la-oliva/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 227",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/pajara/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 228",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/galicia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 229",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 230",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/alp/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 231",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/begur/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 232",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/blanes/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 233",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/caldes-de-malavella/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 234",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/calonge/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 235",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/castell-platja-daro/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 236",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/el-port-de-la-selva/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 237",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/girona/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 238",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/llado/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 239",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/llanars/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 240",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/lloret-de-mar/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 241",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/navata/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 242",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/palafrugell/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 243",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/peralada/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 244",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/puigcerda/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 245",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/riudellots-de-la-selva/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 246",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/roses/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 247",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/sant-feliu-de-guixols/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 248",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/santa-coloma-de-farners/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 249",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/santa-cristina-daro/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 250",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/torrent/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 251",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/torroella-de-montgri/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 252",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/toses/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 253",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/tossa-de-mar/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 254",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/girona/vilademuls/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 255",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 256",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/agaete/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 257",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/las-palmas-de-gran-canaria/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 258",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/maspalomas/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 259",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/mogan/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 260",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/san-agustin-maspalomas/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 261",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/san-bartolome-de-tirajana/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 262",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/tejeda/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 263",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/tuineje/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 264",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/granada/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 265",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/granada/almunecar/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 266",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/granada/alpujarra-de-la-sierra/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 267",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/granada/cortes-y-graena/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 268",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/granada/granada/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 269",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/granada/guadix/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 270",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/granada/lanjaron/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 271",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/granada/loja/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 272",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/granada/monachil/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 273",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/granada/otivar/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 274",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/granada/zujar/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 275",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/guadalajara/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 276",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/guadalajara/abanades/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 277",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/guadalajara/siguenza/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 278",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/guarda/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 279",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/guarda/ajuda/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 280",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/guarda/alcantara/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 281",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/guarda/alvoco-da-serra/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 282",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/guarda/coracao-de-jesus/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 283",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/guarda/santa-maria-de-belem/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 284",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/guarda/sao-domingos-de-benfica/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 285",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/guarda/sao-joao-de-deus/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 286",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/guarda/sao-jorge-de-arroios/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 287",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/guarda/sao-mamede/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 288",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/guarda/sao-sebastiao-da-pedreira/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 289",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/guipuzcoa/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 290",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/guipuzcoa/aizarnazabal/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 291",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/guipuzcoa/donostia-san-sebastian/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 292",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/guipuzcoa/orio/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 293",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huelva/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 294",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huelva/aracena/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 295",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huelva/ayamonte/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 296",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huelva/cartaya/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 297",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huelva/costa-occidental/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 298",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huelva/isla-cristina/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 299",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huelva/lepe/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 300",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huelva/moguer/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 301",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huesca/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 302",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huesca/ainsa-sobrarbe/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 303",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huesca/barbastro/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 304",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huesca/benasque/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 305",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huesca/biescas/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 306",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huesca/boltana/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 307",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huesca/canfranc/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 308",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huesca/graus/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 309",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huesca/huesca/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 310",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huesca/jaca/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 311",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huesca/monzon/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 312",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huesca/panticosa/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 313",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huesca/sabinanigo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 314",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huesca/sallent-de-gallego/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 315",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huesca/santa-cruz-de-la-seros/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 316",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/huesca/torla/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 317",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/ibiza/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 318",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/ibiza/ibiza/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 319",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/ibiza/sant-antoni-de-portmany/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 320",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/ibiza/sant-joan-de-labritja/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 321",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/isla-de-el-hierro/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 322",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/isla-de-el-hierro/frontera/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 323",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/islas-baleares/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 324",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/jaen/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 325",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/jaen/chilluevar/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 326",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/jaen/marmolejo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 327",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/jaen/pozo-alcon/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 328",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/jaen/ubeda/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 329",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/la-gomera/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 330",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/la-gomera/san-sebastian-de-la-gomera/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 331",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/la-palma/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 332",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/la-palma/brena-baja/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 333",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/la-palma/tazacorte/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 334",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/la-rioja/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 335",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/la-rioja/aldeanueva-de-ebro/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 336",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/la-rioja/arnedillo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 337",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/la-rioja/gravalos/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 338",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/la-rioja/logrono/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 339",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lanzarote/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 340",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lanzarote/arrecife/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 341",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lanzarote/puerto-del-carmen/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 342",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lanzarote/teguise/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 343",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lanzarote/tias/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 344",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lanzarote/yaiza/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 345",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/leiria/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 346",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/leiria/batalha/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 347",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/leiria/bombarral/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 348",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/leiria/marinha-grande/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 349",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/leiria/monte-real/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 350",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/leiria/nazare/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 351",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/leiria/obidos/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 352",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/leiria/peniche/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 353",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/leiria/vestiaria/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 354",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/leiria/vieira-de-leiria/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 355",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/leon/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 356",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/leon/cimanes-del-tejar/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 357",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/leon/cistierna/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 358",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/leon/leon/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 359",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/leon/sena-de-luna/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 360",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lisboa/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 361",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lisboa/carcavelos/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 362",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lisboa/cascais/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 363",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lisboa/estoril/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 364",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lisboa/linda-a-velha/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 365",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lisboa/lisboa/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 366",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lisboa/moscavide-e-portela/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 367",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lisboa/sintra/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 368",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lisboa/torres-vedras/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 369",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lleida/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 370",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lleida/alt-aneu/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 371",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lleida/canejan/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 372",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lleida/la-vall-de-boi/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 373",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lleida/llavorsi/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 374",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lleida/lleida/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 375",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lleida/naut-aran/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 376",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lleida/prullans/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 377",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lleida/rialp/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 378",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lleida/solsona/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 379",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lleida/vallbona-de-les-monges/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 380",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lleida/vielha-e-mijaran/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 381",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lugo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 382",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lugo/burela/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 383",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lugo/foz/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 384",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lugo/lugo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 385",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lugo/palas-de-rei/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 386",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lugo/panton/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 387",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lugo/vilalba/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 388",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/lugo/viveiro/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 389",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/madeira/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 390",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/madeira/camara-de-lobos/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 391",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/madeira/canical/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 392",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/madeira/canico/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 393",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/madeira/funchal/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 394",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/madeira/porto-santo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 395",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/madrid/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 396",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/madrid/alcala-de-henares/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 397",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/madrid/aranjuez/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 398",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/madrid/collado-villalba/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 399",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/madrid/madrid/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 400",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/madrid/pinto/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 401",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/malaga/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 402",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/malaga/antequera/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 403",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/malaga/arriate/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 404",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/malaga/benahavis/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 405",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/malaga/benalmadena/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 406",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/malaga/benamocarra/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 407",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/malaga/carratraca/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 408",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/malaga/casares/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 409",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/malaga/estepona/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 410",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/malaga/fuengirola/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 411",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/malaga/istan/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 412",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/malaga/malaga/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 413",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/malaga/marbella/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 414",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/malaga/mijas/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 415",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/malaga/nerja/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 416",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/malaga/ojen/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 417",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/malaga/ronda/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 418",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/malaga/torremolinos/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 419",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/malaga/torrox/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 420",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/malaga/vinuela/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 421",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/mallorca/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 422",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/mallorca/alcudia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 423",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/mallorca/cala-millor/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 424",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/mallorca/calvia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 425",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/mallorca/campos/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 426",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/mallorca/capdepera/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 427",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/mallorca/costa-los-pinos/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 428",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/mallorca/esporles/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 429",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/mallorca/inca/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 430",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/mallorca/llucmajor/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 431",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/mallorca/montuiri/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 432",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/mallorca/palma/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 433",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/mallorca/pollenca/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 434",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/mallorca/sant-llorenc-des-cardassar/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 435",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/mallorca/santa-margalida/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 436",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/mallorca/santanyi/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 437",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/mallorca/sineu/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 438",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/mallorca/soller/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 439",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/mallorca/son-servera/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 440",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/menorca/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 441",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/menorca/alaior/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 442",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/menorca/ciutadella-de-menorca/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 443",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/menorca/ferreries/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 444",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/menorca/sant-lluis/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 445",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/murcia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 446",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/murcia/aguilas/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 447",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/murcia/cartagena/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 448",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/murcia/las-torres-de-cotillas/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 449",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/murcia/lorca/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 450",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/murcia/los-alcazares/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 451",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/murcia/san-pedro-del-pinatar/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 452",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/murcia/torre-pacheco/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 453",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/murcia/totana/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 454",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/murcia/villanueva-del-rio-segura/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 455",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/murcia/yecla/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 456",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/navarra/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 457",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/navarra/berrioplano/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 458",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/navarra/cintruenigo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 459",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/navarra/egues/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 460",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/navarra/elgorriaga/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 461",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/navarra/galar/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 462",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/navarra/huarte/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 463",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/navarra/irurtzun/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 464",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/navarra/olite/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 465",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/navarra/pamplona/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 466",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/navarra/tudela/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 467",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/oporto/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 468",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/oporto/matosinhos/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 469",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/oporto/oporto/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 470",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/oporto/penafiel/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 471",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/oporto/povoa-de-varzim/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 472",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/oporto/sao-felix-da-marinha/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 473",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/oporto/sao-mamede-de-infesta/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 474",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/oporto/vila-nova-de-gaia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 475",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/ourense/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 476",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/ourense/a-arnoia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 477",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/ourense/allariz/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 478",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/ourense/cenlle/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 479",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/ourense/leiro/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 480",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/ourense/lobios/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 481",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/ourense/nogueira-de-ramuin/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 482",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/ourense/ourense/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 483",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/pais-vasco/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 484",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/palencia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 485",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/palencia/ampudia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 486",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/pontevedra/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 487",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/pontevedra/a-lama/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 488",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/pontevedra/caldas-de-reis/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 489",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/pontevedra/lalin/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 490",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/pontevedra/moana/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 491",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/pontevedra/mondariz-balneario/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 492",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/pontevedra/o-grove/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 493",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/pontevedra/oia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 494",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/pontevedra/pontevedra/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 495",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/pontevedra/sanxenxo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 496",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/pontevedra/silleda/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 497",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/pontevedra/tomino/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 498",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/pontevedra/vigo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 499",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/portalegre/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 500",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/portalegre/crato/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 501",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/portalegre/montargil/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 502",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/principado-de-asturias/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 503",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/region-de-azores/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 504",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/region-de-madeira/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 505",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/region-de-murcia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 506",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/salamanca/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 507",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/salamanca/bermellar/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 508",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/salamanca/la-alamedilla/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 509",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/salamanca/madronal/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 510",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/salamanca/santa-marta-de-tormes/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 511",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/salamanca/valverdon/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 512",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/salamanca/villamayor/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 513",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/santarem/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 514",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/santarem/fatima/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 515",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/santarem/golega/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 516",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/santarem/tomar/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 517",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/segovia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 518",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/segovia/el-espinar/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 519",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/segovia/real-sitio-de-san-ildefonso/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 520",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/segovia/santo-tome-del-puerto/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 521",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/segovia/segovia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 522",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/segovia/vegas-de-matute/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 523",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/setubal/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 524",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/setubal/costa-de-caparica/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 525",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/sevilla/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 526",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/sevilla/la-campana/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 527",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/sevilla/sevilla/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 528",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/soria/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 529",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/soria/burgo-de-osma-ciudad-de-osma/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 530",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tarragona/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 531",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tarragona/altafulla/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 532",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tarragona/cunit/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 533",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tarragona/el-perello/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 534",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tarragona/el-vendrell/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 535",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tarragona/l-ampolla/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 536",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tarragona/salou/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 537",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tarragona/tortosa/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 538",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tarragona/vila-seca/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 539",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tenerife/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 540",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tenerife/adeje/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 541",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tenerife/arona/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 542",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tenerife/buenavista-del-norte/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 543",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tenerife/el-tanque/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 544",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tenerife/granadilla-de-abona/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 545",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tenerife/guia-de-isora/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 546",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tenerife/playa-de-las-americas/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 547",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tenerife/puerto-de-la-cruz/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 548",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tenerife/san-cristobal-de-la-laguna/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 549",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tenerife/san-miguel-de-abona/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 550",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tenerife/santa-cruz-de-tenerife/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 551",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tenerife/santa-ursula/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 552",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tenerife/santiago-del-teide/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 553",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/tenerife/vilaflor/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 554",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/teruel/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 555",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/teruel/alcala-de-la-selva/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 556",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/teruel/cantavieja/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 557",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/teruel/mora-de-rubielos/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 558",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/teruel/valderrobres/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 559",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/toledo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 560",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/toledo/carranque/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 561",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/toledo/la-estrella/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 562",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/toledo/toledo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 563",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/toledo/villaminaya/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 564",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/valencia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 565",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/valencia/alboraya/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 566",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/valencia/gandia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 567",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/valencia/paterna/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 568",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/valencia/valencia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 569",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/valladolid/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 570",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/valladolid/arroyo-de-la-encomienda/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 571",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/valladolid/el-campillo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 572",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/valladolid/valbuena-de-duero/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 573",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/valladolid/valladolid/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 574",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/viana-do-castelo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 575",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/viana-do-castelo/arcos-de-valdevez/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 576",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/viana-do-castelo/caminha/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 577",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/viana-do-castelo/viana-do-castelo/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 578",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/vila-real/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 579",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/vila-real/boticas/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 580",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/vila-real/chaves/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 581",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/vila-real/mesao-frio/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 582",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/viseu/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 583",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/viseu/campo-de-besteiros/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 584",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/viseu/canas-de-senhorim/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 585",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/viseu/lamego/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 586",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/viseu/mortagua/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 587",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/viseu/resende/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 588",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/viseu/sao-pedro-do-sul/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 589",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/vizcaya/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 590",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/vizcaya/areatza/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 591",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/vizcaya/bilbao/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 592",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/vizcaya/manaria/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 593",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/vizcaya/mungia/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 594",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/vizcaya/urduna/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 595",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/vizcaya/zeanuri/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 596",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/zamora/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 597",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/zamora/valdefinjas/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 598",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/zaragoza/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 599",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/zaragoza/alhama-de-aragon/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 600",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/zaragoza/calatayud/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 601",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/zaragoza/el-buste/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 602",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/zaragoza/jaraba/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayList_46pagetXlt50kBGrMeta?.name ?? "GetAway by location landing: 603",
    path: GetAwayList_46pagetXlt50kBGrMeta?.path ?? "/escapadas-hotel-spa/zaragoza/murillo-de-gallego/",
    props: GetAwayList_46pagetXlt50kBGrMeta?.props ?? false,
    meta: GetAwayList_46pagetXlt50kBGrMeta || {},
    alias: GetAwayList_46pagetXlt50kBGrMeta?.alias || [],
    redirect: GetAwayList_46pagetXlt50kBGrMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayList.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 0",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/sevilla/desconexion-total-escapada-en-hotel-con-circuito-termal-y-masaje-con-aromaterapia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-romantica-en-iberostar-royal-andalus/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 2",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-magica-en-pestana-plaza-mayor-madrid/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 3",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-magica-en-sheraton-fuerteventura-beach-golf-spa-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 4",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/acores/escapada-lovers-en-marina-atlantico/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 5",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/guarda/escapada-de-ensueno-en-pareja-en-corinthia-lisbon/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 6",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/vizcaya/escapada-magica-en-occidental-bilbao/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 7",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-migjorn-ibiza-suites-and-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 8",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-ensueno-en-pareja-en-landmar-costa-los-gigantes/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 9",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-especial-relax-en-villa-oniria/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 10",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-para-dos-en-el-rodat/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 11",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-grupotel-montecarlo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 12",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-gran-sagitario/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 13",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-especial-relax-en-elke-spa-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 14",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-antiestres-en-bull-reina-isabel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 15",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-grand-palladium-white-island-resort-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 16",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/jaen/escapada-magica-en-san-pedro/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 17",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-romantica-en-rocamar-exclusive-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 18",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-con-arquitectura-al-estilo-tipico-canario-spa-privado/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 19",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lugo/escapada-antiestres-en-hotel-galatea/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 20",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/desconexion-total-en-la-barrosa-con-estancia-circuito-spa-y-masaje-relajante-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 21",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-magica-en-kempinski-hotel-bahia-marbella-estepona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 22",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-ensueno-en-pareja-en-spa-villalba/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 23",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-sentido-fido-punta-del-mar-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 24",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-romantica-en-the-level-at-melia-barcelona-sky/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 25",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-para-dos-en-sol-costa-daurada/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 26",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-romantica-en-cleopatra-spa-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 27",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-msh-mallorca-senses-hotel-palmanova/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 28",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-summum-prime-boutique-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 29",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-son-caliu-hotel-spa-oasis/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 30",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-de-lujo-en-sofia-barcelona-in-the-unbound-collection-by-hyatt/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 31",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-para-dos-en-grevol-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 32",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-bienestar-con-spa-y-masaje-con-aromaterapia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 33",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lleida/escapada-de-ensueno-en-pareja-en-montarto/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 34",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-de-ensueno-en-pareja-en-barcelo-costa-ballena/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 35",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-me-ibiza/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 36",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-ensueno-en-pareja-en-los-claveles/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 37",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-hotel-globales-cala-vinas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 38",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/guarda/escapada-especial-relax-en-lutecia-smart-design-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 39",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-de-lujo-en-catalonia-ronda/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 40",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/experiencia-wellness-total-con-estancia-y-masaje-con-aromaterapia-para-dos-en-en-el-corazon-de-la-serra-tramuntana/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 41",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-romantica-en-peralada-wine-spa-golf/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 42",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-de-lujo-en-vila-gale-marina/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 43",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/soria/escapada-de-lujo-en-hotel-spa-rio-ucero/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 44",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-iberostar-albufera-park/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 45",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-de-ensueno-en-pareja-en-melia-salinas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 46",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-lujo-en-kn-aparthotel-columbus/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 47",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-de-ensueno-en-pareja-en-apartamentos-guitart/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 48",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-magica-en-gran-talaso-hotel-sanxenxo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 49",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/viana-do-castelo/escapada-lovers-en-axis-viana-business-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 50",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/guarda/escapada-romantica-en-altis-belem-hotel-spa-design-hotels/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 51",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/teruel/escapada-de-lujo-en-la-trufa-negra-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 52",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lisboa/escapada-de-ensueno-en-pareja-en-pestana-cidadela-cascais-pousada-art-district/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 53",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/reequilibra-la-energia-con-una-escapada-con-masaje-hidu-y-spa-para-2-frente-al-mar-mediterraneo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 54",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-especial-con-estancia-de-lujo-y-stone-therapy-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 55",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-magica-en-hotel-best-tenerife/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 56",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-romantica-en-jumeirah-port-soller-hotel-and-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 57",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-para-dos-en-vila-gale-lagos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 58",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-de-ensueno-en-pareja-en-fairmont-barcelona-rey-juan-carlos-i/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 59",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-lujo-en-dunes-platja/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 60",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-be-live-experience-costa-palma/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 61",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lleida/escapada-para-dos-en-rafaelhoteles-by-la-pleta/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 62",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-magica-en-nuevo-madrid/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 63",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/aveiro/escapada-para-dos-en-curia-palace-hotel-spa-golf-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 64",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-especial-relax-en-ibersol-antemare/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 65",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/asturias/escapada-de-ensueno-en-pareja-en-las-caldas-spa-sport/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 66",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/albacete/escapada-romantica-en-hospedium-hotel-canitas-maite/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 67",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-de-lujo-en-ap-oriental-beach-adults-friendly/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 68",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-de-ensueno-en-pareja-con-estancia-y-ritual-lanzarote-experience/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 69",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/viseu/escapada-lovers-en-golden-tulip-caramulo-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 70",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-fontanellas-playa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 71",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/porto/escapada-de-lujo-en-palace-hotel-spa-termas-de-sao-vicente/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 72",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/experiencia-wellness-total-para-dos-con-circuito-spa-privado-y-masaje-con-piedras-volcanicas-calientes-de-tenerife/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 73",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-de-ensueno-en-pareja-en-dreams-lanzarote-playa-dorada/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 74",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-lujo-en-royal-plaza/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 75",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-cap-rocat/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 76",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-ensueno-en-pareja-en-alexandre-hotel-la-siesta/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 77",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cantabria/escapada-antiestres-en-castilla-termal-balneario-de-solares/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 78",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-hotel-ilusion-calma-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 79",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-para-dos-en-vincci-rumaykiyya/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 80",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-hoposa-villaconcha-aptos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 81",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-steigenberger-hotel-resort-camp-de-mar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 82",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-magica-en-los-monteros-spa-golf-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 83",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-lovers-en-vila-alba-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 84",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/desconexion-total-escapada-con-estancia-circuito-hydrowellness-y-masaje-oriental-en-jandia-para-2-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 85",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-especial-relax-en-vidamar-resorts-madeira-half-board/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 86",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valladolid/escapada-romantica-en-vincci-frontaura/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 87",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/evora/escapada-especial-relax-en-alentejo-marmoris-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 88",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-de-lujo-en-sir-victor/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 89",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huelva/escapada-especial-relax-en-ama-islantilla-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 90",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/avila/escapada-magica-en-el-milano-real/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 91",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/recupera-energia-en-pareja-estancia-circuito-hydrowellness-tratamiento-facial-y-corporal-para-dos-en-el-sur-de-tenerife/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 92",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cordoba/escapada-romantica-en-soho-boutique-capuchinos-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 93",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huesca/experiencia-wellness-total-para-dos-en-formigal-con-estancia-circuito-spa-y-masaje-relajante-con-aceites-aromaticos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 94",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-lovers-en-bellavista/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 95",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-romantica-en-sunset-view-club-by-diamond-resorts/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 96",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/aveiro/escapada-de-lujo-en-grande-hotel-do-luso/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 97",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-de-ensueno-en-pareja-en-mac-puerto-marina-benalmadena/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 98",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-magica-en-so-sotogrande-apertura-verano-2021/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 99",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-vistasol-apartments/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 100",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-de-lujo-en-hotel-the-costa-taurito/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 101",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alava/escapada-romantica-para-2-con-estancia-spa-y-masaje/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 102",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huelva/escapada-de-ensueno-en-pareja-con-estancia-y-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 103",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-de-lujo-en-h10-suites-lanzarote-gardens/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 104",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-especial-relax-en-tenerife-con-spa-masaje-relajante-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 105",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cantabria/escapada-de-lujo-en-la-casona-de-luis/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 106",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-antiestres-en-catalonia-plaza-mayor/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 107",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/navarra/escapada-lovers-en-alhama/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 108",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-es-turo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 109",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-romantica-en-cap-roig/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 110",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-lujo-en-blau-colonia-sant-jordi-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 111",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-romantica-en-aumallia-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 112",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-lovers-en-seven-hotel-wellness/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 113",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-antiestres-en-exe-estepona-thalasso-spa-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 114",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-de-lujo-en-los-jarales/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 115",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-lujo-en-gloria-de-sant-jaume/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 116",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/viana-do-castelo/escapada-antiestres-en-ribeira-collection-hotel-by-piamonte-hotels/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 117",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-de-ensueno-en-pareja-con-estancia-en-casa-rural-y-spa-privado-en-la-alpujarra/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 118",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/leon/escapada-romantica-en-balneario-caldas-de-luna/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 119",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-de-ensueno-en-pareja-en-carrossa-hotel-spa-villas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 120",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/recupera-energia-en-pareja-estancia-circuito-hidrotermal-y-masaje-relax-en-lanzarote-incluido-para-dos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 121",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-de-ensueno-en-pareja-en-aquamarina-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 122",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-de-ensueno-en-pareja-con-estancia-y-spa-en-un-exclusivo-hotel-urbano-vanguardista-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 123",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-para-dos-en-catalonia-reina-victoria-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 124",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-magica-en-claris-and-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 125",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lugo/escapada-de-ensueno-en-pareja-en-hotel-auditorio-santiago-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 126",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-romantica-con-spa-y-masaje-junto-al-mar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 127",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-magica-en-cascade-wellness-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 128",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-hard-rock-hotel-ibiza/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 129",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-antiestres-en-parador-de-aiguablava/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 130",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-fontsanta-thermal-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 131",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-para-dos-en-don-carlos-leisure-resort-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 132",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-de-ensueno-en-pareja-en-el-palacete-del-corregidor/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 133",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/caceres/escapada-de-ensueno-en-pareja-en-la-sinagoga-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 134",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-lovers-en-macdonald-dona-lola/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 135",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/escapada-magica-en-ar-golf-almerimar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 136",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-para-dos-en-barcelo-marbella/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 137",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-para-dos-en-the-level-at-melia-barcelona-sky/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 138",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-de-ensueno-en-pareja-con-estancia-de-lujo-y-masaje-relajante-para-dos-personas-en-lanzarote/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 139",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-antiestres-en-ght-oasis-tossa-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 140",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-especial-relax-en-albir-playa-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 141",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-grupotel-parc-natural-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 142",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huesca/escapada-de-ensueno-en-pareja-en-hotel-continental/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 143",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-romantica-en-h10-estepona-palace/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 144",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-magica-en-ms-amaragua/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 145",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/navarra/escapada-especial-relax-en-pompaelo-urban-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 146",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/recupera-energia-en-pareja-en-gran-canaria-con-estancia-y-ritual-corporal-especial/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 147",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/braga/escapada-magica-en-do-templo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 148",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/ourense/escapada-antiestres-en-lobios-caldaria-hotel-balneario/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 149",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/asturias/escapada-especial-relax-en-oca-palacio-de-la-llorea-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 150",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-lujo-con-spa-y-masaje-de-piedras-calientes-para-2-en-tenerife/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 151",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/braga/escapada-antiestres-en-do-parque/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 152",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-de-lujo-en-royal-orchid/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 153",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huelva/escapada-romantica-en-hotel-convento-aracena-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 154",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-de-ensueno-en-pareja-en-prestige-victoria/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 155",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/recupera-energia-en-pareja-con-estancia-y-peeling-corporal-de-coco-hidratacion-completa-en-el-parque-natural-del-delta-del-ebro/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 156",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-eco-wellness-con-spa-y-masaje-de-piedras-calientes-rodeado-de-naturaleza/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 157",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-marsenses-rosa-del-mar-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 158",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-de-ensueno-en-pareja-en-santa-marta/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 159",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/experiencia-wellness-total-para-dos-en-pleno-centro-de-tenerife-con-estancia-circuito-termal-y-masaje-anti-estres/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 160",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-lujo-en-maritim-hotel-galatzo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 161",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/portalegre/escapada-lovers-en-ap-lago-montargil-conference-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 162",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/isla-de-el-hierro/escapada-de-lujo-en-balneario-pozo-de-la-salud/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 163",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/jaen/escapada-romantica-en-rosaleda-de-don-pedro/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 164",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huelva/escapada-antiestres-en-garden-playa-natural-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 165",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-especial-relax-en-eurostars-gran-hotel-la-toja/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 166",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-romantica-en-oku-ibiza/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 167",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/relax-total-a-pie-de-montana-con-estancia-spa-y-masaje-para-dos-en-berga/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 168",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-fergus-club-vell-mari/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 169",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/a-coruna/escapada-lovers-en-hotel-spa-relais-chateaux-a-quinta-da-auga/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 170",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-especial-relax-en-sol-torremolinos-don-pedro/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 171",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-portblue-club-pollentia-resort-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 172",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-cabot-pollensa-park-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 173",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-para-dos-en-naitly-madrid-chamberi/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 174",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cantabria/escapada-para-dos-en-hotel-spa-el-muelle/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 175",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/guipuzcoa/escapada-especial-relax-en-hotel-arima/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 176",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-magica-en-boa-vista-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 177",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-de-ensueno-en-benidorm-con-estancia-circuito-spa-wellness-y-ritual-thai-experiencie-en-pareja/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 178",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-de-lujo-en-eira-do-serrado-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 179",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-ensueno-en-pareja-en-barcelo-tenerife/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 180",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lisboa/escapada-lovers-en-grande-real-villa-italia-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 181",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/desconexion-total-escapada-en-hotel-con-spa-privado-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 182",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/asturias/escapada-lovers-en-spa-la-hacienda-don-juan/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 183",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-lovers-en-abades-nevada-palace/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 184",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-especial-relax-en-royal-hideaway-corales-beach-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 185",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-antiestres-en-hotel-best-alcazar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 186",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-romantica-en-h10-ocean-dreams-hotel-boutique-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 187",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-romantica-en-barcelo-corralejo-sands/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 188",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-para-dos-en-sercotel-hotel-bonalba-alicante/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 189",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alava/desconexion-total-en-vitoria-con-estancia-spa-exfoliacion-y-masaje-en-pareja/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 190",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-lujo-en-adrian-hoteles-jardines-de-nivaria/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 191",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-ebano-hotel-apartments-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 192",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/navarra/escapada-de-lujo-en-iriguibel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 193",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-lovers-en-hotel-spa-la-collada/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 194",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/porto/escapada-antiestres-en-axis-porto-business-spa-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 195",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-lovers-en-marsol/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 196",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-de-lujo-en-daniya-denia-spa-business/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 197",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-romantica-en-arena-beach/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 198",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/guadalajara/escapada-desconexion-bienestar-con-estancia-y-jacuzzi/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 199",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-romantica-en-alcadima/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 200",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/guarda/escapada-de-ensueno-en-pareja-en-inspira-liberdade-boutique-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 201",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-romantica-en-tenerife-para-2-con-circuito-spa-privado-y-masaje-relajante-en-pareja/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 202",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-especial-relax-en-nh-collection-palacio-de-tepa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 203",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-magica-en-gran-hotel-reymar-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 204",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/experiencia-wellness-con-estancia-spa-y-masaje-para-2-en-madrid/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 205",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-de-lujo-en-exe-via-argentum/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 206",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/navarra/escapada-de-lujo-en-bed4u-pamplona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 207",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-el-cupido/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 208",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lisboa/escapada-de-ensueno-en-pareja-en-evolution-cascais-estoril/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 209",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-especial-relax-en-bahia-principe-fantasia-tenerife/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 210",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-magica-en-grand-muthu-golf-plaza-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 211",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-de-lujo-en-alua-lago-rojo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 212",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-antiestres-en-alannia-guardamar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 213",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/salamanca/escapada-para-dos-en-salamanca-forum-hotel-dona-brigida/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 214",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-lujo-en-gran-melia-palacio-de-isora/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 215",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/experiencia-wellness-total-para-dos-con-estancia-circuito-spa-y-masaje-en-el-parque-natural-del-delta-del-ebro/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 216",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-de-lujo-en-vp-plaza-espana-design/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 217",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-ensueno-en-pareja-en-royal-hideaway-corales-suites/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 218",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-de-ensueno-en-pareja-en-h10-andalucia-plaza/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 219",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-romantica-en-isla-mallorca-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 220",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-especial-relax-en-el-tiburon-hotel-boutique-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 221",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/salamanca/escapada-de-ensueno-en-pareja-en-dona-teresa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 222",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-exagon-park/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 223",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-magica-en-estrelicia-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 224",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/jaen/escapada-de-lujo-en-rural-los-nogales/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 225",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huelva/escapada-magica-en-ilunion-islantilla/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 226",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cantabria/escapada-para-dos-en-palacio-torre-de-ruesga/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 227",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-de-lujo-en-villa-termal-das-caldas-de-monchique-spa-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 228",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-chocolate-lovers-con-estancia-circuito-spa-y-masaje-relajante-de-chocolate-en-el-corazon-de-granada-para-2-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 229",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-de-ensueno-en-pareja-en-melia-villaitana/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 230",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-spa-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 231",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-antiestres-en-hilton-diagonal-mar-barcelona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 232",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/desconexion-en-pleno-centro-de-madrid-con-estancia-spa-y-masaje-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 233",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/experiencia-wellness-total-para-dos-con-estancia-circuito-spa-y-masaje-incluido-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 234",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/toledo/escapada-antiestres-en-villa-nazules-hipica-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 235",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-para-dos-en-iberostar-selection-llaut-palma/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 236",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-antiestres-en-rosewood-villa-magna/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 237",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-lovers-en-the-cook-book-gastro-boutique-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 238",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/escapada-de-ensueno-en-pareja-en-cabogata-jardin/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 239",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-de-ensueno-en-pareja-con-estancia-de-lujo-thalasso-experience-y-masaje-relajante-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 240",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-barcelo-nura/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 241",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-de-ensueno-en-pareja-en-hotel-suites-del-mar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 242",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-romantica-en-seaside-grand-hotel-residencia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 243",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/navarra/escapada-de-ensueno-en-pareja-en-balneario-elgorriaga/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 244",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-de-ensueno-en-pareja-en-barcelo-fuerteventura-mar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 245",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/badajoz/escapada-especial-relax-en-el-corazon-de-merida-con-spa-privado-y-masaje-aromaterapia-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 246",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/escapada-para-dos-en-spa-calagrande/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 247",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-ona-aucanada/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 248",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-especial-relax-en-tropical/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 249",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-wellness-con-estancia-circuito-de-aguas-y-masaje-relajante-en-pleno-casco-historico/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 250",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-lovers-en-labranda-corralejo-village/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 251",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-especial-relax-en-catalonia-granada/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 252",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cantabria/escapada-especial-relax-en-gran-hotel-balneario-puente-viesgo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 253",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-lovers-en-europalace/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 254",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/santarem/escapada-antiestres-en-dos-templarios/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 255",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-magica-en-vila-gale-ampalius/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 256",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-especial-relax-en-the-one-barcelona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 257",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-antiestres-en-vistabella/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 258",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-antiestres-en-esmeralda-apartamentos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 259",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-magica-en-muthu-oura-praia-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 260",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-de-ensueno-en-pareja-en-pestana-carlton-madeira-ocean-resort-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 261",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-iberostar-alcudia-park/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 262",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/ourense/escapada-magica-en-eurostars-monasterio-de-san-clodio-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 263",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-cm-castell-de-mar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 264",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-magica-en-palacio-de-los-duques-gran-melia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 265",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-salles-hotel-marina-portals/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 266",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-antiestres-en-royal-palm-resort-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 267",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-de-ensueno-en-pareja-en-nuevo-astur-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 268",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-samos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 269",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-antiestres-en-florasol-residence-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 270",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lisboa/escapada-especial-relax-en-sheraton-cascais/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 271",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-faustino-gran-relais-chateaux/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 272",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-especial-relax-en-vila-gale-tavira/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 273",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-romantica-de-ensueno-con-estancia-spa-y-masaje-en-pareja/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 274",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lisboa/escapada-para-dos-en-four-seasons-hotel-lisbon/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 275",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-de-ensueno-en-pareja-en-hotel-spa-marbella-hills/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 276",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valencia/escapada-lovers-en-oceanografico-apartments-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 277",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-para-dos-en-hotel-riu-calypso-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 278",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huesca/escapada-de-ensueno-en-pareja-en-sommos-hotel-benasque-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 279",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/a-coruna/escapada-magica-en-sercotel-odeon/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 280",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-de-lujo-en-hotel-spa-bosque-mar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 281",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-de-ensueno-en-pareja-con-estancia-y-masaje-de-esencias-naturales-en-salou/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 282",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-magica-en-la-palmera-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 283",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/desconexion-total-en-la-playa-escapada-con-estancia-y-circuito-spa-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 284",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-lovers-en-monarque-sultan/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 285",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/leon/escapada-magica-en-tryp-leon-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 286",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-lovers-en-secrets-bahia-real-resort-spa-adults-only-18/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 287",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/castellon/escapada-antiestres-en-gran-hotel-peniscola/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 288",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/ciudad-real/desconexion-total-escapada-en-hotel-con-circuito-spa-y-ritual-antioxidante-de-vino-para-2-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 289",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-antiestres-en-van-der-valk-hotel-barcarola/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 290",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-de-hotel-masaje-en-sitges/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 291",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lisboa/escapada-especial-relax-en-hotel-cascais-miragem-health-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 292",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/braga/escapada-romantica-en-do-lago/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 293",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-wellness-estancia-y-ritual-de-exfoliacion-spa-incluido-en-madrid/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 294",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-bienestar-en-barcelona-con-masaje-de-vela-y-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 295",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/coimbra/escapada-lovers-en-nh-coimbra-dona-ines/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 296",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-es-princep/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 297",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-romantica-en-sant-roc/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 298",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-romantica-en-ght-oasis-park-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 299",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-especial-relax-en-can-bordoy/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 300",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valencia/escapada-de-lujo-en-nh-valencia-center/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 301",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-especial-relax-en-melia-barcelona-sarria/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 302",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-magica-en-sol-lanzarote-all-inclusive/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 303",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/viseu/escapada-magica-en-grande-hotel-das-caldas-da-felgueira/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 304",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-romantica-en-barcelo-montecastillo-golf/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 305",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/badajoz/escapate-a-un-precioso-hotel-boutique-con-spa-privado-para-2-en-merida/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 306",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lugo/escapada-especial-relax-en-oca-playa-de-foz-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 307",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-de-lujo-con-estancia-circuito-spa-y-ritual-para-dos-personas-en-barcelona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 308",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cantabria/escapada-antiestres-en-hotel-spa-villa-pasiega/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 309",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/escapada-especial-relax-en-playalinda-aquapark-spa-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 310",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-para-dos-en-barcelo-granada-congress/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 311",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/toledo/recupera-energia-en-toledo-con-estancia-y-circuito-hidrotermal-masaje-antiestres/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 312",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-antiestres-en-las-artes/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 313",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-lovers-en-h10-rubicon-palace/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 314",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/experiencia-wellness-total-para-dos-a-pie-de-mar-con-estancia-circuito-spa-y-ritual-sublime-de-polinesia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 315",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/escapada-de-ensueno-en-pareja-en-valle-del-este-golf-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 316",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-romantica-en-montanya-hotel-lodge/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 317",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-especial-relax-en-balneario-de-mondariz/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 318",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valencia/desconexion-total-escapada-en-valencia-con-estancia-spa-y-masaje-oriental/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 319",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-especial-relax-en-r2-pajara-beach/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 320",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/desconexion-total-escapada-con-estancia-circuito-spa-y-ritual-de-sensaciones-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 321",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-romantica-en-melia-barcelona-sky/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 322",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-para-dos-en-santa-catalina-a-royal-hideaway-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 323",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lisboa/escapada-romantica-en-onyria-marinha-boutique-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 324",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-ynaira-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 325",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-lovers-en-risco-del-gato-suites-the-senses-collection/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 326",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valencia/escapada-especial-relax-en-rh-bayren-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 327",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/sevilla/escapada-de-lujo-en-hotel-palacio-de-villapanes/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 328",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-de-lujo-en-suitehotel-playa-del-ingles/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 329",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-para-dos-en-riviera/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 330",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-de-lujo-en-blaucel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 331",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-antiestres-en-exe-cunit-suites-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 332",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-playa-garden-selection-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 333",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/relax-total-a-pie-de-playa-con-estancia-de-lujo-circuito-hydrowellness-y-masaje-anti-estres-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 334",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-especial-relax-en-alba/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 335",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huelva/escapada-especial-relax-en-ama-islantilla-resort-con-spa-masaje-relajante-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 336",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-especial-relax-en-cadiz-con-estancia-spa-y-masaje-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 337",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-magica-en-rh-princesa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 338",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-para-dos-en-eden-roc-mediterranean-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 339",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-hotel-sant-jaume/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 340",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-spa-para-parejas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 341",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-especial-relax-en-cosmo-apartments-platja-daro/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 342",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valladolid/escapada-de-lujo-en-castilla-termal-monasterio-de-valbuena/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 343",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-de-lujo-en-urh-moli-del-mig/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 344",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-lovers-en-barcelo-castillo-royal-level/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 345",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lleida/escapada-de-lujo-en-nastasi-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 346",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-hotel-55-santo-tomas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 347",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valencia/escapada-bienestar-con-estancia-circuito-spa-y-masaje-aromaterapia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 348",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-magica-en-hotel-the-valle-taurito/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 349",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-santa-ponsa-pins/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 350",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lisboa/escapada-de-ensueno-en-pareja-en-penha-longa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 351",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/leiria/escapada-romantica-en-cristal-praia-resort-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 352",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/castellon/escapada-para-2-con-estancia-masaje-y-balneario-con-aguas-mineromedicinales/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 353",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-h10-punta-negra-boutique-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 354",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/experiencia-wellness-total-para-dos-en-marbella-con-estancia-y-masaje-tui-na/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 355",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cordoba/escapada-de-ensueno-en-pareja-en-balneario-aguas-de-villaharta/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 356",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-iberostar-ciudad-blanca/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 357",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-para-dos-en-gran-hotel-benahavis-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 358",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-especial-relax-en-bull-escorial-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 359",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-especial-relax-en-salles-hotel-pere-iv/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 360",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-magica-en-hovima-costa-adeje/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 361",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lugo/escapada-romantica-con-spa-privado-para-2-en-la-costa-de-lugo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 362",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-de-lujo-en-illot-suites-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 363",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-antiestres-en-blue-sea-costa-jardin-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 364",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/leiria/escapada-romantica-en-villa-batalha/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 365",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-bienestar-con-circuito-spa-masaje-bienestar-de-20-minutos-para-2-en-tenerife/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 366",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valencia/escapada-romantica-en-azz-valencia-congress-hotelspa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 367",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-para-dos-en-gpro-valparaiso-palace-and-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 368",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-romantica-en-aluavillage-fuenteventura/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 369",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-lovers-en-iberostar-selection-marbella-coral-beach/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 370",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-especial-relax-junto-a-la-elegancia-del-passeig-de-gracia-con-spa-y-masaje/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 371",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-para-dos-en-soho-boutique-jerez-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 372",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/burgos/escapada-desconexion-con-estancia-spa-y-ritual-de-vinoterapia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 373",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-club-del-sol-aparthotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 374",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-lago-resort-menorca-casas-del-lago/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 375",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/badajoz/escapada-romantica-en-hotel-spa-adealba/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 376",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-para-dos-en-gran-tacande-wellness-relax-costa-adeje/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 377",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-antiestres-en-sant-pere-del-bosc/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 378",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-lovers-en-hotel-the-lago-taurito/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 379",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-especial-relax-en-grand-muthu-forte-do-vale/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 380",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/ciudad-real/escapada-antiestres-en-hotel-la-caminera-club-de-campo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 381",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-especial-relax-en-hotel-don-pepe-gran-melia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 382",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/salamanca/escapada-romantica-en-don-gregorio/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 383",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-relajante-en-el-sur-de-gran-canaria-con-circuito-spa-y-masaje-profundo-de-espalda/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 384",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-blau-portopetro-beach-resort-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 385",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-magica-en-montanya-hotel-lodge/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 386",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/guadalajara/escapada-especial-relax-con-estancia-jacuzzi-y-masaje/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 387",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-lovers-en-club-el-marques/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 388",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cordoba/escapada-especial-relax-en-casas-de-la-juderia-de-cordoba/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 389",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-spa-entre-arquitectura-canaria/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 390",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huesca/escapada-romantica-en-santa-cristina-petit-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 391",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-romantica-en-belmar-spa-beach-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 392",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huesca/escapada-lovers-en-barcelo-monasterio-de-boltana/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 393",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-magica-en-iberostar-playa-gaviotas-park-all-inclusive/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 394",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-magica-en-garahotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 395",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/a-coruna/escapada-para-dos-en-nh-collection-santiago/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 396",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/navarra/escapada-romantica-en-castillo-de-gorraiz-golf-and-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 397",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huesca/escapada-de-lujo-en-silken-ordesa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 398",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-magica-en-aparthotel-suites-vila-birdie/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 399",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-antiestres-en-granada-palace/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 400",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-spa-con-espacios-para-encontrarse-con-uno-mismo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 401",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-especial-relax-en-le-meridien-barcelona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 402",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-wellness-en-pareja-con-estancia-y-experiencia-volcanica-en-el-spa-para-dos-personas-en-lanzarote/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 403",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cantabria/escapada-especial-relax-en-torresport/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 404",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/desconexion-wellness-escapada-spa-masaje-para-2-en-un-entorno-especial/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 405",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-de-ensueno-en-pareja-con-estancia-y-spa-en-madrid/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 406",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-antiestres-en-villa-padierna-thermas-de-carratraca/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 407",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-especial-relax-en-hotel-blancafort-spa-termal/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 408",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-para-dos-en-melia-sierra-nevada/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 409",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-para-dos-en-mur-hotel-faro-jandia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 410",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lugo/escapada-para-dos-en-balneario-rio-pambre/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 411",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/desconexion-total-escapada-en-hotel-con-estancia-spa-y-masaje-relajante/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 412",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-especial-relax-en-elba-lanzarote-royal-village-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 413",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-antiestres-en-meridional/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 414",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-de-ensueno-en-pareja-en-la-fonda/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 415",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-especial-relax-en-urh-excelsior/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 416",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-spa-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 417",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-de-lujo-en-castell-son-claret/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 418",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-antiestres-en-melia-sol-y-nieve/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 419",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-de-ensueno-en-pareja-en-fairplay-golf-spa-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 420",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huelva/escapada-especial-relax-en-the-residences-islantilla-apartments/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 421",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-lovers-en-dolce-by-wyndham-sitges-barcelona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 422",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-romantica-en-ele-hotel-spa-medina-sidonia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 423",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-catalonia-majorica/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 424",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-para-dos-en-del-mar-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 425",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huelva/escapada-romantica-en-playa-marina-spa-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 426",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-lovers-en-europe-villa-cortes/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 427",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/jaen/escapada-para-dos-en-palacio-de-ubeda/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 428",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-bienestar-con-estancia-y-circuito-spa-para-dos-en-pleno-centro-de-barcelona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 429",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-antiestres-en-grand-muthu-golf-plaza-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 430",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/asturias/escapada-especial-relax-en-castillo-del-bosque-la-zoreda/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 431",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cordoba/escapada-de-ensueno-en-pareja-en-ms-fuente-las-piedras/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 432",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/braga/escapada-especial-relax-en-guimaraes/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 433",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/a-coruna/escapada-bienestar-para-dos-personas-en-ferrol-con-estancia-circuito-spa-y-masaje-relajante/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 434",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/oporto/escapada-para-dos-en-sheraton-porto-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 435",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-antiestres-en-cleopatra-palace-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 436",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-aluasoul-palma-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 437",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cantabria/escapada-de-ensueno-en-pareja-en-gran-hotel-liber-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 438",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-aluasoul-alcudia-bay-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 439",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-hotel-spa-ferrer-concord/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 440",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-hotel-spa-ferrer-janeiro/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 441",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-lovers-en-hotel-california-urban-beach/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 442",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-para-dos-en-marins-playa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 443",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/sevilla/escapada-lovers-en-eurostars-sevilla-boutique/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 444",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-de-lujo-en-kn-matas-blancas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 445",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-lovers-en-amare-beach-hotel-marbella/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 446",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/aveiro/escapada-magica-en-jardins-da-ria/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 447",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-de-ensueno-en-pareja-en-quinta-da-casa-branca/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 448",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-romantica-en-alvor-baia-resort-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 449",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/acores/escapada-de-lujo-en-ponta-delgada/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 450",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/viseu/escapada-de-lujo-en-lamego-hotel-life/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 451",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/experiencia-wellnes-escapada-en-un-oasis-de-tranquilidad-spa-masaje-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 452",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/ourense/escapada-romantica-en-laias-caldaria-hotel-balneario/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 453",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/experiencia-wellness-total-para-dos-en-tenerife-con-estancia-circuito-relax-y-masaje-de-espalda-y-cuello/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 454",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-bienestar-con-estancia-y-circuito-de-aguas-en-una-ciudad-patrimonio-de-la-humanidad-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 455",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valencia/escapada-de-lujo-en-santos-las-arenas-balneario-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 456",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/sevilla/experiencia-wellness-con-estancia-en-el-centro-de-sevilla/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 457",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-de-ensueno-en-pareja-en-sercotel-alcala-611/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 458",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/murcia/escapada-para-dos-en-hyltor/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 459",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huelva/escapada-romantica-en-iberostar-isla-canela/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 460",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/acores/escapada-lovers-en-azoris-royal-garden-leisure-conference-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 461",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-de-ensueno-en-pareja-en-pine-cliffs-hotel-a-luxury-collection-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 462",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-bienestar-en-granada-con-estancia-y-circuito-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 463",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lugo/desconexion-total-escapada-en-hotel-galatea-con-spa-privado-con-cava-y-masaje-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 464",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-de-ensueno-en-pareja-en-magic-tropical-splash/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 465",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-magica-en-tivoli-carvoeiro-algarve-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 466",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-lovers-en-hotel-sun-palace-albir-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 467",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-magica-en-catalonia-oro-negro/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 468",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-antiestres-en-bcnsporthostels/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 469",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-magica-en-melia-madeira-mare/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 470",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-romantica-en-hotel-sunway-playa-golf-spa-sitges/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 471",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/viseu/escapada-de-ensueno-en-pareja-en-douro-marina-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 472",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lleida/escapada-de-ensueno-en-pareja-en-pessets/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 473",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/escapada-de-lujo-en-aire-hotel-ancient-baths/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 474",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/guadalajara/escapada-lovers-en-cardamomo-siguenza/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 475",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/leon/escapada-especial-relax-en-aqua-luna-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 476",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-lovers-en-el-palace/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 477",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-de-lujo-en-mariposa-del-sol/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 478",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/castelo-branco/escapada-antiestres-en-sport-hotel-gym-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 479",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-magica-en-hotel-antequera/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 480",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-romantica-en-maristel-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 481",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/salamanca/escapada-especial-relax-en-emperatriz-iii/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 482",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-romantica-en-eurosalou-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 483",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-bienestar-para-dos-con-estancia-y-envoltura-de-chocolate-para-dos-personas-en-la-costa-gaditana/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 484",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/zaragoza/escapada-antiestres-en-pilar-plaza/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 485",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-romantica-en-jam-hostel-barcelona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 486",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-antiestres-en-barcelo-santiago/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 487",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-de-lujo-en-hotel-balmoral/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 488",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-wellness-de-ensueno-con-estancia-y-experiencia-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 489",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-magica-en-helios-costa-tropical/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 490",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/vila-real/escapada-lovers-en-vidago-palace/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 491",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-spa-sagitario-playa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 492",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-de-ensueno-en-pareja-en-son-moll-sentits-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 493",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-lovers-en-pulitzer/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 494",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-wellness-para-dos-personas-con-estancia-circuito-spa-y-masaje-relajante-en-la-costa-granadina/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 495",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-lovers-en-seaside-palm-beach/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 496",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-ensueno-en-pareja-en-h10-conquistador/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 497",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-antiestres-en-holiday-world-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 498",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valencia/escapada-de-ensueno-en-pareja-con-estancia-de-lujo-y-ritual-de-oriente-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 499",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-magica-en-gara-suites-golf-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 500",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-magica-en-costa-brava/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 501",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/navarra/escapada-lovers-en-el-juglar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 502",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cantabria/escapada-especial-relax-en-balneario-la-hermida/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 503",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-lovers-en-r2-rio-calma-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 504",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-magica-en-es-port/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 505",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-els-pins-resort-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 506",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/experiencia-wellness-total-para-dos-en-la-playa-de-levante-con-estancia-circuito-spa-y-masaje-de-aromaterapia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 507",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/castelo-branco/escapada-especial-relax-en-hotel-covilha-affiliated-by-melia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 508",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/guipuzcoa/escapada-para-dos-en-balneario-de-cestona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 509",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-magica-en-royal-hideaway-sancti-petri/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 510",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-de-ensueno-en-pareja-en-swiss-hotel-moraira/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 511",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-de-lujo-en-3hb-guarana-duplicated-id-909974/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 512",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/salamanca/escapada-romantica-en-abadia-de-los-templarios/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 513",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huelva/escapada-romantica-para-2-con-estancia-y-masaje-con-velas-ecologicas-con-esencias-para-2-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 514",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-lovers-en-paradise-park-fun-lifestyle-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 515",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-de-ensueno-en-pareja-en-gem-wellness-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 516",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-marsenses-paradise-club-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 517",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/zaragoza/escapada-de-lujo-en-alhama-de-aragon-hotel-balneario/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 518",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-de-ensueno-en-pareja-en-vidamar-resort-hotel-algarve/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 519",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-santos-nixe-palace/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 520",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-especial-relax-en-elba-premium-suites/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 521",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/escapada-romantica-en-hotel-best-sabinal/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 522",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-playa-esperanza-resort-by-melia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 523",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-de-lujo-en-sb-bcn-events/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 524",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-romantica-en-augusta-club-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 525",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-lovers-en-fergus-plaza-paris-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 526",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-romantica-en-sol-torremolinos-don-pablo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 527",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/desconexion-atlantica-escapada-con-estancia-circuito-spa-en-el-sur-de-gran-canaria/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 528",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-spa-junto-a-la-playa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 529",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-negresco/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 530",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-magica-en-eurostars-madrid-mirasierra-suites/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 531",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-para-dos-en-eurotel-altura/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 532",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-hotel-illa-dor-club-apartaments/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 533",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/guarda/escapada-romantica-en-tivoli-avenida-liberdade-lisboa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 534",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/escapada-especial-relax-en-hotel-balneario-de-sierra-alhamilla/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 535",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-lovers-en-gran-hotel-la-florida/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 536",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-de-lujo-en-canyamel-park-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 537",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-magica-en-arrecife-gran-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 538",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-magica-en-cabanas-park-resort-duplicate-id-87894/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 539",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-lovers-en-quinta-splendida-wellness-and-botanical-garden/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 540",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-romantica-en-h10-casa-del-mar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 541",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-lovers-en-torremirona-golf-spa-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 542",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-antigua-palma-casa-noble/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 543",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-antiestres-en-park-hotel-san-jorge/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 544",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-ensueno-en-pareja-en-sol-tenerife/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 545",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-lovers-en-ibersol-spa-aqquaria/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 546",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/asturias/escapada-magica-en-hosteria-de-torazo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 547",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/guipuzcoa/escapada-antiestres-en-hotel-akelarre/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 548",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valladolid/escapada-romantica-en-palacio-de-las-salinas-balneario-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 549",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-romantica-en-alexandre-hotel-la-siesta/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 550",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-de-lujo-en-beatriz-costa-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 551",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-para-dos-en-elba-palace-golf-vital-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 552",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-de-lujo-en-pullman-barcelona-skipper/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 553",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-wellness-en-alicante-con-estancia-y-spa-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 554",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-magica-en-ght-costa-brava-spa-tossa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 555",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/beja/escapada-especial-relax-en-duna-parque/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 556",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/escapada-magica-en-playasol-aquapark-spa-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 557",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-especial-relax-en-mar-azul/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 558",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-romantica-con-jacuzzi-privado-masaje-y-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 559",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-romantica-para-2-con-estancia-y-ritual-corporal-con-vistas-a-la-bahia-de-sagaro/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 560",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-especial-relax-en-aparthotel-novo-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 561",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-de-ensueno-en-pareja-en-dreams-madeira-resort-spa-marina/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 562",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/segovia/recupera-energia-en-pareja-con-estancia-y-ritual-de-la-alegria-en-el-centro-de-segovia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 563",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-de-ensueno-en-pareja-con-estancia-y-spa-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 564",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-marriotts-club-son-antem/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 565",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-magica-en-grande-real-santa-eulalia-resort-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 566",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/murcia/escapada-de-ensueno-en-pareja-en-termas-balneario-de-archena/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 567",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-romantica-en-pareja-con-estancia-spa-y-masaje-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 568",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-antiestres-en-aqua-hotel-silhouette-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 569",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-lovers-en-zahara-beach-spa-the-senses-collection/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 570",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-de-lujo-en-barcelo-torre-de-madrid/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 571",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-bienestar-en-un-entorno-idilico-con-estancia-spa-y-masaje-local/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 572",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-de-lujo-en-rosamar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 573",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-de-lujo-en-berga-resort-the-mountain-wellness-center-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 574",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-lovers-en-aluasoul-hotel-carolina/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 575",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-de-lujo-en-browns-sports-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 576",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-viva-blue-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 577",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-lujo-en-nobu-hotel-ibiza-bay/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 578",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/leon/escapada-magica-en-hotel-spa-qh-centro-leon/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 579",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valladolid/escapada-para-dos-en-hotel-la-vega/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 580",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-port-ciutadella/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 581",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-lujo-en-insotel-punta-prima-resort-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 582",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-lujo-en-portinatx-beach-club-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 583",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/evora/escapada-de-lujo-en-pousada-convento-de-arraiolos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 584",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/salamanca/escapada-magica-en-orgullo-rural/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 585",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/guarda/escapada-para-dos-en-h2otel-congress-medical-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 586",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-de-ensueno-en-pareja-en-pestana-grand-premium-ocean-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 587",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-lovers-en-h10-marina-barcelona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 588",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-lovers-en-bahiazul-villas-club-fuerteventura/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 589",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-antiestres-en-sant-jordi-thalasso-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 590",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/jaen/escapada-magica-en-hotel-sierra-de-cazorla-3-estrellas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 591",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-romantica-en-eurostars-madrid-tower/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 592",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-de-lujo-en-hotel-spa-porta-maris/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 593",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-antiestres-en-pueblo-acantilado-suites/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 594",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-lovers-en-villas-kamezi/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 595",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-melia-palma-marina/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 596",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alava/recupera-energia-en-pareja-rodeado-de-bodegas-y-vinedos-con-estancia-spa-y-masaje/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 597",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-helios-apartamentos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 598",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-de-lujo-con-spa-y-masaje-de-piedras-calientes-para-2-en-barcelona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 599",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-magica-en-beatriz-playa-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 600",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lleida/escapada-especial-relax-en-aran-la-abuela/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 601",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-h10-blue-mar-boutique-hotel-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 602",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-especial-relax-en-alexandre-hotel-troya/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 603",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/relax-total-en-el-parque-natural-de-montseny-con-estancia-y-masaje-de-aromaterapia-para-dos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 604",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-de-lujo-en-tui-blue-falesia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 605",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-de-lujo-en-dom-pedro-vilamoura/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 606",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-para-dos-en-plaza-azul/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 607",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-especial-relax-en-palacio-de-onate-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 608",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-desconexion-bienestar-con-estancia-de-lujo-spa-y-masaje-relajante-oriental/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 609",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-antiestres-en-los-angeles-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 610",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-de-ensueno-en-pareja-con-estancia-de-lujo-circuito-spa-y-masaje-relax-en-gran-canaria/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 611",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/santarem/escapada-de-lujo-en-dom-goncalo-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 612",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/navarra/escapada-especial-relax-en-bed4u-tudela/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 613",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/desconexion-total-en-tenerife-circuito-spa-masaje-relajante-aromaterapia-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 614",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-antiestres-en-iberostar-selection-lagos-algarve/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 615",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/asturias/escapada-especial-relax-en-eurostars-palacio-de-cristal/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 616",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/jaen/escapada-de-ensueno-en-pareja-en-gran-hotel-spa-marmolejo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 617",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/escapada-lovers-en-servigroup-marina-playa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 618",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huesca/escapada-de-lujo-en-el-privilegio-de-tena/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 619",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/escapada-relajante-con-estancia-y-ritual-bienestar-para-dos-personas-en-roquetas-de-mar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 620",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-magica-en-viva-suites-spa-adults-only-16/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 621",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/asturias/escapada-romantica-en-puebloastur-eco-resort-wellness-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 622",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/a-coruna/recupera-energia-en-pareja-con-estancia-circuito-spa-y-ritual-oriental-incluido-para-dos-en-ferrol/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 623",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/escapada-de-lujo-en-protur-roquetas-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 624",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/a-coruna/escapada-antiestres-en-plaza/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 625",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/portalegre/escapada-antiestres-en-pousada-mosteiro-do-crato/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 626",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/desconexion-total-escapada-en-hotel-con-spa-privado-y-bio-masaje/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 627",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-santa-clara-urban-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 628",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/la-rioja/escapada-antiestres-en-balneario-de-arnedillo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 629",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huesca/escapada-especial-relax-en-mi-casa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 630",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lisboa/escapada-especial-relax-en-ms-aparthotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 631",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/navarra/escapada-especial-relax-en-pamplona-el-toro-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 632",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/desconexion-bienestar-total-escapada-en-hotel-eco-wellness-con-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 633",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-antiestres-en-sol-costa-atlantis-tenerife/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 634",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-magica-en-hotel-spa-la-quinta-park-suites/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 635",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-magica-en-mirachoro-carvoeiro/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 636",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-de-ensueno-en-pareja-en-hd-beach-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 637",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/porto/escapada-de-ensueno-en-pareja-en-pestana-vintage-porto-hotel-world-heritage-site/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 638",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-especial-relax-en-bq-andalucia-beach/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 639",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-romantica-para-2-con-spa-y-masaje-en-gran-canaria/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 640",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cordoba/experiencia-wellness-total-para-dos-en-pleno-centro-historico-de-cordoba-con-estancia-banos-romanos-y-masaje-oriental/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 641",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-sa-cabana-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 642",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-romantica-en-best-costa-ballena/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 643",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-magica-en-mimosa-studio-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 644",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-diamant-aparthotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 645",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/ourense/escapada-para-dos-en-arnoia-caldaria-hotel-balneario/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 646",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-icon-valparaiso-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 647",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huesca/escapada-magica-en-palacio-del-obispo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 648",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lisboa/escapada-magica-en-epic-sana-marques/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 649",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/a-coruna/escapada-lovers-en-doubletree-by-hilton-a-coruna/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 650",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cantabria/escapada-de-ensueno-en-pareja-en-complejo-san-marcos-posada/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 651",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-de-lujo-en-hote-cala-del-pi-beach-retreat-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 652",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-romantica-en-barcelo-jerez-montecastillo-convention-center/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 653",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-lovers-en-hotel-sir-anthony/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 654",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-ensueno-con-estancia-y-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 655",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-de-ensueno-en-pareja-con-estancia-y-spa-para-dos-personas-en-barcelona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 656",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-bienestar-en-el-puerto-de-mogan-con-estancia-y-ritual-oasis-de-serenidad-incluido-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 657",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/sevilla/escapada-especial-relax-en-mercer-eme-catedral-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 658",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-para-dos-en-ohtels-belvedere/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 659",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/albacete/escapada-para-dos-en-blu-hotel-spa-puerta-de-almansa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 660",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/badajoz/escapada-romantica-en-balneario-el-raposo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 661",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-romantica-en-hotel-fenix-gran-melia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 662",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-de-ensueno-en-pareja-en-monte-santo-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 663",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-de-lujo-en-pestana-porto-santo-beach-resort-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 664",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-especial-bienestar-en-cadiz-con-estancia-spa-y-masaje-shi-zen-relax/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 665",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-relajante-con-estancia-y-circuito-de-banos-arabes-masaje-aromatico-en-granada/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 666",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-para-dos-en-bahia-principe-sunlight-costa-adeje/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 667",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/porto/escapada-para-dos-en-vila-gale-porto/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 668",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-lovers-en-impressive-playa-granada/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 669",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/relax-total-al-borde-del-mar-estancia-y-masaje-sueco-para-dos-en-el-sur-de-gran-canaria/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 670",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/experiencia-wellness-total-para-dos-en-primera-linea-de-playa-de-vigo-con-estancia-y-circuito-celta/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 671",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-antiestres-en-allegro-isora/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 672",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-especial-relax-en-fergus-style-nauitc-park-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 673",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-de-ensueno-en-pareja-en-jerez-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 674",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-lovers-en-four-seasons-vilamoura/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 675",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lisboa/escapada-para-dos-en-myriad-by-sana-hotels/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 676",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/guarda/escapada-lovers-en-vila-gale-opera/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 677",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-magica-en-iberostar-grand-salome-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 678",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/coimbra/escapada-magica-en-qh-praia-de-quiaios/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 679",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-de-lujo-en-cordial-mogan-solaz/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 680",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/guarda/escapada-antiestres-en-pestana-palace-lisboa-hotel-national-monument/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 681",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/navarra/escapada-lovers-en-alma-pamplona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 682",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-mar-hotels-playa-mar-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 683",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-para-dos-en-ohtels-la-hacienda/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 684",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-de-ensueno-en-pareja-en-pestana-alvor-praia-premium-beach-golf-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 685",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/toledo/desconexion-total-en-toledo-con-estancia-circuito-hidrotermal-y-masaje-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 686",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-magica-en-rosamar-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 687",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-con-estancia-spa-y-masaje-en-el-corazon-de-granada-para-2-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 688",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-romantica-en-parador-de-javea/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 689",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-especial-relax-en-monchique-resort-and-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 690",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/segovia/escapada-romantica-en-hotel-segovia-sierra-de-guadarrama/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 691",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-lovers-en-melia-fuerteventura/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 692",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-fergus-style-bahamas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 693",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-especial-relax-en-el-sur-de-tenerife-con-circuito-spa-masaje-de-cuello-y-cabeza-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 694",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-hotel-globales-cala-blanca/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 695",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-magica-en-axel-hotel-barcelona-urban-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 696",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/segovia/escapada-wellness-con-estancia-circuito-spa-y-masaje-relajante-en-un-antiguo-convento-segoviano/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 697",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-antiestres-en-buganvilla-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 698",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-antiestres-en-hotel-spa-la-romana-the-originals-relais/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 699",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-para-dos-en-servatur-puerto-azul/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 700",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-torre-del-mar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 701",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-magica-en-hotel-quinta-das-vistas-palace-garden/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 702",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-especial-relax-en-the-level-melia-alicante/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 703",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/leiria/escapada-lovers-en-hotel-mar-e-sol/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 704",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-grupotel-maritimo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 705",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/zaragoza/escapada-romantica-en-reina-petronila-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 706",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-antiestres-en-vila-petra/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 707",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-antiestres-en-intercontinental-barcelona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 708",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-magica-en-hotel-los-calderones-the-adults-recommended/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 709",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/experiencia-relax-total-en-hotel-de-lujo-con-spa-y-masaje-para-dos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 710",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/asturias/escapada-lovers-en-miracielos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 711",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-especial-relax-en-secrets-lanzarote-resort-spa-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 712",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huesca/escapada-de-ensueno-en-pareja-en-tierra-de-biescas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 713",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-ensueno-en-pareja-en-hotel-hacienda-del-conde-melia-collection/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 714",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/relax-ancestral-con-estancia-spa-y-bambuterapia-en-hotel-de-lujo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 715",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/vizcaya/escapada-para-dos-en-radisson-collection-hotel-gran-via-bilbao/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 716",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lugo/escapada-romantica-en-spa-attica-21-villalba/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 717",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valencia/escapada-romantica-en-olympia-hotel-events-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 718",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-especial-relax-en-abac-restaurant-hotel-barcelona-gl-monumento/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 719",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-romantica-en-ocean-gardens/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 720",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-magica-en-santa-barbara-golf-ocean-golf-club-diamond-res/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 721",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-de-lujo-en-hotel-the-volcan-lanzarote/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 722",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-romantica-en-diamant-hotel-aparthotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 723",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-de-lujo-en-zoetry-mallorca-wellness-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 724",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-de-ensueno-en-pareja-en-santa-eulalia-hotel-apartamento-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 725",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-romantica-en-lazure/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 726",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/toledo/desconexion-total-estancia-y-ritual-con-aromaterapia-en-toledo-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 727",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-hotel-honucai/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 728",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valencia/escapada-de-lujo-con-estancia-y-masaje-geotermal-con-vistas-a-los-jardines-del-turia-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 729",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-romantica-en-catalonia-granada/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 730",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huesca/escapada-romantica-en-masmonzon/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 731",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-especial-relax-en-the-views-monumental/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 732",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-antiestres-en-puerta-america-madrid/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 733",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/asturias/escapada-romantica-en-cristina/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 734",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-especial-relax-en-agua-hotels-riverside/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 735",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-magica-en-hard-rock-hotel-tenerife/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 736",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-de-ensueno-en-pareja-en-norat-marina-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 737",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-alua-gran-camp-de-mar-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 738",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-para-dos-en-domo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 739",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-lovers-en-club-la-santa-all-sports-inclusive/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 740",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-magica-en-melia-tamarindos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 741",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-romantica-en-gran-hotel-monterrey/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 742",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-paradiso-garden/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 743",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-romantica-en-blue-sea-puerto-resort-h-canarife-y-bonanza-palace/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 744",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-romantica-en-vilassar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 745",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-boutique-hotel-sant-roc-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 746",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/vila-real/escapada-magica-en-casas-novas-countryside-hotel-spa-events/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 747",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-lovers-en-hotel-estival-park/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 748",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cantabria/escapada-romantica-en-hotel-spa-arha-potes/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 749",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alava/escapada-relax-entre-bodegas-y-vinedos-para-2-con-estancia-spa-y-masaje/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 750",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-deluxe-con-circuito-spa-y-masaje-de-piedras-volcanicas-en-el-sur-de-gran-canaria-para-2-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 751",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/navarra/escapada-romantica-en-plazaola/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 752",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huelva/escapada-romantica-con-estancia-y-masaje-en-cabina-duo-en-huelva-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 753",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valencia/escapada-spa-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 754",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/braga/escapada-antiestres-en-pousada-mosteiro-de-guimaraes-monument-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 755",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-especial-relax-en-hotel-guadalmina/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 756",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-romantica-en-insotel-fenicia-prestige-suites-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 757",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-de-ensueno-con-spa-masaje-tradicional-tailandes-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 758",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-de-ensueno-en-pareja-en-helios/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 759",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lleida/escapada-antiestres-en-sant-roc/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 760",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/albacete/relax-total-con-estancia-circuito-spa-y-masaje-biodescanso-en-castilla-la-mancha-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 761",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-lovers-en-seventy-barcelona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 762",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/a-coruna/escapada-lovers-en-sercotel-odeon/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 763",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/viseu/escapada-para-dos-en-montebelo-aguieira-lake-resort-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 764",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/teruel/escapada-especial-relax-en-balfagon-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 765",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-de-ensueno-en-pareja-en-cais-da-oliveira/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 766",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huesca/escapada-especial-relax-en-eurostars-reina-felicia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 767",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/zaragoza/escapada-antiestres-en-balneario-seron/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 768",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/segovia/escapada-magica-en-tryp-segovia-los-angeles-nayade-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 769",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huesca/escapada-para-dos-en-hotel-spa-real-villa-anayet/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 770",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-axelbeach-ibiza/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 771",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/la-rioja/escapada-lovers-en-balneario-de-gravalos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 772",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-magica-en-fenals-garden/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 773",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/murcia/escapada-antiestres-en-sercotel-jc1-murcia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 774",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cantabria/escapada-antiestres-en-spa-rural-mies-de-rubayo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 775",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/albacete/escapada-con-circuito-spa-en-castilla-la-mancha-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 776",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huelva/escapada-antiestres-en-estival-islantilla/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 777",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/murcia/escapada-especial-relax-en-poseidon-la-manga-hotel-spa-only-adults/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 778",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/setubal/escapada-magica-en-crowne-plaza-caparica-lisbon/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 779",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-cas-comte-petit-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 780",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-para-dos-en-gloria-palace-royal-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 781",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/guadalajara/escapada-romantica-para-2-con-estancia-circuito-privado-y-ritual-en-medio-de-la-naturaleza/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 782",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-ona-garden-lago/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 783",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/escapada-con-estancia-y-bano-de-vino-para-2-en-almeria/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 784",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-romantica-en-hotel-mas-salagros-ecoresort-aire-ancient-baths/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 785",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-especial-relax-en-crowne-plaza-vilamoura-algarve/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 786",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/porto/escapada-para-dos-en-holiday-inn-porto-gaia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 787",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/relax-total-con-estancia-circuito-spa-y-masaje-en-mallorca-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 788",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-especial-relax-en-cordial-mogan-valle/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 789",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/teruel/escapada-antiestres-en-hotel-la-vega/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 790",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-romantica-en-casa-ladico-hotel-boutique-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 791",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-para-dos-en-gran-hotel-nagari-boutique-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 792",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/relax-total-en-el-corazon-de-la-alpujarra-dos-personas-con-estancia-circuito-thermal-y-masaje-completo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 793",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-romantica-en-ohtels-la-hacienda/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 794",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valencia/escapada-relax-en-valencia-con-estancia-circuito-spa-y-masaje-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 795",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-menorca-experimental/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 796",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-para-dos-en-spring-arona-gran-hotel-spa-only-adults/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 797",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-romantica-en-quinta-do-lago/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 798",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/la-rioja/escapada-para-dos-en-fg-logrono/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 799",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/porto/escapada-para-dos-en-belver-beta-porto/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 800",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-wellness-en-el-centro-de-cadiz-con-estancia-y-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 801",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-magica-en-lopesan-villa-del-conde-resort-and-thalasso/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 802",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-lovers-en-port-benidorm-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 803",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-lindner-golf-resort-portals-nous/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 804",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/avila/escapada-de-ensueno-en-pareja-en-fontecruz-avila/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 805",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-desconexion-con-estancia-y-spa-en-hotel-con-vistas-al-mar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 806",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valencia/escapada-desconexion-wellness-con-estancia-y-circuito-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 807",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-protur-sa-coma-playa-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 808",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/soria/escapada-magica-en-castilla-termal-burgo-de-osma/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 809",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/coimbra/escapada-de-ensueno-en-pareja-en-quinta-da-palmeira-country-house-retreat-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 810",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-de-lujo-en-lago-garden-apart-suites-spa-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 811",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-magica-en-pestana-viking-beach-spa-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 812",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-bellamar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 813",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/sevilla/escapada-relax-con-estancia-circuito-termal-y-masaje/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 814",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-de-lujo-en-luna-club-hotel-yoga-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 815",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-de-lujo-en-iberostar-playa-gaviotas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 816",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/castellon/escapada-balneario-con-aguas-mineromedicinales-masaje-relajante-y-parafango-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 817",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-romantica-en-servigroup-la-zenia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 818",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-especial-relax-en-hotel-riu-chiclana/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 819",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-para-dos-en-grand-muthu-oura-view-beach-club/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 820",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valencia/escapada-romantica-en-myr-plaza-mercado-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 821",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/guarda/escapada-lovers-en-the-vintage-lisboa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 822",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-de-lujo-en-nh-malaga/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 823",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-especial-relax-en-hotel-spa-villa-olimpic-suites/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 824",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-spa-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 825",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-antiestres-en-aequora-lanzarote/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 826",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-para-dos-en-tivoli-marina-vilamoura-algarve-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 827",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-antiestres-en-axor-barajas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 828",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-con-estancia-y-spa-para-2-en-pleno-centro-de-madrid/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 829",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/porto/escapada-lovers-en-golden-tulip-porto-gaia-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 830",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-romantica-en-palacio-ca-sa-galesa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 831",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-para-dos-en-bahia-tropical/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 832",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-romantica-en-hotel-balneario-de-zujar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 833",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/desconexion-total-en-pareja-con-vistas-a-la-bahia-de-sagaro-con-estancia-spa-y-masaje/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 834",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cuenca/escapada-para-dos-en-palacio-del-infante-don-juan-manuel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 835",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-de-ensueno-en-pareja-en-hotel-balneario-de-lanjaron/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 836",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-romantica-en-torre-azul/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 837",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-especial-relax-en-urh-ciutat-de-mataro/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 838",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/desconexion-total-escapada-en-hotel-de-4-estrellas-con-estancia-circuito-spa-y-masaje-con-aromaterapia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 839",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-magica-en-h10-gran-tinerfe-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 840",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/recarga-energia-y-renuevate-con-tu-escapada-spa-en-barcelona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 841",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-antiestres-en-sb-corona-tortosa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 842",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-especial-relax-en-hotel-suites-albayzin-del-mar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 843",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lleida/escapada-de-ensueno-en-pareja-en-el-manantial/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 844",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-spa-en-playa-del-ingles/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 845",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/vizcaya/escapada-magica-en-hotel-balneario-orduna-plaza/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 846",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-magica-en-la-cala-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 847",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-de-ensueno-en-pareja-en-occidental-aranjuez/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 848",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/experiencia-wellness-total-para-dos-con-estancia-circuito-spa-y-masaje-corporal-de-frutas-tropicales-para-2-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 849",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/desconexion-total-escapada-en-hotel-con-spa-y-tratamiento-facial-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 850",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-romantica-en-alexandre-hotel-gala/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 851",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alava/escapada-spa-rodeado-de-bodegas-y-vinedos-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 852",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-circuito-spa-privado-en-el-idilico-centro-de-granada-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 853",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lugo/escapada-de-ensueno-en-pareja-en-iberik-balneario-augas-santas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 854",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-bienestar-con-estancia-circuito-spa-y-masaje-con-aromaterapia-en-marbella-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 855",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/murcia/escapada-de-ensueno-en-pareja-en-jardines-de-lorca/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 856",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-de-ensueno-en-pareja-en-melia-castilla/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 857",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-especial-relax-en-pestana-casino-park-hotel-casino/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 858",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-magica-en-bluebay-lanzarote/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 859",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/asturias/escapada-romantica-en-artiem-asturias/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 860",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/vila-real/escapada-de-ensueno-en-pareja-en-douro-scala/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 861",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/descubre-la-isla-de-los-volcanes-con-esta-escapada-de-estancia-circuito-hidrotermal-y-ritual-lanzarote-experience-para-dos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 862",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huesca/escapada-de-lujo-en-apartahotel-spa-jacetania/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 863",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-lovers-en-royal-oasis-club-at-pueblo-quinta-diamond-resorts/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 864",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-para-dos-en-villas-barrocal/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 865",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-especial-relax-en-denia-marriott-la-sella-golf-resort-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 866",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-iberostar-selection-jardin-del-sol-suites-adults/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 867",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/vila-real/escapada-para-dos-en-casino-chaves/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 868",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-lovers-en-almanac-barcelona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 869",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-lujo-en-mon-port-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 870",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-de-ensueno-en-pareja-en-as-cascatas-golf-resort-and-spa-vilamoura/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 871",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/murcia/escapada-magica-en-doubletree-by-hilton-la-torre-golf-spa-resor/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 872",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/leiria/escapada-de-ensueno-en-pareja-en-cristal-vieira-praia-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 873",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lleida/escapada-especial-relax-en-caldas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 874",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huelva/escapada-magica-en-parador-de-mazagon/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 875",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-iberostar-selection-playa-de-muro-village/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 876",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/toledo/escapada-relax-con-estancia-y-circuito-hidrotermal-incluido-en-toledo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 877",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-antiestres-en-la-isla-y-el-mar-hotel-boutique/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 878",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alava/escapada-para-2-con-estancia-y-bano-de-hidromasaje-en-vino-tinto-o-cava-en-la-rioja-alavesa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 879",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alava/escapada-magica-en-silken-ciudad-de-vitoria/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 880",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-especial-relax-en-hotel-spa-benalmadena-palace/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 881",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-de-lujo-con-estancia-y-tratamiento-con-polvo-de-oro-y-sirope-de-perlas-en-mallorca/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 882",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huesca/escapada-de-ensueno-en-pareja-con-estancia-circuito-spa-y-ritual-exfoliante-de-tierras-lejanas-en-formigal/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 883",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-de-lujo-en-ridomar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 884",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-spa-masaje-relajante-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 885",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-de-ensueno-en-pareja-en-pestana-vila-sol-golf-resort-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 886",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-magica-en-gran-hotel-sol-y-mar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 887",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lugo/escapada-magica-en-parador-de-vilalba/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 888",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-especial-relax-en-hotel-pleta-de-mar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 889",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-experiencia-wellness-con-spa-y-ritual-en-pleno-centro-de-barcelonahar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 890",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-relax-con-spa-con-vistas-al-mar-las-montanas-y-al-campo-de-golf-en-gran-canaria/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 891",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-lovers-en-coronado-thalasso-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 892",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/viana-do-castelo/escapada-lovers-en-pousada-de-viana-do-castelo-historic-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 893",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/recupera-energia-en-pareja-con-estancia-y-masaje-al-aire-libre-en-el-sur-de-gran-canaria/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 894",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-romantica-en-mirador/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 895",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-antiestres-en-ria-park-hotel-and-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 896",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-antiestres-en-anantara-vilapadierna-a-leading-hotel-of-the-world/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 897",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-ensueno-en-pareja-en-arenas-del-mar-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 898",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-lovers-en-balneari-termes-de-la-victoria/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 899",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-de-lujo-con-spa-y-ritual-exfoliation-en-un-hotel-vanguardista-para-2-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 900",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lleida/escapada-para-dos-en-la-morera/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 901",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/zaragoza/escapada-romantica-en-hotel-spa-real-ciudad-de-zaragoza/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 902",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-antiestres-en-servatur-casablanca-suites-spa-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 903",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-especial-relax-en-olympus-palace/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 904",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-chocolate-lovers-estancia-y-circuito-de-banos-arabes-masaje-relajante-de-chocolate-en-granada/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 905",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-para-dos-en-orca-praia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 906",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-de-ensueno-en-pareja-en-ritual-el-palmar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 907",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-especial-relax-en-hotel-riu-palace-tres-islas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 908",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-insotel-cala-mandia-resort-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 909",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cantabria/escapada-de-lujo-en-felisa-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 910",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-bon-sol/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 911",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-de-lujo-en-hospes-maricel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 912",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-de-lujo-en-vincci-seleccion-aleysa-hotel-boutique-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 913",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/toledo/escapada-wellness-en-toledo-con-estancia-y-circuito-hidrotermal/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 914",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-de-lujo-en-aqua-hotel-onabrava-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 915",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-hotel-garbi-ibiza-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 916",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/acores/escapada-especial-relax-en-terra-nostra-garden-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 917",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-especial-relax-en-granada-con-estancia-circuito-spa-y-masaje-shi-zen/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 918",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-lovers-en-costa-calero-thalasso-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 919",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/ciudad-real/escapada-para-dos-en-cumbria-spahotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 920",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-especial-relax-en-sercotel-gran-hotel-luna-de-granada/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 921",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-antiestres-en-balneario-de-acuna/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 922",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valencia/escapada-romantica-en-principal/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 923",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-magica-en-portaventura-hotel-colorado-creek/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 924",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-romantica-en-bull-costa-canaria-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 925",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-de-lujo-en-hotel-restaurant-spa-mas-ses-vinyes/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 926",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-lhermitage/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 927",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/escapada-antiestres-en-bahia-serena/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 928",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/leiria/escapada-de-ensueno-en-pareja-en-marriott-praia-del-rey-golf-beach-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 929",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-romantica-en-talaso-hotel-louxo-la-toja/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 930",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-de-ensueno-en-pareja-en-melia-alicante/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 931",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-de-ensueno-en-pareja-en-la-finca-golf-spa-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 932",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-barcelo-portinatx/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 933",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-relax-en-hotel-de-lujo-para-2-en-costa-adeje-tenerife/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 934",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/leon/escapada-romantica-en-leon-hotel-spa-paris/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 935",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-lovers-en-europa-splash/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 936",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-romantica-en-catalonia-atenas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 937",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-romantica-en-hotel-camiral/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 938",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/asturias/escapada-lovers-en-parador-monasterio-de-corias/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 939",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/castelo-branco/escapada-para-dos-en-alambique-hotel-resort-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 940",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-lovers-en-hotel-gran-claustre-restaurante-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 941",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-magica-en-barcelo-tenerife-royal-level/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 942",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/salamanca/escapada-para-dos-en-hacienda-zorita-wine-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 943",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-de-ensueno-en-pareja-en-iberostar-selection-andalucia-playa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 944",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-antiestres-en-hyatt-regency-barcelona-tower/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 945",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-para-dos-en-salles-hotel-aeroport-de-girona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 946",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cantabria/escapada-de-lujo-en-abba-comillas-golf/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 947",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-innside-palma-center/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 948",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-romantica-para-2-con-spa-y-ritual-de-aloe-vera-en-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 949",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-con-acceso-directo-a-la-playa-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 950",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valencia/escapada-total-relax-con-estancia-circuito-spa-y-masaje-shi-zen-relax/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 951",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/zaragoza/escapada-para-dos-en-suites-apartamentos-los-girasoles/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 952",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/desconexion-total-escapada-en-hotel-de-lujo-con-estancia-y-piscina-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 953",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/sevilla/escapada-de-ensueno-en-pareja-con-estancia-y-masaje-de-aromaterapia-en-el-centro-historico-de-sevilla/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 954",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-especial-relax-en-tenerife-con-masaje-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 955",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-romantica-en-hd-parque-cristobal-tenerife/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 956",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-hipotels-playa-de-palma-palace/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 957",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/a-coruna/escapada-romantica-en-parador-costa-da-morte/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 958",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-para-dos-en-coral-suites-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 959",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/castellon/escapada-lovers-en-gran-hotel-las-fuentes/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 960",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-especial-relax-en-mercure-atenea-aventura/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 961",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/santarem/escapada-magica-en-lusitano/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 962",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-de-ensueno-en-pareja-en-catalonia-magdalenes/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 963",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-romantica-en-son-julia-country-house-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 964",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/guipuzcoa/escapada-para-dos-en-catalonia-donosti/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 965",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-de-ensueno-en-pareja-en-labranda-marieta/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 966",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/castellon/escapada-de-lujo-en-aparthotel-spa-acualandia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 967",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/leiria/escapada-antiestres-en-star-inn-peniche/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 968",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/jaen/escapada-de-ensueno-en-pareja-en-balneario-parque-de-cazorla/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 969",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/recupera-energia-en-pareja-con-estancia-spa-y-masaje-celta-de-piedras-calientes-y-frias/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 970",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-magica-en-acevi-villarroel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 971",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-romantica-en-ac-hotel-palacio-del-retiro-autograph-collection/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 972",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huesca/escapada-de-ensueno-en-pareja-en-vinas-de-larrede/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 973",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-de-lujo-con-spa-en-la-costa-de-lanzarote/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 974",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/relax-total-eco-wellness-con-spa-y-masaje-en-el-corazon-de-las-rias-baixas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 975",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-de-lujo-en-vincci-via-66/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 976",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-especial-relax-en-benidorm-con-spa-masaje-relajante-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 977",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/setubal/escapada-de-lujo-en-tryp-lisboa-caparica-mar-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 978",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/caceres/escapada-de-lujo-en-valles-de-gredos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 979",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huelva/escapada-romantica-para-2-con-estancia-y-masaje-con-velas-ecologicas-de-esencias-mediterraneas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 980",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/porto/escapada-para-dos-en-ac-porto/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 981",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-magica-en-h10-salauris-palace/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 982",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-de-ensueno-en-pareja-en-asia-gardens-hotel-thai-spa-a-royal-hideaway-ho/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 983",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-de-lujo-en-un-palacio-del-siglo-xix-con-estancia-y-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 984",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-iberostar-grand-portals-nous/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 985",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-lovers-en-catalonia-plaza-cataluna/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 986",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/murcia/escapada-magica-en-entremares-biobalneario-marino/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 987",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/guarda/escapada-magica-en-altis-grand-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 988",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-magica-en-thb-guya-playa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 989",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-lovers-en-magic-aqua-rock-gardens/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 990",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/escapada-de-lujo-en-reina/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 991",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-nautic-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 992",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/escapada-especial-relax-en-hotel-envia-almeria-spa-golf/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 993",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-forum-boutique-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 994",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-de-lujo-en-vila-baleira-porto-santo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 995",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-bienestar-con-estanciade-lujo-spa-y-masaje-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 996",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/murcia/escapada-antiestres-en-executive-sport/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 997",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-beauty-para-dos-personas-con-estancia-circuito-spa-masaje-relajante-o-drenaje-linfatico-en-motril/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 998",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-antiestres-en-hotel-balneario-de-graena/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 999",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-houm-plaza-son-rigo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1000",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lisboa/escapada-lovers-en-pestana-sintra-golf-resort-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1001",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-bienestar-a-pie-de-mar-con-estancia-masaje-envolvente-y-facial-spa-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1002",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-bienestar-en-hotel-de-lujo-con-estancia-masaje-mediterraneo-y-piscina-spa-para-2-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1003",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/burgos/escapada-con-estancia-spa-en-un-idilico-enclave-natural-con-vistas-a-sus-vinedos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1004",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-especial-relax-en-regency-torviscas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1005",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-romantica-en-baia-azul/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1006",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-de-lujo-en-occidental-jandia-playa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1007",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-de-ensueno-en-pareja-en-radisson-blu-madrid-prado/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1008",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-especial-relax-en-sant-boi-de-llobregat-con-spa-y-masaje/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1009",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-lujo-con-estancia-y-circuito-de-spa-privado-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1010",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-de-lujo-en-lopesan-villa-del-conde-resort-and-thalasso/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1011",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/murcia/escapada-lovers-en-parador-castillo-de-lorca/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1012",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/castellon/escapada-antiestres-en-hotel-rosaleda-del-mijares/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1013",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-de-ensueno-en-pareja-en-puente-romano-beach-resort-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1014",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-especial-relax-en-hotel-riu-costa-del-sol-all-inclusive/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1015",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-lovers-en-baia-cristal-beach-spa-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1016",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-grupotel-playa-de-palma-suites-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1017",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/la-gomera/escapada-de-lujo-en-hotel-jardin-tecina/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1018",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/sevilla/escapada-especial-relax-en-melia-sevilla/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1019",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-antiestres-en-bull-eugenia-victoria-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1020",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-wellness-con-estancia-y-spa-en-gran-canaria/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1021",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-lujo-en-melia-south-beach/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1022",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-lovers-en-buganvilia-studio-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1023",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-antiestres-en-robinson-quinta-da-ria/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1024",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-iberostar-cristina/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1025",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-de-lujo-en-cortijo-del-mar-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1026",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lleida/escapada-de-lujo-en-romanic/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1027",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/viana-do-castelo/escapada-para-dos-en-porta-do-sol-conference-center-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1028",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huelva/escapada-wellness-con-estancia-piscina-climatizada-jacuzzi-y-masaje-relajante-en-huelva-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1029",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-antiestres-en-grand-luxor-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1030",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huesca/escapada-para-dos-en-hotel-spa-aragon-hills/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1031",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-de-lujo-en-navegadores/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1032",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-especial-relax-en-alexandre-hotel-frontair-congress/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1033",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-magica-en-hotel-riu-nautilus-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1034",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-para-dos-en-hotel-best-negresco/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1035",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/relax-total-a-pie-de-playa-con-estancia-circuito-spa-y-masaje-de-tejidos-profundos-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1036",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/guarda/escapada-magica-en-sana-malhoa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1037",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alava/escapada-de-ensueno-en-pareja-en-silken-villa-de-laguardia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1038",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/murcia/escapada-antiestres-en-levante-balneario-de-archena/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1039",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-antiestres-en-ght-aquarium-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1040",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-ensueno-en-pareja-en-adrian-colon-guanahani/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1041",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/vila-real/escapada-antiestres-en-primavera-perfume-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1042",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/escapada-lovers-en-envia-almeria-apartments-spa-golf/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1043",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/experiencia-wellness-total-para-dos-en-salou-on-estancia-circuito-spa-y-masaje-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1044",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-apartamentos-casa-vida/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1045",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/salamanca/escapada-romantica-en-sercotel-horus-salamanca/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1046",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/acores/escapada-antiestres-en-angra-marina-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1047",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-lovers-en-dynastic-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1048",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/segovia/escapada-desconexion-con-estancia-de-lujo-y-circuito-termal/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1049",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-con-spa-en-primera-linea-de-playa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1050",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/relax-total-a-pie-de-playa-con-estancia-spa-y-masaje-para-dos-en-el-sur-de-tenerife/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1051",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-grupotel-nilo-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1052",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-para-dos-en-vila-gale-cerro-alagoa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1053",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-romantica-en-higueron-hotel-malaga-curio-collection-by-hilton/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1054",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-lovers-en-talaso-atlantico/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1055",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-ensueno-en-pareja-en-hovima-santa-maria/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1056",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-grand-palladium-palace-ibiza-resort-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1057",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/vizcaya/escapada-para-dos-en-mercure-bilbao-jardines-de-albia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1058",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-para-dos-en-gran-hotel-rey-don-jaime/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1059",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-romantica-con-estancia-de-lujo-jacuzzi-privado-y-masaje-relax-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1060",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-de-lujo-en-monte-prado-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1061",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-relax-total-a-pie-de-playa-con-estancia-spa-y-masaje-con-aromaterapia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1062",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-trs-ibiza/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1063",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-insotel-tarida-beach-resort-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1064",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-lovers-en-costa-volcan-and-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1065",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-bienestar-de-lujo-con-estancia-y-ritual-de-miel-y-almendras-con-vista-s-al-mar-las-montanas-y-al-campo-de-golf/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1066",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-magica-en-robinson-jandia-playa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1067",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-especial-relax-en-allegro-madeira-adults-only-member-of-barcelo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1068",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-melia-cala-dor-boutique-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1069",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/castelo-branco/escapada-de-lujo-en-purala-wool-valley-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1070",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-antiestres-en-solineu/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1071",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-aluasoul-mallorca-resort-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1072",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/la-rioja/escapada-romantica-en-hotel-marrodan/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1073",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-de-ensueno-en-pareja-en-h10-mediterranean-village/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1074",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-romantica-en-la-bobadilla-a-royal-hideaway-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1075",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-especial-relax-en-benidorm-con-estancia-circuito-spa-wellness-y-masaje-thai-en-pareja/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1076",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-de-lujo-en-areca/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1077",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-de-lujo-en-iberostar-selection-fuerteventura-palace/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1078",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-magica-en-masia-la-palma/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1079",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/zaragoza/escapada-antiestres-en-spa-aguas-de-los-mallos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1080",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/recupera-energia-en-pareja-sin-salir-de-la-ciudad-con-estancia-y-ritual-de-marruecos-para-2-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1081",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lisboa/escapada-romantica-en-valverde-sintra-palacio-de-seteais/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1082",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-de-ensueno-en-pareja-en-westin-la-quinta-golf-resort-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1083",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/escapada-para-dos-en-moon-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1084",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/vizcaya/escapada-especial-relax-en-palacio-urgoiti/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1085",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/caceres/escapada-especial-relax-en-gran-hotel-don-manuel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1086",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lisboa/escapada-romantica-en-pestana-cascais-ocean-and-conference-aparthotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1087",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-romantica-en-mas-sola/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1088",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/sevilla/escapada-antiestres-en-barcelo-sevilla-renacimiento/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1089",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lleida/escapada-de-ensueno-en-pareja-en-iberik-balneari-rocallaura/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1090",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-bahia-del-sol/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1091",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lleida/escapada-lovers-en-riberies/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1092",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-romantica-en-el-cercado-complejo-rural/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1093",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/burgos/escapada-especial-relax-en-hotel-con-encanto-spa-kinedomus-bienestar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1094",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/castellon/escapada-especial-relax-en-peniscola-plaza-suites/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1095",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-lujo-en-protur-naisa-palma/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1096",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-pabisa-bali-park-tower/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1097",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-lovers-en-hotel-spa-norat-o-grove/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1098",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-eix-platja-daurada-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1099",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-para-dos-en-sandos-monaco/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1100",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/zaragoza/escapada-para-dos-en-globales-castillo-de-ayud/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1101",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-antiestres-en-iberostar-malaga-playa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1102",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-especial-relax-en-viva-cala-mesquida-resort-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1103",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-antiestres-en-pazo-los-escudos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1104",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/porto/escapada-para-dos-en-axis-vermar-conference-beach-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1105",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-wellness-con-estancia-y-circuito-de-banos-arabes-en-granada/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1106",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-de-ensueno-en-pareja-en-golden-bahia-de-tossa-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1107",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-de-ensueno-en-pareja-en-les-palmeres/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1108",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-lovers-en-muthu-clube-praia-da-oura/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1109",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/toledo/escapada-de-lujo-en-eurostars-palacio-buenavista/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1110",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-lovers-en-catalonia-del-mar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1111",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/recupera-energia-en-pareja-sin-salir-de-la-ciudad-con-estancia-spa-y-masaje-incluido-para-dos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1112",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-de-lujo-en-los-jameos-playa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1113",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-bienestar-en-pareja-con-estancia-y-masaje-de-piedras-calientes-para-dos-en-la-playa-de-sa-coma/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1114",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-especial-relax-en-jandia-con-estancia-y-masaje-de-piedras-calientes-para-2-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1115",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-especial-relax-en-domes-lake-algarve/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1116",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-romantica-para-2-con-estancia-y-spa-vip-para-dos-personas-en-la-playa-del-albir/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1117",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-lujo-en-simbad/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1118",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-antiestres-en-porto-santo-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1119",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-para-dos-en-parador-de-alcala-de-henares/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1120",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lleida/escapada-romantica-en-aparthotel-siente-boi-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1121",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/relax-total-para-dos-en-vigo-con-estancia-circuito-celta-y-stone-therapy/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1122",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-js-yate/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1123",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-con-estancia-spa-con-vistas-al-teide-y-al-oceano-atlantico/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1124",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-stic-urban/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1125",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-magica-en-occidental-lanzarote-mar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1126",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cordoba/escapada-romantica-en-hotel-madinat/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1127",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-lovers-en-montegordo-hotel-apartamentos-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1128",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huesca/escapada-lovers-en-hotel-sancho-abarca/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1129",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/porto/escapada-para-dos-en-porto-palacio-by-the-editory/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1130",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huesca/escapada-antiestres-en-hotelspa-real-jaca/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1131",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/vizcaya/escapada-de-ensueno-en-pareja-en-silken-gran-hotel-durango/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1132",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/salamanca/escapada-de-ensueno-en-pareja-en-orgullo-rural/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1133",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/vizcaya/escapada-para-dos-en-hotel-spa-etxegana/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1134",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-aluasoul-ibiza/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1135",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-especial-relax-en-madrid-con-spa-masaje-relajante-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1136",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-especial-relax-en-el-charco-suite/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1137",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-magica-en-pure-salt-port-adriano/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1138",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-de-ensueno-en-pareja-en-cordial-mogan-valle/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1139",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/asturias/escapada-especial-relax-en-azz-langrehotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1140",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-romantica-en-barcelo-raval/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1141",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-romantica-en-marquis-hotels-urban/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1142",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-especial-relax-en-hotel-spa-la-residencia-puerto/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1143",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-el-puerto-ibiza-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1144",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/ourense/escapada-de-lujo-en-oca-imi-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1145",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-bienestar-para-2-con-estancia-y-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1146",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-antares/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1147",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-antiestres-en-luna-park-hotel-yoga-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1148",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-de-lujo-en-alpino-atlantico-ayurveda-cure-centre-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1149",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cordoba/escapada-de-ensueno-con-estancia-y-banos-romanos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1150",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-continental-don-antonio/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1151",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/navarra/escapada-de-ensueno-en-pareja-en-zenit-pamplona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1152",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/ourense/escapada-para-dos-en-parador-de-santo-estevo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1153",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-romantica-en-caprice-alcudia-port/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1154",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-especial-relax-en-barcelo-teguise-beach/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1155",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valencia/escapada-de-ensueno-en-pareja-con-estancia-de-lujo-y-masaje-relajante-de-cuerpo-completo-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1156",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/murcia/escapada-de-ensueno-en-pareja-en-lodomar-spa-talasoterapia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1157",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-para-dos-en-bienestar-moana-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1158",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-de-ensueno-en-pareja-en-playaballena-spa-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1159",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-ensueno-en-pareja-con-estancia-circuito-spa-masaje-y-picoteo-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1160",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/escapada-de-ensueno-en-pareja-en-barcelo-cabo-de-gata/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1161",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/escapada-spa-y-masaje-en-un-idilico-enclave-natural-con-vistas-al-mar-mediterraneo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1162",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-para-dos-en-sagaro-hotel-wellness-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1163",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/asturias/escapada-de-lujo-en-eurostars-hotel-la-reconquista/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1164",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-can-canals/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1165",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-especial-relax-en-tenerife-con-spa-masaje-relajante/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1166",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/castellon/escapada-antiestres-en-hotel-spa-castillo-de-peniscola/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1167",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-spa-para-parejas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1168",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-relax-para-2-en-primerisima-linea-de-la-playa-de-arroyo-vaquero-con-estancia-y-spa-incluido/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1169",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-spa-y-masaje-en-un-idilico-enclave-natural-para-dos-personas-en-mallorca/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1170",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-de-lujo-en-albayt-resort-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1171",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/a-coruna/escapada-lovers-en-oca-insua/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1172",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/ciudad-real/escapada-relajante-en-la-historica-ciudad-manchega-de-almagro-con-estancia-circuito-spa-y-masaje-de-chocolate-para-dos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1173",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/leon/escapada-romantica-en-hospedium-hotel-spa-puerta-vadinia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1174",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-especial-relax-en-spa-cap-de-creus-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1175",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-de-ensueno-en-pareja-en-cap-de-mar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1176",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-antiestres-en-ar-diamante-beach-spa-convention-centre/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1177",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-de-lujo-en-hotel-bcl-levante-club-spa-only-adults/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1178",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-para-dos-en-rural-campaniola-s-l/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1179",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/murcia/escapada-antiestres-en-thalasia-costa-de-murcia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1180",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-tacande-portals/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1181",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-magica-en-casares-del-mar-luxury-apartments/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1182",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-romantica-en-hipocampos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1183",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/castellon/escapada-especial-relax-en-rh-vinaros-playa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1184",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-para-dos-en-majestic-hotel-spa-barcelona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1185",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-magica-en-axor-feria/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1186",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-antiestres-en-hotel-fenix-torremolinos-adults-only-recommended/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1187",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valencia/escapada-romantica-en-primus-valencia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1188",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/caceres/escapada-especial-relax-con-estancia-circuito-hidrotermal-y-masaje-oriental/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1189",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/segovia/escapada-especial-relax-en-la-casa-mudejar-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1190",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-romantica-para-2-con-estancia-circuito-spa-y-masaje-en-pareja-en-el-levante/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1191",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-para-dos-en-h10-atlantic-sunset/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1192",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-lovers-en-barcelo-la-nucia-palms/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1193",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-romantica-en-labranda-golden-beach/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1194",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-bless-hotel-ibiza/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1195",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-ponent-mar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1196",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-antiestres-en-hotel-the-tarifa-lances/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1197",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-para-dos-en-hotel-atlantic-mirage-suites-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1198",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-de-lujo-en-blaumar-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1199",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-js-yate/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1200",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-eco-hotel-rural-spa-monnaber-nou/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1201",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-de-lujo-en-hotel-roca-mar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1202",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/albacete/escapada-antiestres-en-hotel-boutique-canitas-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1203",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-romantica-en-grand-muthu-forte-da-oura/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1204",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alava/escapada-romantica-en-hotel-gorbea/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1205",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/leiria/escapada-de-lujo-en-your-hotel-spa-alcobaca/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1206",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/castellon/escapada-de-ensueno-en-pareja-en-xauen/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1207",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lleida/escapada-romantica-en-muntanya-spa-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1208",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/castellon/escapada-de-ensueno-en-pareja-en-gran-duque/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1209",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-melia-calvia-beach/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1210",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-ensueno-en-pareja-en-mediterranean-palace/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1211",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-de-lujo-en-hotel-casa-anamaria/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1212",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-especial-relax-en-ap-eva-senses/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1213",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-sa-creu-nova-petit-palais-art-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1214",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-antiestres-en-bluebay-beach-club/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1215",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-de-ensueno-en-pareja-en-seaside-sandy-beach/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1216",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/ciudad-real/escapada-lovers-en-sercotel-guadiana/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1217",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-lovers-en-golf-boavista/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1218",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-romantica-en-finca-cortesin-golf-and-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1219",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-romantica-en-iberostar-bahia-de-palma-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1220",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-lovers-en-pestana-promenade-ocean-resort-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1221",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-de-ensueno-en-pareja-en-galaico/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1222",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-lovers-en-royal-river-adults-only-16/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1223",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/asturias/escapada-especial-relax-en-hotel-palacio-de-la-magdalena/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1224",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-especial-relax-con-estancia-spa-y-masaje-con-aromaterapia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1225",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valencia/escapada-para-dos-en-parador-de-el-saler/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1226",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-magica-en-la-vinuela/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1227",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-antiestres-en-hotel-unico-madrid/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1228",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-con-estancia-y-envoltura-de-aloe-vera-para-dos-personas-en-un-idilico-enclave-natural-con-vistas-al-oceano-atlantico/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1229",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huelva/escapada-de-ensueno-en-pareja-en-playacartaya-aquapark-spa-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1230",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-magica-en-hotel-las-tirajanas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1231",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/aveiro/escapada-lovers-en-exe-praia-golfe/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1232",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-de-ensueno-en-pareja-en-hotel-fuerte-conil-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1233",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-de-lujo-en-sumus-stella-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1234",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-antiestres-en-guitart-la-molina-aparthotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1235",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huesca/escapada-de-ensueno-en-pareja-en-hotel-golf-spa-real-badaguas-jaca/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1236",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-de-lujo-en-playacalida-spa-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1237",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-antiestres-en-las-caletas-village/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1238",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-lovers-en-casa-velha-do-palheiro-relais-and-chateaux/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1239",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/aveiro/escapada-romantica-en-octant-douro/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1240",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-magica-en-ambassador-playa-i/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1241",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/segovia/escapada-lovers-en-parador-de-la-granja/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1242",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cordoba/escapada-romantica-para-2-con-estancia-y-banos-romanos-privados-en-pleno-centro-historico-de-cordoba/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1243",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/experiencia-wellness-total-en-un-idilico-enclave-natural-en-tenerife/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1244",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/porto/escapada-especial-relax-en-pestana-palacio-do-freixo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1245",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-dreams-calvia-resort-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1246",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-bienestar-para-dos-en-tenerife-con-estancia-y-ritual-de-piedras-calientes/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1247",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-especial-relax-en-solar-de-mos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1248",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-lujo-en-bahia-principe-sunlight-coral-playa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1249",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-secrets-mallorca-villamil-resort-spa-only-adults/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1250",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-con-estancia-y-spa-en-primera-linea-de-playa-en-cadiz/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1251",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/ciudad-real/escapada-magica-en-la-casa-del-rector-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1252",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-ensueno-con-estancia-de-lujo-y-masaje-thai-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1253",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/desconexion-total-en-gran-canaria-con-estancia-spa-y-masaje-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1254",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-insotel-punta-prima-prestige-suites-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1255",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-antiestres-en-aparthotel-atenea-valles/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1256",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-romantica-en-marins-beach-club/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1257",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-romantica-en-barcelo-corralejo-bay/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1258",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-boutique-hotel-posada-terra-santa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1259",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-para-dos-en-ona-marinas-de-nerja/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1260",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-de-lujo-en-aluasun-costa-park/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1261",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/ciudad-real/escapada-lovers-en-chateau-vinasoro/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1262",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-de-lujo-en-hipotels-gran-playa-de-palma/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1263",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-lovers-en-the-views-baia-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1264",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-magica-en-eurostars-las-salinas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1265",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-para-dos-en-the-oasis-by-don-carlos-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1266",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-spa-y-chocolaterapia-para-2-junto-a-la-montana-en-berga-barcelona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1267",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huesca/escapada-para-dos-en-gran-hotel-ciudad-de-barbastro/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1268",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-para-dos-en-club-jandia-princess/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1269",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/escapada-magica-en-apartamentos-mexico/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1270",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/zamora/escapada-especial-relax-en-eurostars-valbusenda-hotel-bodega-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1271",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-lovers-en-alcazar-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1272",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-para-dos-en-nanin-playa-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1273",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-para-dos-en-real-marina-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1274",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-de-lujo-en-suitopia-sol-y-mar-suites-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1275",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-iberostar-selection-playa-de-palma/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1276",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/a-coruna/escapada-especial-relax-en-nh-collection-a-coruna-finisterre/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1277",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-lujo-en-hotel-victoria-gran-melia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1278",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-hotel-son-trobat-wellnessspa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1279",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/asturias/escapada-antiestres-en-princesa-munia-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1280",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/la-rioja/escapada-para-dos-en-palacios/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1281",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alava/escapada-spa-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1282",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-magica-en-melia-madrid-princesa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1283",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-ensueno-en-pareja-con-estancia-de-lujo-spa-privado-y-masaje-para-dos-personas-en-tenerife/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1284",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-para-dos-en-vincci-tenerife-golf/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1285",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-grupotel-gran-vista-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1286",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-de-ensueno-en-pareja-en-nuevo-vichona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1287",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-lovers-en-elba-costa-ballena-beach-thalasso-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1288",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cantabria/escapada-de-ensueno-en-pareja-en-arha-reserva-del-saja/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1289",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lleida/escapada-de-ensueno-en-pareja-en-grand-hotel-central/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1290",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-para-dos-en-la-laguna-spa-golf/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1291",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-antiestres-en-occidental-jandia-mar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1292",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/castellon/escapada-lovers-en-agora-spa-resorts/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1293",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-ensueno-en-pareja-en-alexandre-hotel-gala/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1294",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-especial-relax-en-el-capistrano-village/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1295",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-magica-en-hotel-arts/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1296",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-lovers-en-pazo-los-escudos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1297",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-romantica-en-grupotel-los-principes-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1298",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/palencia/escapada-romantica-en-posada-de-la-casa-del-abad/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1299",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/viseu/escapada-antiestres-en-the-wine-house-hotel-quinta-da-pacheca/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1300",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-especial-relax-en-magic-robin-hood-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1301",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-bienestar-con-estancia-en-primera-linea-de-playa-spa-y-masaje-incluido-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1302",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-antiestres-en-real-bellavista-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1303",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-rafa-nadal-sports-centre/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1304",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-de-ensueno-en-pareja-en-club-maspalomas-suites-spa-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1305",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-lovers-en-hotel-balneario-termaeuropa-playa-de-coma-ruga/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1306",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/asturias/escapada-especial-relax-en-blanco-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1307",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-romantica-en-gran-hotel-bali/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1308",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/sevilla/experiencia-wellness-total-para-dos-en-pleno-centro-de-sevilla-con-estancia-y-ritual-de-oriente/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1309",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-myseahouse-flamingo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1310",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-de-ensueno-en-pareja-en-nobu-hotel-barcelona/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1311",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-romantica-en-eden-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1312",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-magica-en-gloria-palace-amadores-thalasso-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1313",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-hotel-cappuccino-palma-formerly-hotel-mama/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1314",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-lovers-en-ohtels-les-oliveres-beach-resort-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1315",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-de-ensueno-en-pareja-en-elba-estepona-gran-hotel-thalasso-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1316",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-romantica-en-principe/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1317",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-para-dos-en-bull-dorado-beach-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1318",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/segovia/escapada-lovers-en-artesa-suites-spa-only-adults/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1319",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huesca/escapada-de-ensueno-en-pareja-en-hotel-spa-pena-montanesa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1320",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-de-ensueno-en-pareja-en-vincci-capitol/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1321",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-romantica-en-florida-park/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1322",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-especial-relax-en-tenerife-con-estancia-circuito-spa-y-masaje-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1323",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-especial-relax-en-hotel-creu-de-tau-only-adults/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1324",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-especial-relax-en-villa-paulita/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1325",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-romantica-para-2-con-estancia-en-primera-linea-de-playa-spa-y-masaje-incluido/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1326",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-cas-cabo-nou-agroturisme-only-adults/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1327",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-ensueno-en-pareja-en-the-suites-at-the-mirage/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1328",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-hoposa-uyal/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1329",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/escapada-especial-relax-frente-al-mar-mediterraneo-con-estancia-y-circuito-spa-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1330",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/castellon/escapada-de-ensueno-en-pareja-en-estudios-rh-vinaros/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1331",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huesca/escapada-lovers-en-hotel-rural-spa-el-mirador-de-los-pirineos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1332",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/toledo/escapada-romantica-en-pareja-con-estancia-circuito-hidrotermal-y-masaje-con-aceites-esenciales/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1333",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-especial-relax-en-hotel-puertobahia-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1334",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-para-dos-en-yellow-praia-monte-gordo-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1335",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-antiestres-en-the-level-at-melia-villaitana/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1336",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lisboa/escapada-de-lujo-en-riviera-hotel-carcavelos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1337",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-antiestres-en-holiday-club-puerto-calma/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1338",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-especial-relax-en-weare-hotel-la-paz/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1339",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/zaragoza/escapada-para-dos-en-balneario-sicilia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1340",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-antiestres-en-pestana-bay-ocean-aparthotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1341",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/leiria/escapada-para-dos-en-miramar-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1342",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cantabria/escapada-magica-en-eurostars-hotel-real/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1343",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-antiestres-en-alannia-costa-blanca/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1344",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-romantica-en-elba-sunset-mallorca-thalasso-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1345",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-para-dos-en-beverly-hills-heights/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1346",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lisboa/escapada-romantica-en-onyria-quinta-da-marinha-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1347",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-especial-relax-en-radisson-blu-resort-spa-gran-canaria-mogan/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1348",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lugo/escapada-bienestar-en-el-centro-de-burela-con-spa-privado-y-masaje-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1349",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/relax-total-en-pareja-frente-al-mar-mediterraneo-con-estancia-spa-y-masaje/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1350",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/la-palma/escapada-de-ensueno-en-pareja-en-hotel-hacienda-de-abajo/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1351",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-de-lujo-en-salles-hotel-spa-mas-tapiolas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1352",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-romantica-en-hotel-spa-galatea/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1353",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lleida/escapada-romantica-en-finca-prats-hotel-golf-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1354",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-lovers-en-parador-de-cadiz-atlantico/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1355",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lugo/escapada-romantica-en-las-sirenas-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1356",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/experiencia-romantica-relax-total-con-spa-privado-y-masaje-de-piedras-volcanicas-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1357",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-romantica-en-bahia-almunecar/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1358",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-be-live-collection-palace-de-muro/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1359",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-de-ensueno-en-pareja-en-r2-romantic-fantasia-dreams-suites-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1360",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/caceres/escapada-con-estancia-circuito-hidrotermal-y-masaje-con-armaterapia-en-un-idilico-enclave-natural/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1361",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-lovers-en-golden-port-salou-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1362",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/asturias/escapada-de-lujo-en-maria-manuela/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1363",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-romantica-en-ar-roca-esmeralda-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1364",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-especial-relax-en-caleia-talayot-spa-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1365",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-de-ensueno-en-pareja-en-ozadi-tavira-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1366",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-para-dos-en-augusta-spa-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1367",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-romantica-en-ar-imperial-park-spa-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1368",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-wellness-estancia-spa-y-masaje-con-aromaterapia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1369",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-de-lujo-en-le-meridien-ra-beach-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1370",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/badajoz/escapada-especial-relax-en-el-corazon-de-merida-con-spa-privado-y-masaje-con-piedras-volcanicas-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1371",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-romantica-en-hotel-sorli-emocions/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1372",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-de-ensueno-en-pareja-en-caprici-beach-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1373",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-lovers-en-beatriz-costa-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1374",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/guipuzcoa/escapada-wellness-con-estanca-y-circuito-termal-en-la-costa-del-pais-vasco/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1375",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-romantica-en-htop-calella-palace-family-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1376",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-magica-en-palmeras-garden/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1377",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-lovers-en-balneari-vichy-catalan/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1378",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-hotel-de-mar-gran-melia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1379",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-especial-relax-en-granada-con-circuito-spa-masaje-relajante-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1380",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-para-dos-en-minho/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1381",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lleida/escapada-de-ensueno-en-pareja-en-parador-de-vielha/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1382",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-magica-en-robinson-esquinzo-playa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1383",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/escapada-romantica-en-de-naturaleza-rodalquilar-spa-cabo-de-gata/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1384",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/ourense/escapada-magica-en-hotel-oca-vila-de-allariz/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1385",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/caceres/escapada-de-ensueno-con-estancia-y-circuito-hidrotermal/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1386",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/leiria/escapada-antiestres-en-comendador/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1387",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-romantica-en-grupotel-acapulco-playa-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1388",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-artiem-capri/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1389",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-lovers-en-vale-doliveiras-quinta-resort-and-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1390",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-especial-relax-en-pareja-con-estancia-y-masaje-ritual-en-pareja-para-dos-en-la-playa-de-sa-coma/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1391",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-antiestres-en-holiday-world-polynesia-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1392",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/vizcaya/escapada-romantica-en-balneario-areatza/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1393",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-para-dos-en-los-amigos-beach-club-by-diamond-resorts/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1394",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-magica-en-anantara-vilamoura-algarve-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1395",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-magica-en-ilusion-markus-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1396",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-lujo-en-laguna-nivaria-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1397",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-para-dos-en-hotel-wellington-madrid/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1398",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-de-lujo-en-isla-de-la-garena/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1399",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-six-senses-ibiza/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1400",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-lovers-en-barcelo-castillo-beach-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1401",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valencia/escapada-romantica-para-2-con-spa-privado/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1402",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-antiestres-en-parador-de-cruz-de-tejeda/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1403",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/escapada-de-ensueno-en-pareja-en-atenea-port-barcelona-mataro/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1404",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-romantica-en-royal-sunset-beach-club-by-diamond-resorts/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1405",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/santarem/escapada-de-lujo-en-lux-fatima-park/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1406",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-para-dos-en-innside-by-melia-fuerteventura/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1407",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/jaen/escapada-de-ensueno-en-pareja-en-hotel-spa-sierra-de-cazorla-4-estrellas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1408",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madeira/escapada-de-lujo-en-sentido-galomar-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1409",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/madrid/escapada-antiestres-en-miguel-angel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1410",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/desconexion-total-en-primera-linea-de-playa-con-estancia-spa-masaje-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1411",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/viseu/escapada-antiestres-en-palace-hotel-spa-monte-rio-termas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1412",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/ceuta/escapada-romantica-en-ceuta-puerta-de-africa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1413",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-de-ensueno-en-pareja-en-bela-vista-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1414",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-de-lujo-en-ap-adriana-beach-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1415",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-ensueno-en-pareja-en-marylanza-suites-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1416",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-romantica-en-h10-big-sur-boutique-hotel-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1417",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-especial-relax-con-spa-y-masaje-con-aromaterapia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1418",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/a-coruna/escapada-antiestres-en-congreso/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1419",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/la-rioja/escapada-especial-relax-en-los-bracos/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1420",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-antiestres-en-redlevel-at-melia-palacio-de-isora/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1421",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-en-hotel-de-5-estrellas-con-spa-y-masaje-de-piedras-calientes/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1422",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/girona/escapada-de-lujo-en-htop-royal-star-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1423",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/alicante/escapada-magica-en-dna-monse-hotel-spa-golf/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1424",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-de-ensueno-en-pareja-en-healthouse-las-dunas-5-estrellas-gl-health-beach/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1425",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lisboa/escapada-magica-en-senhora-da-guia-cascais-boutique-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1426",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-especial-relax-en-eurostars-gran-via/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1427",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lanzarote/escapada-magica-en-thb-tropical-island/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1428",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/castellon/escapada-balneario-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1429",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/la-palma/escapada-lovers-en-h10-taburiente-playa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1430",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/pontevedra/escapada-de-ensueno-en-pareja-en-hotel-spa-norat-torre-do-deza/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1431",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-barcelo-illetas-albatros/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1432",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-romantica-en-palas-pineda/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1433",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-antiestres-en-eix-alcudia-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1434",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/gran-canaria/escapada-especial-relax-en-gloria-palace-amadores-thalasso-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1435",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/relax-total-en-el-corazon-de-la-serra-tramuntana-con-estancia-y-masaje-con-piedras-calientes-y-semi-preciosas-hammam/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1436",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/almeria/relax-total-a-pie-de-playa-con-estancia-circuito-spa-y-masaje-con-aromaterapia-para-dos-personas/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1437",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tarragona/escapada-de-lujo-en-golden-costa-salou/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1438",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lleida/escapada-especial-relax-en-hotel-vielha-baqueira-affiliated-by-melia/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1439",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/mallorca/escapada-lovers-en-cap-vermell-grand-hotel/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1440",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lisboa/escapada-especial-relax-en-dolce-camporeal-lisboa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1441",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-de-ensueno-en-pareja-con-estancia-en-el-sur-de-tenerife-y-masaje-de-oro-y-aceites-relajantes/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1442",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/aveiro/escapada-antiestres-en-alegre-hotel-bussaco/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1443",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/fuerteventura/escapada-romantica-en-occidental-jandia-royal-level/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1444",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/murcia/escapada-lovers-en-leon-balneario-de-archena/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1445",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-de-ensueno-en-pareja-en-estival-torrequebrada/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1446",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/tenerife/escapada-romantica-en-tenerife-con-estancia-y-circuito-spa-privado-en-pareja/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1447",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-the-ibiza-twiins/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1448",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-magica-en-pine-cliffs-residence/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1449",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-romantica-en-hispania/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1450",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huelva/escapada-lovers-en-barcelo-aracena/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1451",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huelva/escapada-relajante-para-2-con-estancia-y-masaje-energetico-cerca-del-algarve-portugues/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1452",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/murcia/escapada-lovers-en-senator-mar-menor-golf-spa-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1453",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/malaga/escapada-de-ensueno-en-pareja-en-melia-costa-del-sol/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1454",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/experiencia-wellness-total-para-dos-con-estancia-y-circuito-spa-cerca-del-parque-natural-de-montseny/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1455",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-de-ensueno-en-pareja-en-protur-biomar-gran-hotel-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1456",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/castellon/escapada-para-dos-en-marina-dor-5/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1457",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-romantica-en-duva/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1458",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-lovers-en-barcelo-hamilton-menorca-adults-only/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1459",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/valencia/escapada-antiestres-en-sh-valencia-palace/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1460",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-para-dos-en-hotel-rural-son-manera/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1461",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/huelva/escapada-romantica-en-fuerte-el-rompido-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1462",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/barcelona/experiencia-wellness-total-en-un-idilico-enclave-natural-con-spa-y-masaje-para-2/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1463",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/lleida/escapada-magica-en-hotel-spa-acevi-val-daran/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1464",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cantabria/escapada-romantica-en-hotel-balneario-parque-de-alceda/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1465",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/burgos/escapada-romantica-en-pareja-con-estancia-y-spa-privado/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1466",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/baleares/escapada-especial-relax-en-pure-salt-garonda/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1467",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cantabria/escapada-magica-en-arha-villa-de-suances-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1468",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-de-ensueno-en-pareja-en-hilton-vilamoura-as-cascatas-golf-resort-spa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1469",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/cadiz/escapada-antiestres-en-hipotels-playa-la-barrosa/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1470",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/faro/escapada-especial-relax-en-tivoli-lagos-algarve-resort/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  },
  {
    name: GetAwayDetail_46pagezm2QR8BuUSMeta?.name ?? "GetAway landing: 1471",
    path: GetAwayDetail_46pagezm2QR8BuUSMeta?.path ?? "/escapadas-hotel-spa/granada/escapada-con-estancia-en-un-palacio-del-siglo-xix-con-spa-y-masaje/",
    props: GetAwayDetail_46pagezm2QR8BuUSMeta?.props ?? false,
    meta: GetAwayDetail_46pagezm2QR8BuUSMeta || {},
    alias: GetAwayDetail_46pagezm2QR8BuUSMeta?.alias || [],
    redirect: GetAwayDetail_46pagezm2QR8BuUSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-packages-44b37739-15dd-4637-9610-d13cc1f9fc3b/b2c-build/core/pages/get-away/GetAwayDetail.page.vue")
  }
]