const pt = {
  singular: {
    'search': 'Pesquisar',
    'filter': 'filtrar',
    'filter-by': 'filtrar por',
    'region': 'região',
    'experience': 'experiência',
    'found': 'encontrado',
    'sort-by': 'ordenar por',
    'recommended': 'recomendado',
    'most-valued': 'mais valorizado',
    'cheapest-service': 'serviço mais barato',
    'most-expensive-service': 'serviço mais caro',
    'see': 'ver',
    'see-more': 'ver mais',
    'see-less': 'ver menos',
    'see-options': 'ver opções',
    'see-spas': 'ver spas',
    'XX-in-XX': '{{left}} em {{right}}',
    'coupon': 'cupão',
    'buy': 'comprar',
    'book': 'reserva',
    'continue': 'prosseguir',
    'back': 'voltar',
    'go-back': 'voltar atrás',
    'hotel-kind': 'tipo de hotel',
    'cheapest-hotel': 'Hotel mais barato',
    'most-expensive-hotel': 'Hotel mais caro',
    'hotel-and-spa': 'hotel + spa',
    'other': 'outro',
    'remove': 'retirar',
    'apply': 'aplicar',
    'code': 'código',
    'remember-exc': 'Lembras-te!',
    'pay': 'pagar',
    'thanks': 'obrigado',
    'here': 'aqui',
    'warning': 'Alerta',

    'christmas': 'natal',
    'christmas-special': 'especial de natal',
    'christmas-and-couple': 'natal + casais',
    'christmas-and-food': 'natal + comida',

    'valentines': 'Valentim',
    'saint-valentines': 'São Valentim',
    'valentines-special': 'Especial São Valentim',

    'go-to-XX': 'Ir para {{place}}',

    'loc-type-country': 'país',
    'loc-type-community': 'comunidade',
    'loc-type-district': 'distrito',
    'loc-type-parish': 'paróquia',
    'loc-type-region': 'província',
    'loc-type-city': 'cidade',

    'gift-voucher': 'vale de presente',
    'show-all-photos': 'mostrar todas as fotos',
    'your-getaway-includes': 'a sua escapadela inclui',
    'no-availability-for-given-dates-and-pax': 'não temos disponibilidade para as datas e pessoas seleccionadas.',
    'no-rates-for-given-dates-and-pax': 'não há tarifas disponíveis para esta configuração',
    'discount-for-time': 'desconto por tempo limitado',
    'night': 'noite',
    'step-personal-data': 'Dados pessoais',
    'step-payment-options': 'Opções de pagamento',
    'no-refundable': 'não reembolsável',
    'no-refundable-get-away': 'Não reembolsável. Não são permitidos cancelamentos e alterações de datas.',
    'no-changes-nor-cancel-allowed': 'não são permitidos cancelamentos ou alterações de datas',
    'refunds-label-date': 'Cancelamento gratuito até {{deadline}}, depois disso terá um custo de {{price}}',
    'refunds-label-time': 'Cancelamento gratuito até às {{deadline}}, após o que será cobrada uma taxa de {{price}}',

    'purchase-has-to-accept-conditions': 'Deve aceitar as condições gerais para poder prosseguir com o seu processo.',

    'downloading-purchase-data': 'descarregando os detalhes da sua compra',
    'downloading-booking-data': 'descarregando os dados da sua reserva',
    'confirming-voucher': 'estamos a confirmar o seu voucher.',
    'confirming-booking': 'estamos a confirmar a sua reserva.',
    'voucher-confirm-takes-a-minute': 'isto pode demorar alguns minutos, por isso seja paciente.',

    'were-sorry': 'Lamentamos muito.',
    'an-error-has-ocurred-contact-support': 'Ocorreu um erro, contacte o nosso serviço técnico',
    'apologise-for-inconvenience': 'Pedimos desculpa pelo incómodo.',
    'you-can-try-again-here': 'pode tentar novamente a sua compra clicando em',

    'an-error-has-ocurred': 'Ocorreu um erro!',
    'the-purchase-could-not-be-started': 'Não conseguimos iniciar o processo de compra.',
    'please-try-it-again-later': 'Por favor, inténtelo más tarde.',
    'if-problems-persist-contact-support': 'Se continuar a ter problemas, contacte o nosso serviço técnico.',

    'booking-cannot-continue': 'Não foi possível continuar a reserva. Por favor, tente novamente mais tarde',
    'booking-cannot-be-located-support': 'Não foi possível localizar a reserva. Por favor, contacte-nos e entraremos em contacto consigo o mais rapidamente possível.',
    'booking-is-already-booked': 'Esta reserva já foi confirmada',

    'voucher-cannot-continue': 'Não foi possível continuar a compra. Por favor, tente novamente mais tarde',
    'voucher-cannot-be-located-support': 'Não foi possível localizar a venda. Por favor, contacte-nos e entraremos em contacto consigo o mais rapidamente possível',
    'voucher-file-cannot-be-located-support': 'Não foi possível localizar o voucher. Por favor, contacte-nos e entraremos em contacto consigo o mais rapidamente possível.',

    'voucher-file-you-may-download': 'Se preferir, pode descarregar o voucher diretamente:',
    'voucher-file-btn': 'Descarregar o voucher em PDF',

    'your-voucher-is-confirmed': 'O seu voucher foi confirmado com êxito',
    'you-will-receive-voucher-email': 'Receberá em breve o voucher e o número de telefone do spa para marcar uma consulta por correio eletrónico',
    'remember-voucher-at-spa': 'Não esquecer que é necessário apresentá-lo em formato físico ou digital.',
    'contact-support-with-any-question': 'Se tiver alguma dúvida, não hesite em contactar-nos através do e-mail',
    'booking-get-your-bags-ready': 'Faça as malas porque vai passar uns dias incríveis em',
    'congratulations-XX': 'Parabéns {{client}}!',

    'newsletter-posible-discount': 'Receba um código de desconto de 5% na sua primeira compra:',
    'newsletter-sub-pls': 'Subscreva a nossa Newsletter para obter o desconto',
    'newsletter-sub-notice': 'Não se aplica a serviços com desconto e Escapadinhas Wellness',
    'newsletter-country-selector': 'selecionar o país',
    'newsletter-btn': 'Obter um desconto',

    'newsletter-subscribed': 'Obrigado por subscrever a nossa newsletter!',
    'newsletter-subscribed-secondary': 'Receberá o seu código de desconto no seu e-mail em breve.',
    'newsletter-error': 'Não foi possível registar os dados. Pedimos desculpa pelo incómodo',

    'payment-method': 'modo de pagamento',
    'credit-card-payment': 'Pagar com cartão de crédito',
    'paypal-payment': 'Pagar com Paypal',
    'all-transtactions-are-safe': 'Todas as transacções efectuadas neste sítio são seguras',
    'credit-card-data-errors': 'Ocorreu um erro com os dados do seu cartão, por favor tente novamente.',
    'to-try-it-again': 'Para tentar novamente',
    'click-here': 'clique aqui',

    'name': 'nome',
    'surname': 'apelido',
    'email': 'e-mail',
    'confirm-email': 'confirmar o e-mail',
    'phone': 'telefone',
    'name-and-surname': 'nome e apelido',

    'offer-accept-label': 'Aceito receber informações e ofertas por correio eletrónico.',

    'ive-read-and-accept': 'Li e aceito as ',
    'and the': ' e as ',
    'service-terms-and-conditions': 'termos e condições do serviço',
    'terms-and-conditions': 'termos e condições',
    'hotel-spa-terms-and-conditions': 'Termos e Condições do Hotel e Spa',

    'buyer-form-name-and-surname-error': 'El nombre y apellidos no son correctos',
    'buyer-form-name-error': 'O nome não está correto',
    'buyer-form-surname-error': 'O apelido não está correto ',
    'buyer-form-email-error': 'O e-mail não está correto',
    'buyer-form-phone-error': 'O número de telefone não está correto',
    'buyer-form-terms-error': 'É necessário aceitar as condições gerais',
    'newsletter-form-location-error': 'A localização não é válida',

    'if-thereis-coupon-applies-next-step': 'Se tiver um código de desconto, este é aplicado no passo seguinte.',

    'i-have-discount-coupon': 'Tenho um código de desconto',
    'coupon-XX-applied': 'Cupão "{{label}}" aplicado',
    'coupon-XX-applied-with-XX-value': 'Código "{{label}}" com {{discount}}% de desconto aplicado corretamente!',

    'lunes': 'segunda-feira',
    'martes': 'terça-feira',
    'miercoles': 'quarta-feira',
    'jueves': 'quinta-feira',
    'viernes': 'sexta-feira',
    'sabado': 'sábado',
    'domingo': 'domingo',
    'festivo': 'festivo',
    'festivos': 'feriados',

    'special-conditions': 'Condições especiais',

    'hotels-with-spa': 'hotéis com spa',

    'from-value': 'a partir de',

    'select-price': 'selecionar uma quantia',

    'minute': 'minuto',

    'environment': 'ambiente',
    'service': 'serviço',
    'attention': 'atenção',
    'quality-price': 'qualidade-preço',
    'comment': 'comentário',

    'the-spa': 'o spa',
    'the-hotel': 'o hotel',

    'you-will-find-in-XX': 'Encontrará no {{spaName}}',

    'send': 'envio',
    'sent-comment': 'comentário enviado',

    'reviews-last': 'Avaliações mais recentes',
    'reviews-add': 'Adicionar avaliação',
    'reviews-empty': 'Não há classificações recentes',

    'description': 'descrição',

    'unique-benefits': 'Benefícios únicos',

    'enjoy-once-is-open': 'aproveite quando reabrir',
    'enjoy-once-spa-is-open': 'desfrutar quando o spa reabrir',
    'couple': 'casal',
    'couple-special': 'casal especial',
    'included-in-service': 'este serviço inclui',
    'included-in-XX-min-service': 'Este serviço de {{time}} minutos inclui',
    'your-dates': 'As suas datas',

    'is-gift-voucher-available': 'vales de oferta disponíveis',
    'available-service': 'serviço disponível',
    'available-service-in-category': 'serviço disponível na categoria',
    'service-for': 'serviço para',
    'service-duration': 'duração do serviço',

    'spa-status-online': 'Spa aberto',
    'spa-status-disabled': 'Spa desativado',
    'spa-status-closed': 'Spa fechado',
    'spa-status-closedtemp': 'Spa fechado para temporada',
    'spa-status-lost': 'Spa perdido',

    'no-available-services': 'não existem serviços disponíveis',
    'no-available-services-secondary': 'De momento, não podemos oferecer-lhe qualquer serviço com os filtros seleccionados',

    'no-available-treatments': 'não existiam tratamentos disponíveis',
    'no-available-treatments-secondary': 'neste momento, não podemos propor-lhe qualquer tratamento com os filtros seleccionados.',

    'no-available-promotions': 'Não foram encontradas promoções disponíveis',
    'no-available-promotions-secondary': 'De momento, não podemos oferecer-lhe quaisquer promoções com os filtros seleccionados.',

    'no-available-spas': 'Não foram encontrados Spas Disponíveis',
    'no-available-spas-secondary': 'De momento, não podemos oferecer-lhe quaisquer spas com os filtros seleccionados.',

    'no-available-get-away': 'Não foram encontradas escapadelas disponíveis',
    'no-available-get-away-secondary': 'De momento, não podemos oferecer-lhe nenhum Escapade com os filtros seleccionados.',

    'no-available-get-away-availability': 'Não foram encontradas Escapadelas disponíveis para as datas:',
    'no-available-get-away-availability-secondary': 'Por favor, seleccione outras datas',

    'spa-schedule': 'Horário de abertura do spa',

    'services-and-treatments': 'serviços e tratamentos',

    'summary-spa-closedtemp': 'Spa fechado para temporada. Aproveite o voucher quando o spa reabrir.',
    'summary-voucher-pdf': 'Receberá um voucher em PDF e um número de telefone para marcar uma reserva.',
    'summary-change-refund': 'Trocas e devoluções até 14 dias após a compra.',
    'summary-change-refund-chrst': 'Trocas e reembolsos até 20 de janeiro.',
    'summary-expiration': 'Se expirar, trocamo-lo por um cartão de oferta de igual valor.',
    'summary-service-unavailable': 'Serviço não disponível',
    'summary-voucher-expirate-month': 'Pode ser resgatado durante nos próximos {{months}} meses.',
    'summary-voucher-expirate-day': 'Pode ser resgatado durante nos próximos {{days}} dias.',

    'footer-company-code': 'Informamos que o código de identificação atribuído a esta atividade é',
    'contact-us': 'Contacte-nos!',
    'spa-gift-voucher': 'Vouchers de oferta Spa',
    'stay': 'estadia',
    'receive-offers': 'Receba as melhores oportunidades para o seu intervalo no seu e-mail!',
    'search-spas': 'Pesquisar spas',

    'only-hotel': 'apenas hotel',
    'person': 'pessoa',
    'destination': 'Destino',
    'departure': 'partida',
    'arrive': 'chegada',
    'treatment': 'tratamento',
    'promotion': 'promoção',
    'massage': 'massagem',
    'hydrotherapy': 'hidromassagem',
    'ritual': 'ritual',
    'food': 'comida',
    'hotel': 'hotel',
    'offer': 'oferta',
    'booking': 'reserva',
    'online-booking': 'reserva online',

    'booking-for': 'reserva para {{label}}',

    'treatment-in': 'tratamento em {{location}}',

    'get-away-if-you-want-more-people': 'Se pretender pesquisar para mais de 2 pessoas, pode utilizar o motor de busca geral ou aceder aos detalhes de um Escapadinha',
    'get-away-you-can-modify': 'Pode personalizar a sua escapadinha',
    'get-away-price-for': 'Preços para as datas',
    'get-away-no-availability-for-dates': 'Não há escapadelas disponíveis para as datas',
    'search-other-date': 'Pesquisar outra data',
    'search-by-date': 'Pesquisar por data',
    'search-for-two': 'Pesquisa para 2 pessoas',

    'no-search-results-for-XX': 'Não foram encontrados resultados para a pesquisa:',
    'no-search-results-alternatives': 'Aqui estão outros resultados semelhantes à sua pesquisa',

    'escapada': 'escapada',
    'spa': 'spa',

    'are-you-spa': 'É um spa?',

    'mothers-day': 'Dia da Mãe',

    'gift-card': 'Cartões de oferta',
    'gift-card-1': 'Bem-estar de sonho 25€',
    'gift-card-2': 'Relaxamento Sublime 50€',
    'gift-card-3': 'Oásis de luxo 80€',
    'gift-card-4': 'Experiência sensorial 100€',
    'gift-card-5': 'Eternal Whim 120€',

    'special-moments': 'Momentos especiais',
    'special-moments-1': 'Circuito Spa',
    'special-moments-2': 'Spa para casais',
    'special-moments-3': 'Spa & Gastro',
    'special-moments-4': 'Circuito Spa & Massagem',
    'special-moments-5': 'Circuito Spa & Ritual',
    'special-moments-6': 'Spa privado',
    'special-moments-7': 'Spa para crianças',
    'special-moments-8': 'Spa Daypass',
    'special-moments-9': 'Spa para grávidas',

    'treatment-1': 'Tratamentos Faciais',
    'treatment-2': 'Tratamentos Corporais',
    'treatment-3': 'Rituais de Beleza',

    'about-us': 'Sobre nós',
    'about-us-1': 'Quem somos',
    'about-us-2': 'Aviso legal',
    'about-us-3': 'Política de privacidade',
    'about-us-4': 'Política de Devoluções e Trocas',
    'about-us-5': 'Termos e Condições',
    'about-us-6': 'Política de cookies',
    'about-us-7': 'Blogue',
    'about-us-8': 'Contacto',

    'redeem-voucher': 'Resgatar o seu voucher',

    'relax': 'relaxar',
    'connect': 'ligar',
    'share': 'partilhar',

    'checkout-voucher-header-title': 'Preparado para desfrutar do Bem-estar?',
    'checkout-getaway-header-title': 'Preparado para desfrutar da sua Viagem?',

    'checkout-voucher-form-title': 'detalhes completos da compra',
    'checkout-getaway-form-title': 'detalhes completos da reserva',
    'checkout-getaway-gift-title': 'É um presente?',
    'checkout-getaway-gift-form-header': 'Para quem é o Bono?',

    'search-by-location-or-spa': 'Pesquisa por Cidade ou nome do Spa',
    'search-by-destination': 'Pesquisa por destino',
    'search-by-treatment': 'Pesquisa por Tratamento',

    'benefits-givings': 'O melhor presente para o descanso e a saúde',
    'benefits-givings-small': 'O melhor presente',
    'benefits-best-price': 'Garantia de preço',
    'benefits-safe-payment': 'Pagamento seguro',
    'benefits-save-time': 'Compras com economia de tempo',
    'benefits-give-experiencies': 'Ofereça experiências',
    'benefits-valid-bonuses': 'Vouchers sempre válidos',
    'benefits-support': 'Tem alguma questão?',
    'benefits-ssl': 'Encriptação SSL segura',
    'benefits-safe-payment-ensured': 'Pagamento seguro e garantido',

    'benefits-desc-save-time': 'Sem filas ou esperas em centros comerciais',
    'benefits-desc-give-experiencies': 'Os objectos acabam no esquecimento, as experiências são para sempre.',
    'benefits-desc-valid-bonuses': 'Se expirarem, trocamo-las por um serviço de igual valor à sua escolha.',
    'benefits-desc-support': 'Escreva-nos para ayuda@spalopia.com',

    'promotion-home-desc': 'Encontre as melhores experiências de Spa em Espanha: Circuito de Spa, Spa e Massagem, Spa e Brunch, Spa e Hotel... Tudo o que precisa para relaxar!',

    'home-header-title': 'ENCONTRE OS MELHORES SPAS E RESORTS DE BEM-ESTAR',
    'home-header-subtitle': 'Encontre experiências de relaxamento e bem-estar para desfrutar com os seus entes queridos em mais de 300 spas em Portugal',

    'home-promo-title': 'MAIS DE 200 SPAS PARA ESCOLHER',
    'home-promo-subtitle': 'Desfrute de uma ida ao spa em Portugal com a Spalopia!',
    'home-promo-extra-1': 'Escolha entre Circuito de Spa, Massagens, Day Pass, Escapes e muito mais.',

    'home-promo-btn-label': 'Ver spas',

    'home-promo-side-title': 'Gostaria de desfrutar do Spa com a sua pessoa preferida?',
    'home-promo-side-subtitle': 'Descubra todos os spas que temos!',

    'home-promo-section-1-title': 'Os melhores momentos para dar e desfrutar',
    'home-promo-section-1-text': 'Na Spalopia fizemos uma seleção de momentos especiais no Spa para que possa oferecer ou desfrutar e relaxar como merece.',
    'home-promo-section-2-title': 'Os melhores tratamentos para si',
    'home-promo-section-2-text': 'Sabia que o Spa é muito mais do que circuitos termais? Seleccionámos os melhores tratamentos para que possa desfrutar, desconectar, relaxar e recuperar energias com uma massagem a 4 mãos, uma terapia de chocolate ou um envolvimento de algas.',
    'home-promo-section-3-title': 'Momentos únicos para si e para os seus',
    'home-promo-section-3-text': 'Há momentos que merecem um momento de descontração e bem-estar. Por isso, fizemos uma seleção de momentos únicos que lhe propomos para que possa dedicar um momento especial a si e aos seus.',
    'home-promo-section-4-title': 'Experiências inesquecíveis nos melhores spas',
    'home-promo-section-4-text': 'Sabia que na Spalopia tem mais de 300 spas para desfrutar em todo o Portugal e Espanha? Aqui está uma seleção das províncias e ilhas preferidas dos nossos clientes para ir ao Spa.',
    'home-promo-section-5-text': 'Quer desfrutar de uma experiência única de bem-estar e relaxamento nos balneários e spas de Portugal? Aqui está uma seleção das cidades e distritos favoritos dos nossos clientes para ir ao spa em Portugal.',

    'home-promo-valentines-title': 'FEVEREIRO É O MÊS DO AMOR!',
    'home-promo-valentines-subtitle': 'O Dia dos Namorados está a chegar e na Spalopia selecionamos os melhores pacotes para surpreender o seu parceiro',
    'home-promo-valentines-extra-1': 'Escolha entre Spa Privado, Massagem para Casais, Escapadinhas ou Circuito de Spa!',
    'home-promo-valentines-btn-label': 'Veja as opções para o Dia dos Namorados',
    'home-promo-valentines-side-title': 'Dedique ao seu parceiro o que tem de mais precioso: tempo de qualidade',
    'home-promo-valentines-side-subtitle': 'Mais de 500 opções à escolha!',

    'get-away-landing-description-1': 'Descubra as melhores escapadelas românticas em hotéis com spas {{location}}. Temos uma ampla seleção de escapadelas para desfrutar e desconectar em casal.',
    'get-away-landing-description-2': 'Faça a sua reserva online ao melhor preço e viva uma experiência de bem-estar única.',
    'get-away-landing-description-3': 'Seleccionámos para si as melhores Escapadinhas {{location}} com serviços de Spa, circuito de bem-estar, massagens e rituais de beleza incluídos. Queremos que encontre a calma e a harmonia que precisa com o seu parceiro. Descubra um mundo de novas sensações nestes completos oásis de bem-estar.',
    'get-away-landing-description-4': 'Experiências românticas para casais {{location}}. Hotéis de 4* e 5* com meia pensão, massagens relaxantes, jacuzzis de bolhas, circuitos termais com jactos que o deixarão como novo, jacuzzis de bolhas, saunas e banhos turcos para eliminar as toxinas do seu corpo e fabulosos tratamentos faciais e corporais para limpar e hidratar a sua pele ao máximo.',
    'get-away-landing-description-5': 'Escolha a melhor escapadela para dois nos melhores hotéis com Spa {{location}} e desfrute da experiência de se desligar ao máximo.',

    'footer-description': 'Na Spalopia encontra os melhores preços para desfrutar de uma experiência inesquecível num spa ou centro de bem-estar. Massagens, Rituais, Chocolaterapias, Dayspas, Spa com estadia ou Spa com Brunch são alguns dos serviços e tratamentos que pode escolher entre os mais de 300 spas e centros que trabalham connosco em toda a Espanha. Se já sabe quando quer ir ao Spa, pode reservar diretamente a data e a hora que mais lhe interessa para planear o seu bem-estar de forma simples e cómoda com a Spalopia. Temos milhares de ofertas e promoções de Spa para si e temos uma grande variedade de serviços disponíveis para oferecer ao seu ente querido a um preço único. Todos os nossos vouchers e reservas de tratamentos de Spa têm a segurança e a confiança da Spalopia, o único site em Espanha que visa melhorar o seu bem-estar no Spa.',
  },
  plural: {
    'hotel': 'hotéis',
    'ritual': 'rituais',
    'other': 'outros',
    'coupon': 'cupões',

    'description': 'descrições',

    'festivo': 'feriados',

    'couple': 'casais',
    'couple-special': 'especial casais',

    'escapada': 'escapadinhas',

    'person': 'pessoas',

    'treatment-in': 'tratamentos em {{location}}',

    'available-service': 'serviços disponíveis',
    'available-service-in-category': 'serviços disponíveis em categorias',
    'service-for': 'serviços para',

    'promotion': 'promoções',

    'recommended': 'recomendações',
  },
}

export default pt
