<template>
  <div
    class="confirmation"
    :class="{ loading: isConfirming }"
  >
    <div v-if="externalDeps">
      <TeTePixel
        :voucher-purchase="voucherPurchase"
        :should-display="isConfirmed"
      />
      <FloodLightPixel
        :should-display="isConfirmed"
        is-confirmation
      />
      <AdformPixel
        :should-display="isConfirmed"
        is-confirmation
      />
      <AwinPixel
        :should-display="isConfirmed"
        :voucher-purchase="voucherPurchase"
      />
    </div>

    <Card
      v-if="!voucherPurchase && !existsError && !isVoucherPurchaseExpired"
      mobile-with-border
      desktop-with-border
      class="confirmation__loading-data"
    >
      <h2>
        <DynLang
          by-key="downloading-purchase-data"
          capitalize-first
        />...
      </h2>
      <LoadingSpinner />
    </Card>

    <VoucherPurchaseExpired v-if="!voucherPurchase && !existsError && isVoucherPurchaseExpired" />

    <Card
      v-if="existsError"
      mobile-with-border
      desktop-with-border
      class="confirmation__error"
    >
      <h2>
        <DynLang
          by-key="were-sorry"
          capitalize-first
        />
      </h2>
      <p>
        <DynLang
          by-key="an-error-has-ocurred-contact-support"
          capitalize-first
        />:
      </p>
      <div class="confirmation__error__email">
        <a href="mailto:ayuda@spalopia.com">ayuda@spalopia.com</a>
      </div>
      <p class="confirmation__error__sad">
        <DynLang
          by-key="apologise-for-inconvenience"
          capitalize-first
        />
      </p>
      <p>
        <DynLang
          by-key="you-can-try-again-here"
          capitalize-first
        />
        <SplNuxtLink
          class="confirmation__error__back-link"
          :to="backToPaymentUrl"
          is-external
        >
          <DynLang by-key="here" />
        </SplNuxtLink>
      </p>
    </Card>

    <div
      v-else
      class="confirmation__final"
    >
      <div>
        <h1
          v-if="voucherPurchase && isConfirmed"
          class="title-desktop"
        >
          <DynLang
            by-key="congratulations-XX"
            :params="{ client: voucherPurchase.buyer.name }"
          />
        </h1>
        <Card
          v-if="voucherPurchase"
          mobile-with-border
          desktop-with-border
          class="confirm-card"
        >
          <h1
            v-if="voucherPurchase && isConfirmed"
            class="title-mobile"
          >
            <DynLang
              by-key="congratulations-XX"
              :params="{ client: voucherPurchase.buyer.name }"
            />
          </h1>
          <div
            v-if="isConfirmed"
            class="confirm-card__content"
          >
            <div class="confirm-card__text-section">
              <h3 class="confirm-card__text-section__header">
                <DynLang
                  by-key="your-voucher-is-confirmed"
                  capitalize-first
                />
              </h3>
              <div>
                <DynLang
                  by-key="you-will-receive-voucher-email"
                  capitalize-first
                />:
              </div>
              <div class="confirm-card__email">
                {{ voucherPurchase.buyer.email }}
              </div>
            </div>

            <div class="confirm-card__text-section">
              <p>
                <DynLang by-key="voucher-file-you-may-download" />
              </p>
              <SplButton
                variant="acent"
                @click="downloadVoucher()"
              >
                <DynLang by-key="voucher-file-btn" />
              </SplButton>
            </div>

            <div class="confirm-card__text-section">
              <strong>
                <DynLang
                  by-key="remember-voucher-at-spa"
                  capitalize-first
                />
              </strong>
            </div>

            <div class="confirm-card__text-section">
              <div>
                <DynLang
                  by-key="contact-support-with-any-question"
                  capitalize-first
                />:
              </div>
              <div class="confirm-card__email">
                <a href="mailto:ayuda@spalopia.com">ayuda@spalopia.com</a>
              </div>
            </div>

            <div class="confirm-card__cancel-policy">
              <VoucherPurchaseSummary
                :services="purchaseServiceCollection"
                :base-price="basePrice"
                :final-price="finalPrice"
                :spa-name="spaName"
                :spa="spa"
                :coupons="voucherPurchase.pricing.coupons"
              />
            </div>

            <VoucherPurchaseConditions :voucher-purchase="voucherPurchase" />
          </div>
          <div
            v-if="isConfirming"
            class="confirm-card__content"
          >
            <span class="confirm-card__content__waiting-msg">
              <p>
                <DynLang
                  by-key="confirming-voucher"
                  capitalize-first
                />
              </p>
              <p>
                <DynLang
                  by-key="voucher-confirm-takes-a-minute"
                  capitalize-first
                />
              </p>
              <p class="thanks">
                <DynLang
                  by-key="thanks"
                  capitalize-first
                />
              </p>
            </span>
            <LoadingSpinner />
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import SplNuxtLink from '~/core/components/shared/SplNuxtLink.vue'
import SplButton from '~/core/components/shared/form/SplButton.vue'
import Card from '~/core/components/shared/Card.vue'
import LoadingSpinner from '~/core/components/shared/LoadingSpinner.vue'
import VoucherPurchaseSummary from '~/core/components/voucher-purchase/VoucherPurchaseSummary.vue'
import VoucherPurchaseConditions from '~/core/components/voucher-purchase/VoucherPurchaseConditions.vue'
import VoucherPurchaseExpired from '~/core/components/voucher-purchase/VoucherPurchaseExpired.vue'

import TeTePixel from '~/core/components/slapped-pixels/TeTe.pixel.vue'
import FloodLightPixel from '~/core/components/slapped-pixels/Floodlight.pixel.vue'
import AdformPixel from '~/core/components/slapped-pixels/Adform.pixel.vue'
import AwinPixel from '~/core/components/slapped-pixels/Awin.pixel.vue'

import { useVoucherConfirmation } from '~/core/composable/voucher-purchase/useVoucherConfirmation'
import type { Spa } from '~/core/ts/entity/Spa'
import SpaNavigationStack from '~/core/ts/repository/SpaNavigationStackRepository'
import { Service } from '~/core/ts/entity/Service'
import ServiceNavigationStack from '~/core/ts/repository/ServiceNavigationStackRepository'

definePageMeta({
  layout: 'thanks',
})

export default defineNuxtComponent({
  components: {
    SplNuxtLink,
    SplButton,
    Card,
    LoadingSpinner,
    VoucherPurchaseSummary,
    VoucherPurchaseConditions,
    VoucherPurchaseExpired,
    // Píxeles de afiliados
    TeTePixel,
    FloodLightPixel,
    AdformPixel,
    AwinPixel,
  },
  setup() {
    const runtime = useRuntimeConfig()

    const {
      voucherUUID,
      isConfirmed,
      isConfirming,
      existsError,
      voucherPurchase,
      isVoucherPurchaseExpired,

      downloadVoucher,
    } = useVoucherConfirmation()

    const backToPaymentUrl = computed(() => {
      if (!voucherUUID) {
        return ''
      }

      return `/voucher/${voucherUUID}/checkout/payment`
    })

    const purchaseServiceCollection = computed(() => {
      if (!voucherPurchase.value) {
        return []
      }
      return voucherPurchase.value.vouchers.flatMap(({ items }) => items)
    })

    const finalPrice = computed(() => {
      if (!voucherPurchase.value) {
        return 0
      }
      return voucherPurchase.value.pricing.final.amount
    })

    const basePrice = computed(() => {
      if (!voucherPurchase.value) {
        return 0
      }
      return voucherPurchase.value.pricing.base.amount
    })

    const spaName = computed(() => {
      if (!voucherPurchase.value) {
        return ''
      }
      return voucherPurchase.value.spa.name
    })

    const spaStore = ref<Spa | null>(null)
    const spa = computed(() => {
      if (spaStore.value) {
        return spaStore.value
      }

      if (!voucherPurchase.value) {
        return null
      }

      spaStore.value = SpaNavigationStack.getByUuid(voucherPurchase.value.spaUUID)
      return spaStore.value
    })

    const serviceStore = ref<Service | null>(null)
    const service = computed(() => {
      if (serviceStore.value) {
        return serviceStore.value
      }

      if (!voucherPurchase.value) {
        return null
      }

      voucherPurchase.value.vouchers.forEach((voucher) => {
        voucher.items.forEach((item) => {
          serviceStore.value = ServiceNavigationStack.getByUuid(item.uuid)
        })
      })

      return serviceStore.value
    })

    const externalDeps = computed(() => {
      if (runtime.public.isLocal) {
        return false
      }

      return true
    })

    return {
      backToPaymentUrl,
      isConfirmed,
      isConfirming,
      existsError,
      voucherPurchase,
      isVoucherPurchaseExpired,
      purchaseServiceCollection,
      finalPrice,

      downloadVoucher,

      spa,
      spaName,
      service,

      basePrice,

      externalDeps,
    }
  },
})
</script>

<style lang="scss" scoped>
.confirmation {
  @apply mx-2;
  @apply flex;
  @apply items-center justify-center;
  flex-direction: column;

  @screen lg {
    margin-top: 12vh;

    @apply p-8;
    @apply mb-3;
    @apply mx-20;
  }

  h2 {
    @apply text-3xl;
    @apply text-green-900;
  }

  &__loading-data {
    @apply py-8 px-12;
    @apply w-2/3;

    @screen lg {
      @apply w-1/3;
    }

    h2 {
      @apply py-4;
      @apply text-xl;
      @apply font-semibold;

      @apply text-center;
    }
  }

  &__error {
    @apply text-center;
    @apply py-6;
    @apply px-8;

    @screen lg {
      @apply mx-12;
    }

    &__back-link {
      @apply ml-1;
      @apply text-green-900;
      @apply underline;
    }

    h2 {
      @apply mb-4;
    }

    &__sad {
      @apply text-spl-primary-dark;
    }

    p {
      @apply text-xl;
      @apply mt-2;
    }

    &__email {
      @apply text-xl;
      @apply font-semibold;
      @apply text-green-900;
      @apply mt-2;
      @apply mb-4;
    }
  }

  &__final {
    @screen md {
      @apply w-full;
    }

    @screen lg {
      @apply w-1/2;
    }
  }
}

.loading {
  @screen lg {
    margin-top: 21vh;
  }
}

h4 {
  @apply my-2;
  @apply font-bold;
  @apply text-spl-primary-dark;
}
.confirm-card {
  &__cancel-policy {
    @apply mt-20p;
  }

  &__text-section {
    @apply mb-6;
    @apply mx-2;

    @screen lg {
      @apply mx-4;
      @apply mt-2;
    }

    &__header {
      @apply text-3xl;
      @apply text-center;
      @apply text-spl-primary-dark;

      @apply mb-6;
    }

    p {
      @apply my-2;
    }

    &__conditions {
      @apply mt-12;
      @apply mx-2;

      @screen lg {
        @apply mx-4;
      }

      &__header {
        @apply text-xl font-bold text-spl-dark;
        @apply flex items-center justify-between;
        @apply w-full;
        @apply mb-2;
      }

      &__arrow-btn {
        @apply transition-all duration-300 ease-in-out;

        &--expanded {
          @apply transform rotate-180;
        }
      }

      &__content {
        @apply transition-all duration-500 ease-in-out;

        @apply h-0;
        @apply overflow-hidden;

        &--expanded {
          height: inherit;

          @apply mb-4;
        }
      }
    }

    &__general-conditions {
      @apply mt-4;
      @apply text-center;
    }
  }

  &__email {
    @apply text-green-900;
  }

  &__content {
    @apply p-6;

    &__waiting-msg {
      @apply text-2xl font-bold text-spl-dark;
      @apply text-center;

      p {
        @apply my-1;
        @apply text-xl;
      }

      .thanks {
        @apply pt-2;
      }
    }

    &__title {
      @apply text-spl-dark;
      @apply font-bold;
      @apply text-lg;
      @apply capitalize;
      @screen lg {
        @apply text-base;
      }
    }
  }
}

.title-mobile,
.title-desktop {
  @apply text-3xl;
  @apply font-bold;
  @apply text-center;

  @apply my-30p;

  @screen lg {
    @apply text-white;
    text-shadow: -1px -1px 0 #222, 1px -1px 0 #222, -1px 1px 0 #222, 1px 1px 0 #222;
  }
}

.title-desktop {
  @apply hidden;
  @screen lg {
    @apply block;
  }
}

.title-mobile {
  @screen lg {
    @apply hidden;
  }
}
</style>
