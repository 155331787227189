<template>
  <div class="get-away-list">
    <Breadcrumb />

    <div class="get-away-list__header">
      <h1 class="get-away-list__header__title">
        {{ title }}
      </h1>

      <GetAwayListSmallDescription :location="location" />
    </div>

    <!-- Service list -->
    <div class="get-away-list__list-wrapper">
      <!-- Filtros -->
      <div>
        <!-- Filtros de escritorio -->
        <div class="only-desktop-block">
          <Card
            desktop-with-border
            class="get-away-list__filters"
          >
            <div class="get-away-list__filters__title">
              <Lang
                by-key="filter-by"
                capitalize-first
              />
            </div>

            <div
              v-if="filterLocationsTree.length || generalParentLocation"
              class="get-away-list__filters__expanded-filter"
            >
              <p class="get-away-list__filters__expanded-filter__header">
                <Lang
                  by-key="region"
                  capitalize-first
                />
              </p>

              <LocationLinkTree
                starts-collapsed
                :countries="countries"
                :link-url-base="locationFilterBaseUrl"
                :general-parent-location="generalParentLocation"
                :locations-tree="filterLocationsTree"
                :current-location="location"
              />
            </div>

            <hr>

            <div
              v-if="treatmentCategoryNodes.length"
              class="get-away-list__filters__expanded-filter"
            >
              <p class="get-away-list__filters__expanded-filter__header">
                <Lang
                  by-key="experience"
                  capitalize-first
                />
              </p>

              <div>
                <div
                  class="get-away-list__filters__expanded-filter__checkbox-node"
                  :class="{'selected': onlyHotel}"
                >
                  <input
                    :id="`category-checkbox-only-hotel`"
                    v-model="onlyHotel"
                    type="checkbox"
                    @change="onOnlyHotelClick"
                  >
                  <label :for="`category-checkbox-only-hotel`">
                    <Lang
                      by-key="only-hotel"
                      capitalize-all
                    />
                  </label>
                </div>

                <div
                  v-for="treatmentNode in treatmentCategoryNodes"
                  :key="treatmentNode.id"
                  class="get-away-list__filters__expanded-filter__checkbox-node"
                  :class="{'selected': treatmentNode.value}"
                >
                  <input
                    :id="`category-checkbox-${treatmentNode.id}`"
                    v-model="treatmentNode.value"
                    type="checkbox"
                    @change="onTreatmentCategoryNodeClick"
                  >
                  <label :for="`category-checkbox-${treatmentNode.id}`">
                    <Lang :by-raw="treatmentNode.texts" />
                  </label>
                </div>
              </div>
            </div>

            <hr>

            <div
              v-if="hotelStarsNodes.length"
              class="get-away-list__filters__expanded-filter"
            >
              <p class="get-away-list__filters__expanded-filter__header">
                <Lang
                  by-key="hotel-kind"
                  capitalize-all-large
                />
              </p>

              <div
                v-for="element in hotelStarsNodes"
                :key="`hotel-checkbox-${element.id}`"
                class="get-away-list__filters__expanded-filter__checkbox-node"
                :class="{'selected': element.value}"
              >
                <input
                  :id="`hotel-checkbox-${element.id}`"
                  v-model="element.value"
                  type="checkbox"
                  @change="onHotelStarsNodeClick"
                >
                <label
                  :for="`hotel-checkbox-${element.id}`"
                  class="get-away-list__filters__expanded-filter__checkbox-node__stars-label"
                >
                  <span
                    v-if="Number(element.id)"
                    class="get-away-list__filters__expanded-filter__checkbox-node__stars-label__stars"
                  >
                    <span
                      v-for="n in Number(element.id)"
                      :key="`hotel-checkbox-label-${n}`"
                      class="get-away-list__filters__expanded-filter__checkbox-node__stars-label__stars__icon"
                    >
                      <StarIcon :size="16" />
                    </span>
                  </span>
                  <span v-else>
                    <Lang
                      by-key="other"
                      capitalize-first
                      plural
                    />
                  </span>
                  <span>({{ getHotelCountForStars(Number(element.id)) }})</span>
                </label>
              </div>
            </div>
          </Card>
        </div>

        <!-- Filtros de móvil -->
        <div class="only-mobile">
          <div class="get-away-list__mobile-filters">
            <StickyHeader>
              <template #only-sticky>
                <h1 class="get-away-list__mobile-filters__header__title">
                  {{ title }}
                </h1>

                <div class="get-away-list__mobile-filters__header__location-selector">
                  <SessionLocationSelector dark />
                </div>

                <div class="get-away-list__mobile-filters__header__current-search">
                  <div v-if="currentSearchDatesSmallLabel">
                    {{ currentSearchDatesSmallLabel }}.
                  </div>
                  <div v-if="currentSearchPaxLabel">
                    {{ currentSearchPaxLabel }}
                  </div>
                </div>
              </template>
              <template #body>
                <div class="get-away-list__mobile-filters__header">
                  <div class="get-away-list__mobile-filters__header__controls">
                    <button
                      class="get-away-list__mobile-filters__button"
                      @click="setIsModalDisplayed(true)"
                    >
                      <HopperIcon
                        class="get-away-list__mobile-filters__button__icon"
                        :size="18"
                      />
                      <span><Lang
                        by-key="filter"
                        capitalize-first
                      /></span>
                    </button>

                    <SelectBox
                      id="get-away-list-sorter"
                      variant="minified"
                      :data="sortOptions"
                      display-prop="label"
                      class="get-away-list__mobile-filters__sort-selector"
                      @input="pushSortOrder"
                    >
                      <template #label>
                        <Lang
                          by-key="sort-by"
                          capitalize-first
                        />:
                        <span class="get-away-list__mobile-filters__sort-selector__label">{{ selectedSortOptionLabel }}</span>
                      </template>
                    </SelectBox>
                  </div>

                  <div
                    v-if="onlyHotel || selectedTreatments.length || hotelStarsSelectedNames.length"
                    class="get-away-list__mobile-selected-filters"
                  >
                    <div class="get-away-list__mobile-selected-filters__row">
                      <Badge
                        v-if="onlyHotel"
                        color="secondary"
                        class="get-away-list__mobile-selected-filters__category"
                      >
                        <Lang
                          by-key="only-hotel"
                          capitalize-all
                        />
                      </Badge>

                      <Badge
                        v-for="treatment in selectedTreatments"
                        :key="treatment.id"
                        color="secondary"
                        class="get-away-list__mobile-selected-filters__category"
                      >
                        {{ treatment.label }}
                      </Badge>

                      <Badge
                        v-for="label in hotelStarsSelectedNames"
                        :key="`selected-hotel-badge-${label}`"
                        color="acent"
                        class="get-away-list__mobile-selected-filters__category"
                      >
                        {{ label }}
                      </Badge>
                    </div>
                  </div>
                </div>
              </template>
            </StickyHeader>

            <ConfigModalMobile
              v-model="isModalDisplayed"
              is-apply-button-displayed
            >
              <template #header>
                <div
                  class="get-away-list__mobile-filters__header"
                >
                  <Lang
                    by-key="filter-by"
                    capitalize-first
                  />
                </div>
              </template>

              <div
                v-if="filterLocationsTree.length"
                class="get-away-list__mobile-filters__expanded-filter"
              >
                <p class="get-away-list__mobile-filters__expanded-filter__header">
                  <Lang
                    by-key="region"
                    capitalize-first
                  />
                </p>

                <LocationLinkTree
                  starts-collapsed
                  :countries="countries"
                  :link-url-base="locationFilterBaseUrl"
                  :general-parent-location="generalParentLocation"
                  :locations-tree="filterLocationsTree"
                  :current-location="location"
                />
              </div>

              <hr>

              <div
                v-if="treatmentCategoryNodes.length"
                class="get-away-list__mobile-filters__expanded-filter"
              >
                <p class="get-away-list__mobile-filters__expanded-filter__header">
                  <Lang
                    by-key="experience"
                    capitalize-first
                  />
                </p>

                <div>
                  <div
                    class="get-away-list__mobile-filters__expanded-filter__checkbox-node"
                    :class="{'selected': onlyHotel}"
                  >
                    <input
                      :id="`category-checkbox-mobile-only-hotel`"
                      v-model="onlyHotel"
                      type="checkbox"
                      @change="onOnlyHotelClick"
                    >
                    <label :for="`category-checkbox-mobile-only-hotel`">
                      <Lang
                        by-key="only-hotel"
                        capitalize-all
                      />
                    </label>
                  </div>

                  <div
                    v-for="treatmentNode in treatmentCategoryNodes"
                    :key="treatmentNode.id"
                    class="get-away-list__mobile-filters__expanded-filter__checkbox-node"
                    :class="{'selected': treatmentNode.value}"
                  >
                    <input
                      :id="`category-checkbox-mobile-${treatmentNode.id}`"
                      v-model="treatmentNode.value"
                      type="checkbox"
                      @change="onTreatmentCategoryNodeClick"
                    >
                    <label :for="`category-checkbox-mobile-${treatmentNode.id}`">
                      <Lang :by-raw="treatmentNode.texts" />
                    </label>
                  </div>
                </div>
              </div>

              <hr>

              <div
                v-if="hotelStarsNodes.length"
                class="get-away-list__mobile-filters__expanded-filter"
              >
                <p class="get-away-list__mobile-filters__expanded-filter__header">
                  <Lang
                    by-key="hotel-kind"
                    capitalize-all-large
                  />
                </p>

                <div
                  v-for="element in hotelStarsNodes"
                  :key="`hotel-checkbox-${element.id}`"
                  class="get-away-list__mobile-filters__expanded-filter__checkbox-node"
                  :class="{'selected': element.value}"
                >
                  <input
                    :id="`hotel-checkbox-${element.id}`"
                    v-model="element.value"
                    type="checkbox"
                    @change="onHotelStarsNodeClick"
                  >
                  <label
                    :for="`hotel-checkbox-${element.id}`"
                    class="get-away-list__mobile-filters__expanded-filter__checkbox-node__stars-label"
                  >
                    <span
                      v-if="Number(element.id)"
                      class="get-away-list__mobile-filters__expanded-filter__checkbox-node__stars-label__stars"
                    >
                      <span
                        v-for="n in Number(element.id)"
                        :key="`hotel-checkbox-label-${n}`"
                        class="get-away-list__mobile-filters__expanded-filter__checkbox-node__stars-label__stars__icon"
                      >
                        <StarIcon :size="16" />
                      </span>
                    </span>
                    <span v-else>Otros</span>
                    <span>({{ getHotelCountForStars(Number(element.id)) }})</span>
                  </label>
                </div>
              </div>
            </ConfigModalMobile>
          </div>
        </div>
      </div>

      <div class="get-away-list__list">
        <div class="get-away-list__list__header">
          <div>
            <h2 class="get-away-list__list__header__subtitle">
              {{ subtitle }}
            </h2>
          </div>

          <div>
            <SelectBox
              id="get-away-list-sorter"
              variant="minified"
              :data="sortOptions"
              display-prop="label"
              class="get-away-list__list__header__sort-selector"
              @input="pushSortOrder"
            >
              <template #label>
                <Lang
                  by-key="sort-by"
                  capitalize-first
                />: <span class="get-away-list__list__header__sort-selector__label">{{
                  selectedSortOptionLabel }}</span>
              </template>
            </SelectBox>
          </div>
        </div>

        <div
          v-if="noAvailabilityFound && getAwaysByHotelView.length"
          class="get-away-list__list__no-availability"
        >
          <p class="get-away-list__list__no-availability__header">
            <Lang
              by-key="no-available-get-away-availability"
              capitalize-first
            />
          </p>
          <p
            v-if="selectedDateFrom && selectedDateTo"
            class="get-away-list__list__no-availability__dates"
          >
            {{ selectedDateFromStr }} - {{ selectedDateToStr }}
          </p>
          <p>
            <Lang
              by-key="no-available-get-away-availability-secondary"
              capitalize-first
            />
          </p>
        </div>

        <TruncatedList
          v-if="getAwaysByHotelView.length"
          :split-at="8"
          :data="getAwaysByHotelView"
        >
          <template #default="{ element }">
            <div>
              <GetAwayListItemByHotel
                :key="element.hotel.uuid"
                :get-away-group-by-hotel="element"
                :availability-from="selectedDateFrom"
                :availability-to="selectedDateTo"
                show-availability-dates
                :has-valid-search="hasValidSearch"
                :treatments="availableTreatments"
                :selected-treatment-ids="selectedTreatmentsIDsWithChildren"
              />
            </div>
          </template>
        </TruncatedList>

        <div
          v-else
          class="get-away-list__list__no-get-aways"
        >
          <p class="get-away-list__list__no-get-aways__header">
            <Lang
              by-key="no-available-get-away"
              capitalize-first
            />
          </p>
          <p>
            <Lang
              by-key="no-available-get-away-secondary"
              capitalize-first
            />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Breadcrumb from '~/core/components/shared/Breadcrumb.vue'
import TruncatedList from '~/core/components/shared/TruncatedList.vue'
import GetAwayListSmallDescription from '~/core/components/get-away/GetAwayListSmallDescription.vue'
import GetAwayListItemByHotel from '~/core/components/get-away/GetAwayListItemByHotel.vue'
import SelectBox from '~/core/components/shared/form/SelectBox.vue'
import Card from '~/core/components/shared/Card.vue'
import Badge from '~/core/components/shared/Badge.vue'
import StickyHeader from '~/core/components/shared/StickyHeader.vue'
import LocationLinkTree from '~/core/components/location/LocationLinkTree.vue'
import ConfigModalMobile from '~/core/components/shared/form/ConfigModalMobile.vue'
import SessionLocationSelector from '~/core/components/menu/session-location/SessionLocationSelector.vue'
import HopperIcon from '~/core/components/shared/icons/Hopper.icon.vue'
import StarIcon from '~/core/components/shared/icons/Star.icon.vue'

import { getGetAwayListMetadata, getListTitleAndDescription } from '~/core/composable/get-away/useGetAwayPageHeader'
import { useFetchProxy } from '~/core/composable/shared/useFetchProxy'
import { usePaxSelector } from '~/core/composable/spa-service/usePaxSelector'
import { useDateRange } from '~/core/composable/shared/useDateRange'
import { useGetAwayAvailability } from '~/core/composable/get-away/useGetAwayAvailability'
import { useMultiHotelStarsFilter } from '~/core/composable/get-away/useMultiHotelStarsFilter'
import { useMultiTreatmentFilter } from '~/core/composable/spa/useMultiTreatmentFilter'
import { useOnlyHotel } from '~/core/composable/get-away/useOnlyHotel'
import { useSorter } from '~/core/composable/spa/useSorter'
import { useSessionLocation } from '~/core/composable/shared/useSessionLocation'
import { useGetAwayAvailabilitySearchStorage } from '~/core/composable/get-away/useGetAwayAvailabilitySearchStorage'

import {
  capitalizeAllLargeWords,
  capitalizeAllWords,
  capitalizeFirstWord,
  includesInsensitive,
  slashUri
} from '~/core/ts/util/string'

import { GetAway } from '~/core/ts/entity/GetAway'
import { GetAwayWithAvailability } from '~/core/ts/entity/GetAwayWithAvailability'
import { GetAwayGroupByHotel, GetAwayGroupByHotelCollection } from '~/core/ts/entity/GetAwayGroupByHotel'
import type { GetAwayLandingByLocation } from '~/core/ts/entity/GetAwayLandingByLocation'
import LoadingEventBus from '~/core/events/loading'
import { checkIfIsDate, datetoDDmm } from '~/core/ts/util/date'
import { CheckBoxNode } from '~/core/ts/entity/CheckBoxNode'
import type { Location, LocationTree } from '~/core/ts/entity/Location'
import type { Treatment } from '~/core/ts/entity/Treatment'
import { translate, translateByKey } from '~/core/ts/util/translate'
import { Service } from '~/core/ts/entity/Service'
import type { Tag } from '~/core/ts/entity/Tag'

export default defineNuxtComponent({
  components: {
    Breadcrumb,
    TruncatedList,
    GetAwayListSmallDescription,
    GetAwayListItemByHotel,
    SelectBox,
    Card,
    Badge,
    StickyHeader,
    LocationLinkTree,
    ConfigModalMobile,
    SessionLocationSelector,
    HopperIcon,
    StarIcon,
  },
  async setup() {
    const route = useRoute()
    const path = route.path

    const runtime = useRuntimeConfig()

    const isModalDisplayed = ref(false)
    function setIsModalDisplayed(value: boolean) {
      isModalDisplayed.value = value
    }

    const sessionLocation = useSessionLocation()

    // Filtros

    const {
      onlyHotel,
      onOnlyHotelClick,
    } = useOnlyHotel()

    const recommendedText = capitalizeAllLargeWords(translateByKey('recommended', true))
    const cheapestHotelText = capitalizeAllLargeWords(translateByKey('cheapest-hotel'))
    const mostExpensiveHotelText = capitalizeAllLargeWords(translateByKey('most-expensive-hotel'))

    const {
      sortOrder,
      pushSortOrder,
      sortOptions,
      selectedSortOptionLabel
    } = useSorter([
      { label: cheapestHotelText, value: 'cheapest' },
      { label: mostExpensiveHotelText, value: 'more_expensive' },
      { label: recommendedText, value: 'recommended' },
    ], 'cheapest')

    const hotelStarsNodes = ref<CheckBoxNode[]>([])
    const {
      hotelStarsSelected,
      hotelStarsSelectedNames,
      onHotelStarsNodeClick,
    } = useMultiHotelStarsFilter(hotelStarsNodes)

    const treatmentCategoryNodes = ref<CheckBoxNode[]>([])
    const {
      selectedTreatments,
      selectedTreatmentsIDs,
      onTreatmentCategoryNodeClick,
    } = useMultiTreatmentFilter(treatmentCategoryNodes)

    function includeTreatmentsChildren(uuids: Array<string>): Array<string> {
      const final: Record<string, string> = {}

      uuids.forEach((uuid) => {
        for (const treat of availableTreatments) {
          final[uuid] = uuid
          if (treat.uuidParents.includes(uuid)) {
            final[treat.uuid] = (treat.uuid)
          }
        }
      })

      return Object.values(final)
    }

    const selectedTreatmentsIDsWithChildren = computed(() => includeTreatmentsChildren(selectedTreatmentsIDs.value))

    // { star_count => hotel_count }
    const hotelCountByStars = ref<Record<number, number>>({})

    // Fin Filtros

    const {
      hasSelectedPax,
      selectedPaxValue,
      pushPaxSelection,
    } = usePaxSelector(ref([]), '0')

    const {
      hasValidDateRange,
      selectedDateFrom,
      selectedDateTo,
    } = useDateRange()

    useGetAwayAvailabilitySearchStorage()

    const selectedDateFromStr = computed(() => {
      if (!selectedDateFrom.value) {
        return ''
      }

      return datetoDDmm(selectedDateFrom.value)
    })

    const selectedDateToStr = computed(() => {
      if (!selectedDateTo.value) {
        return ''
      }

      return datetoDDmm(selectedDateTo.value)
    })

    const {
      getAvailabilityByLocationUUID,
      getAvailabilityOf,
    } = useGetAwayAvailability()

    const searchPerformed = ref(false)
    const hasValidSearch = computed(() => {
      if (!searchPerformed.value) {
        return false
      }

      return hasValidDateRange.value && hasSelectedPax.value
    })

    onMounted(() => {
      // Normalizamos el número de personas
      if (!hasSelectedPax.value) {
        pushPaxSelection({ label: 'pax', value: '2' })
      } else {
        const pax = GetAway.normalizePaxNumber(Number(selectedPaxValue.value))
        pushPaxSelection({ label: 'pax', value: pax.toString() })
      }

      // Obtener dispo al cargar la página
      setTimeout(async () => {
        if (location && hasValidDateRange.value && hasSelectedPax.value) {
          LoadingEventBus.show()
          try {
            checkIfIsDate(selectedDateFrom.value)
            checkIfIsDate(selectedDateTo.value)

            await getAvailabilityByLocationUUID(
              location.uuid,
              selectedDateFrom.value,
              selectedDateTo.value,
              Number(selectedPaxValue.value as string)
            )

            searchPerformed.value = true
            setTimeout(() => {
              const collection = new GetAwayGroupByHotelCollection(getAwaysByHotel.value)
              collection.filterByHasAvailabilityIfThereIsAny()
              hotelCountByStars.value = getHotelCountByStarsDic(collection.get())
            }, 200)
          } catch (e) {
            LoadingEventBus.hide()
            throw e
          }

          LoadingEventBus.hide()
        }
      }, 700)
    })

    // Datos
    const countries = await useFetchProxy<Array<Location>>(
      '/api/locations/locations-by-type',
      { method: 'post', body: { type: 'country' } },
    )

    const landing = await useFetchProxy<GetAwayLandingByLocation>(
      '/api/getaway/get-getaway-by-location-landing-by-uri',
      {
        method: 'post',
        body: { uri: path }
      },
    )

    const location = landing.location

    const getAways = await useFetchProxy<Array<GetAway>>(
      '/api/getaway/get-getaway-by-uuid-list',
      {
        method: 'post',
        body: { uuids: landing.payload }
      },
    )

    const runtimeConfig = useRuntimeConfig()

    const locationFilterBaseUrl = slashUri(runtimeConfig.public.urls.getAwayBaseUrl)
    const filterLocationsTree = await (async () => {
      if (!location) {
        return await useFetchProxy<LocationTree[]>(
          '/api/getaway/location-tree-with-no-parent',
          {
            method: 'post',
            body: { lang: runtimeConfig.public.language },
          }
        )
      } else {
        return await useFetchProxy<LocationTree[]>(
          '/api/getaway/location-tree-siblings-by-parent-uuid',
          {
            method: 'post',
            body: { uuid: location.parentUUID }
          }
        )
      }
    })()
    let generalParentLocation: Location | null = null
    if (location && location.parentUUID) {
      generalParentLocation = await useFetchProxy<Location>(
        '/api/locations/location-by-uuid',
        {
          method: 'post',
          body: { uuid: location.parentUUID }
        }
      )
    }

    async function getAvailableTreatments() {
      let tagUUIDs: Array<string> = []
      getAways.forEach((getAway) => {
        const uuids = GetAway.getTagUUIDs(getAway)
        tagUUIDs = tagUUIDs.concat(uuids)
      })

      const treatments = await useFetchProxy<Array<Treatment>>(
        '/api/treatments/treatment-list-by-uuid-list',
        {
          method: 'post',
          body: {
            uuids: tagUUIDs,
            langcode: runtime.public.language,
            includeParents: true,
          }
        }
      )

      return treatments
    }

    const availableTreatments = await getAvailableTreatments()

    // Fin Datos
    const availableTreatmentParents = availableTreatments.filter((treat) => !treat.uuidParents.length)

    treatmentCategoryNodes.value = availableTreatmentParents
      .sort((treatmentA, treatmentB) => treatmentA.title.localeCompare(treatmentB.title))
      .map((treatment) => {
        const label = (() => {
          if (includesInsensitive(treatment.title, 'hidroterapia')) {
            return 'Circuito Spa'
          }

          return treatment.title
        })()

        const texts: CheckBoxNode['texts'] = {}
        Object.entries(treatment.texts).forEach(([key, value]) => texts[key] = value.title)

        return CheckBoxNode.copy({
          id: treatment.uuid,
          label: capitalizeAllWords(label),
          value: false,
          metadata: { treatment }
        })
      })

    const getAwaysByHotel = computed(() => {
      const dic: Record<string, GetAwayGroupByHotel> = {}

      for (const getAway of getAways) {
        let posibleGroup = dic[getAway.hotel.uuid]
        if (!posibleGroup) {
          posibleGroup = new GetAwayGroupByHotel(getAway.hotel, getAway.spa, [])
        }

        const obj = GetAwayWithAvailability.from(getAway, getAvailabilityOf(getAway.uuid))
        posibleGroup.getAways.push(obj)

        dic[getAway.hotel.uuid] = posibleGroup
      }

      return Object.values(dic)
    })

    const getAwaysByHotelView = computed(() => {
      const collection = new GetAwayGroupByHotelCollection(getAwaysByHotel.value)


      const country = sessionLocation.storedLocationCountry.value
      if (!location && country) {
        collection.filterByCountry(country)
      }

      if (hotelStarsSelected.value.length) {
        collection.filterByHotelStarCount(hotelStarsSelected.value)
      }

      if (selectedTreatments.value.length) {
        const uuids = includeTreatmentsChildren(
          selectedTreatments.value.map((treatment) => treatment.id)
        )

        if (hasValidSearch.value) {
          collection.filterByIncludesTagUUIDsAndAvailability(uuids)
        } else {
          collection.filterByIncludesTagUUIDs(uuids)
        }
      }

      if (hasValidSearch.value) {
        collection.filterByHasAvailabilityIfThereIsAny()
      }

      if (onlyHotel.value) {
        collection.filterByOnlyHotel()
      }

      switch (sortOrder.value.value) {
        case 'cheapest':
          collection.sortByCheaper()
          break
        case 'more_expensive':
          collection.sortByMostExpensive()
          break
        case 'recommended':
          // collection.sortByRecommended()
          break
      }

      collection.spliceByIncludesSpa()
      return collection.get()
    })

    const noAvailabilityFound = computed(() => {
      if (!hasValidSearch.value) {
        return false
      }

      for (const gabh of getAwaysByHotelView.value) {
        if (GetAwayGroupByHotel.hasAnyValidAvailability(gabh)) {
          return false
        }
      }

      return true
    })


    function getHotelCountForStars(stars: number) {
      const hotelNumByStars = hotelCountByStars.value

      return hotelNumByStars[stars] || 0
    }

    function getHotelCountByStarsDic(list: Array<GetAwayGroupByHotel>) {
      const stars: Record<string, number> = {}
        list.forEach((ga) => {
          const value = Math.floor(ga.hotel.category)
          if (!stars[value]) { stars[value] = 0 }
          stars[value]++
        })
      return stars
    }

    hotelCountByStars.value = getHotelCountByStarsDic(getAwaysByHotelView.value)
    hotelStarsNodes.value = Object.entries(hotelCountByStars.value)
      .sort(([akey], [bkey]) => {
        const a = Number(akey)
        const b = Number(bkey)
        if (!a) { return 1 }
        if (!b) { return -1 }
        return a > b ? 1 : 0
      })
      .map(([key, value]) => {
        return CheckBoxNode.copy({
          id: key,
          label: value.toString(),
          value: false,
          metadata: { }
        })
      })

    useHead(getGetAwayListMetadata(route.path, location))
    const { title } = getListTitleAndDescription(location)

    // Textos
    const subtitle = computed(() => {
      const labels = location ? {
        es: 'Encontrados {{x}} hoteles en {{loc}}',
        pt: 'Encontrado {{x}} hotéis em {{loc}}',
      }: {
        es: 'Encontrados {{x}} hoteles',
        pt: 'Encontrado {{x}} hotéis',
      }

      return translate(labels)
        .replaceAll('{{x}}', getAwaysByHotelView.value.length.toString())
        .replaceAll('{{loc}}', location ? location.label : '')
    })

    const currentSearchDatesSmallLabel = computed(() => {
      if (!hasValidSearch.value) {
        return ''
      }

      const from = selectedDateFrom.value as Date
      const to = selectedDateTo.value as Date

      const fromStr = datetoDDmm(from)
      const toStr = datetoDDmm(to)

      return `${fromStr} - ${toStr}`
    })

    const personTxt = capitalizeFirstWord(translateByKey('person'))

    const currentSearchPaxLabel = computed(() => {
      if (!hasValidSearch.value) {
        return ''
      }

      const pax = selectedPaxValue.value

      return `${pax} ${personTxt}s`
    })

    return {
      title,
      subtitle,
      currentSearchDatesSmallLabel,
      currentSearchPaxLabel,

      getAwaysByHotelView,
      availableTreatmentParents,
      availableTreatments,

      // Filtros
      isModalDisplayed,
      setIsModalDisplayed,

      countries,
      locationFilterBaseUrl,
      filterLocationsTree,
      generalParentLocation,

      treatmentCategoryNodes,
      selectedTreatments,
      selectedTreatmentsIDsWithChildren,
      onTreatmentCategoryNodeClick,

      sortOptions,
      selectedSortOptionLabel,
      pushSortOrder,
      hotelStarsNodes,
      hotelStarsSelectedNames,
      onHotelStarsNodeClick,
      getHotelCountForStars,
      onlyHotel,
      onOnlyHotelClick,

      // Disponibilidad
      noAvailabilityFound,
      getAvailabilityOf,
      selectedDateFrom,
      selectedDateTo,
      selectedDateFromStr,
      selectedDateToStr,

      hasValidSearch,

      location,
    }
  }

})
</script>

<style lang="scss" scoped>
.get-away-list {
  @apply py-2;

  &__header {
    @apply pt-6;
    @apply pb-6;

    &__title {
      @apply font-bold;
      @apply text-3xl;

      @apply py-4;

      @apply px-2;

      @screen lg {
        @apply px-0;
      }

      @apply text-spl-dark;
    }
  }

  &__list-wrapper {
    @apply mt-2;
    @apply py-4;

    @screen lg {
      @apply gap-3;
      @apply grid;
      grid-template-columns: 25% 75%;
    }
  }

  &__filters {
    @apply py-4;
    @apply px-4;

    &__title {
      @apply text-lg;
      @apply font-bold;
      @apply text-spl-dark;

      @apply w-full;
      @apply py-1;
      @apply my-1;
      @apply border-b-1;
      @apply border-spl-secondary;
    }

    &__expanded-filter {
      @apply py-2;

      &__header {
        @apply font-bold;
        @apply py-2;

        @apply text-spl-dark;
      }

      &__checkbox-node {
        @apply py-1;
        @apply flex;
        @apply justify-start;
        @apply items-center;

        input,
        label {
          @apply cursor-pointer;
        }

        label {
          @apply px-2;
          @apply text-spl-dark;
        }

        &__stars-label {
          @apply flex;
          @apply gap-1;

          &__stars {
            @apply flex;

            &__icon {
              @apply flex;
              @apply items-center;
            }
          }
        }
      }

      &__checkbox-node:hover {
        label {
          @apply font-bold;
        }
      }

      &__checkbox-node.selected {
        label {
          @apply font-bold;
        }
      }
    }
  }

  &__mobile-selected-filters {
    @apply px-4 pt-1 pb-4;

    &__row {
      @apply py-1;
    }

    &__category {
      @apply m-1;
    }
  }

  &__mobile-filters {
    &__header {
      &__title {
        @apply text-xl;
        @apply text-spl-dark;
        @apply py-4;
        @apply px-2;
        @apply font-semibold;
      }

      &__current-search {
        @apply flex;
        @apply justify-center;
        @apply gap-4;
      }

      &__controls {
        @apply py-2;
        @apply flex justify-end;
      }
    }

    &__button {
      @apply flex items-center;
      @apply text-sm;

      @apply px-4;
      @apply py-2;
      @apply border-r-1;

      &__icon {
        @apply mr-1;
      }
    }

    &__expanded-filter {
      &__header {
        @apply font-bold;
        @apply py-2;

        @apply text-spl-dark;
      }

      &__checkbox-node {
        @apply py-1;
        @apply flex;
        @apply justify-start;
        @apply items-center;

        input,
        label {
          @apply cursor-pointer;
        }

        label {
          @apply px-2;
          @apply text-spl-dark;
        }

        &__stars-label {
          @apply flex;
          @apply gap-1;

          &__stars {
            @apply flex;

            &__icon {
              @apply flex;
              @apply items-center;
            }
          }
        }
      }

      &__checkbox-node:hover {
        label {
          @apply font-bold;
        }
      }

      &__checkbox-node.selected {
        label {
          @apply font-bold;
        }
      }

      @apply pb-2 mb-2;
    }

    &__sort-selector {
      @apply text-sm;
      @apply px-4;
      @apply py-2;

      &__label {
        @apply font-bold;
        @apply italic;
      }
    }
  }

  &__list {
    &__header {
      @apply hidden;

      @screen lg {
        @apply flex;
        @apply justify-between;
        @apply items-center;
      }

      @apply px-2 pb-1;

      &__subtitle {
        @apply text-spl-dark;
        @apply text-sm;
      }

      &__sort-selector {
        @apply my-2;

        &__label {
          @apply font-bold;
          @apply italic;
        }
      }
    }

    &__no-availability {
      @apply text-center;
      @apply py-6;

      @apply mx-2;
      @apply my-8;

      @screen lg {
        @apply my-4;
      }

      @apply border;
      @apply border-spl-gray-2;
      @apply rounded-4xl;

      &__header {
        @apply font-bold;
        @apply text-spl-acent-dark;
      }

      &__dates {
        @apply font-bold;
      }
    }

    &__no-get-aways {
      @apply text-center;
      @apply py-6;

      @apply mx-2;
      @apply my-8;

      @screen lg {
        @apply my-2;
      }

      @apply border;
      @apply border-spl-gray-2;
      @apply rounded-4xl;

      &__header {
        @apply font-bold;
        @apply text-spl-acent-dark;
      }
    }
  }
}
</style>

