<template>
  <div
    class="get-away-details"
    itemscope
    itemtype="https://schema.org/Product"
  >
    <img
      class="util-snippet"
      itemprop="image"
      alt="Product Image"
      :src="firstImage"
    >

    <Breadcrumb />

    <div class="get-away-details__header">
      <div
        v-if="score"
        property="aggregateRating"
        itemprop="aggregateRating"
        itemscope
        itemtype="https://schema.org/AggregateRating"
        class="promotion__header__score"
      >
        <span
          class="util-snippet"
          itemprop="ratingValue"
        >{{ score }}</span>
        <span
          class="util-snippet"
          itemprop="bestRating"
        >{{ maxScore }}</span>
        <span
          class="util-snippet"
          itemprop="reviewCount"
        >{{ reviewCount }}</span>
      </div>
      <h1
        itemprop="name"
        class="get-away-details__header__title"
      >
        {{ title }}
      </h1>

      <div class="get-away-details__header__location">
        <HotelNameStarsAtom :hotel="getAway.hotel" />
        <LocationLink :location="location" />
      </div>
    </div>

    <GalleryMolecule
      :images="getAway.images"
      orientation="portrait"
      class="get-away-details__gallery"
    >
      <Badge
        v-if="getAway.includesSpa"
        color="acent"
        class="get-away-details-page__header__spa-status"
      >
        <Lang
          by-key="hotel-and-spa"
          capitalize-all
        />
      </Badge>

      <Badge
        v-else
        color="acent"
        class="get-away-details-page__header__spa-status"
      >
        <Lang
          by-key="only-hotel"
          capitalize-all
        />
      </Badge>
    </GalleryMolecule>

    <div class="get-away-details__main-info">
      <!-- Izquierda -->
      <div>
        <div class="get-away-details__amenities">
          <p
            v-if="amenities.length || miniDataImages.length"
            class="get-away-details__amenities__title"
          >
            <Lang
              by-key="your-getaway-includes"
              capitalize-first
            />:
          </p>

          <div class="get-away-details__amenities__tags">
            <CheckListItem
              v-for="tag in amenities"
              :key="tag.uuid"
              variant="acent"
            >
              {{ tag.title }}
            </CheckListItem>
          </div>
        </div>

        <!-- Imágenes de habitación, instalaciones y demás -->
        <!-- TODO: Abrir galería al hacer click -->
        <div class="get-away-details__mini-data-images">
          <div
            v-for="(minidata, i) in miniDataImages"
            :key="minidata.image.uuid + i"
          >
            <img
              class="get-away-details__mini-data-images__img"
              :src="minidata.image.url"
            >
            <p class="get-away-details__mini-data-images__title">
              {{ minidata.name }}
            </p>
          </div>
        </div>

        <Separator />

        <div
          property="description"
          itemprop="description"
          class="get-away-details__description"
        >
          <SectionAtom
            :start-collapsed="true"
            :is-collapsable="true"
            :html="description"
            :separator="false"
          >
            <template #title>
              <p class="get-away-details__description__title">
                <Lang
                  by-key="description"
                  capitalize-first
                />
              </p>
            </template>
          </SectionAtom>
        </div>
      </div>

      <!-- Derecha -->
      <div>
        <Card
          desktop-with-border
          class="get-away-details__availability"
        >
          <div
            v-if="!hasValidAvailability"
            class="get-away-details__no-availability-primary"
          >
            <Lang
              by-key="no-availability-for-given-dates-and-pax"
              capitalize-first
            />
          </div>

          <div class="get-away-details__availability__fields">
            <DateRangePicker variant="minimized" />

            <SelectBox
              id="pax-selector"
              :data="paxOptions"
              display-prop="label"
              variant="border"
              class="get-away-details__filters__filter-selector"
              @input="pushPaxSelection"
            >
              <template #label>
                <span class="get-away-details__mobile-filters__sort-selector__label">
                  {{ selectedPaxOptionLabel }}
                </span>
              </template>
            </SelectBox>
          </div>

          <div
            v-if="hasValidAvailability"
            class="get-away-details__availability__fields"
          >
            <div>
              <SelectBox
                id="room-selector"
                label="Habitación"
                :data="getAwayAvailability.roomsAvailable"
                display-prop="name"
                variant="border"
                @input="onRoomSelected"
              >
                <template #label>
                  <span v-if="selectedRoom">
                    {{ selectedRoom.name }}
                  </span>
                </template>
              </SelectBox>
            </div>

            <div>
              <SelectBox
                id="room-selector"
                label="Pensión"
                :data="boardtypesAvailable"
                display-prop="name"
                variant="border"
                @input="onBoardtypeSelected"
              >
                <template #label>
                  <span v-if="selectedBoardtype">
                    {{ selectedBoardtype.name }}
                  </span>
                </template>
              </SelectBox>
            </div>

            <div v-if="availableRates.length">
              <SelectBox
                id="room-selector"
                label="Tarifas"
                :data="availableRates"
                display-prop="name"
                variant="border"
                item-label-as-slot
                @input="onRateSelected"
              >
                <template #label>
                  <GetAwayPrice
                    v-if="selectedRate"
                    :rate="selectedRate"
                    show-rate-name
                  />
                </template>
                <template #item="{ element }">
                  <GetAwayPrice
                    v-if="element"
                    :rate="element"
                    show-rate-name
                  />
                </template>
              </SelectBox>
            </div>

            <div
              v-else
              class="get-away-details__no-availability-secondary"
            >
              <Lang
                by-key="no-rates-for-given-dates-and-pax"
                capitalize-first
              />
            </div>

            <div class="get-away-details__booking-link">
              <SplNuxtLink
                v-if="hasValidPrePurchaseLink"
                to="/purchase/start"
                :query="prePurchaseQuery"
              >
                <SplButton
                  variant="acent"
                  size="lg-w-full"
                >
                  <Lang
                    by-key="book"
                    capitalize-first
                  />
                </SplButton>
              </SplNuxtLink>
            </div>
          </div>

          <div
            v-else
            class="get-away-details__no-availability-secondary"
          >
            <Lang
              by-key="no-available-get-away-availability-secondary"
              capitalize-first
            />
          </div>

          <Separator />

          <GetAwayPurchaseSummary
            v-if="hasValidAvailability"
            :availability="getAwayAvailability"
            :rate="selectedRate"
            :get-away="getAway"
            show-text-summary
          />
        </Card>
      </div>
    </div>

    <Separator />

    <BenefitsCard
      :tags="['GIVINGS', 'BEST_PRICE', 'SAFE_PAYMENT']"
      :can-close="false"
    />

    <div class="get-away-details__spa">
      <div class="get-away-details__spa__description">
        <SectionAtom
          :start-collapsed="true"
          :is-collapsable="true"
          :html="spaDescription"
          :separator="false"
        >
          <template #title>
            <p class="get-away-details__spa__description__title">
              <Lang
                by-key="the-spa"
                capitalize-all
              />
            </p>
          </template>
        </SectionAtom>
      </div>
      <div class="get-away-details__spa__facilities">
        <p class="get-away-details__spa__facilities__title">
          <Lang
            by-key="you-will-find-in-XX"
            :params="{ spaName }"
          />
        </p>

        <div class="get-away-details__spa__facilities__list">
          <CheckListItem
            v-for="tag in facilities"
            :key="tag.uuid"
            variant="secondary"
          >
            {{ tag.title }}
          </CheckListItem>
        </div>
      </div>
    </div>

    <Separator v-if="hotelDescription" />

    <div
      v-if="hotelDescription"
      class="get-away-details__hotel"
    >
      <div class="get-away-details__hotel__description">
        <SectionAtom
          :start-collapsed="true"
          :is-collapsable="true"
          :html="hotelDescription"
          :separator="false"
        >
          <template #title>
            <p class="get-away-details__hotel__description__title">
              <Lang
                by-key="the-hotel"
                capitalize-all
              />
            </p>
          </template>
        </SectionAtom>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Breadcrumb from '~/core/components/shared/Breadcrumb.vue'
import SectionAtom from '~/core/components/shared/Section.atom.vue'
import Badge from '~/core/components/shared/Badge.vue'
import Card from '~/core/components/shared/Card.vue'
import Separator from '~/core/components/shared/Separator.vue'
import GalleryMolecule from '~/core/components/shared/gallery/Gallery.molecule.vue'
import CheckListItem from '~/core/components/shared/CheckListItem.vue'
import SelectBox from '~/core/components/shared/form/SelectBox.vue'
import DateRangePicker from '~/core/components/shared/form/DateRangePicker.vue'

import HotelNameStarsAtom from '~/core/components/hotel/atom/HotelNameStars.atom.vue'
import LocationLink from '~/core/components/shared/LocationLink.vue'
import SplButton from '~/core/components/shared/form/SplButton.vue'
import SplNuxtLink from '~/core/components/shared/SplNuxtLink.vue'
import BenefitsCard from '~/core/components/spa/BenefitsCard.vue'

import GetAwayPurchaseSummary from '~/core/components/get-away/GetAwayPurchaseSummary.vue'
import GetAwayPrice from '~/core/components/get-away/GetAwayPrice.vue'

import { GetAwayPrePurchase } from '~/core/ts/entity/GetAwayPrePurchase'
import { getGetAwayDetailMetadata } from '~/core/composable/get-away/useGetAwayPageHeader'
import { useFetchProxy } from '~/core/composable/shared/useFetchProxy'
import { usePaxSelector } from '~/core/composable/spa-service/usePaxSelector'
import { useGetAwayAvailability } from '~/core/composable/get-away/useGetAwayAvailability'
import { useGetAwayAvailabilitySearchStorage } from '~/core/composable/get-away/useGetAwayAvailabilitySearchStorage'

import { GetAway } from '~/core/ts/entity/GetAway'
import type { Option } from '~/core/composable/shared/useSelectorToQueryParam'
import { useDateRange } from '~/core/composable/shared/useDateRange'
import LoadingEventBus from '~/core/events/loading'
import {
  GetAwayRate,
  type GetAwayAvailabilityBoardtype,
  type GetAwayAvailabilityRoom
} from '~/core/ts/entity/GetAwayAvailability'
import { checkIfIsDate } from '~/core/ts/util/date'
import { translate, translateByKey, translateSilent } from '~/core/ts/util/translate'

export default defineNuxtComponent({
  components: {
    Breadcrumb,
    SectionAtom,
    Badge,
    Card,
    Separator,
    GalleryMolecule,
    CheckListItem,
    SelectBox,
    DateRangePicker,
    HotelNameStarsAtom,
    LocationLink,
    SplButton,
    SplNuxtLink,
    BenefitsCard,
    GetAwayPurchaseSummary,
    GetAwayPrice,
  },
  async setup() {
    const route = useRoute()
    const path = route.path

    const runtimeConfig = useRuntimeConfig()

    const paxSelectorOptions = ref<Option[]>([])

    const {
      hasSelectedPax,

      selectedPaxValue,
      pushPaxSelection,
      paxOptions,
      selectedPaxOptionLabel,

      onPaxChanged,
    } = usePaxSelector(paxSelectorOptions, '0')

    const {
      hasValidDateRange,

      selectedDateFrom,
      selectedDateTo,

      onDateRangeChanged,
      pushDateRangeInput,
    } = useDateRange()

    useGetAwayAvailabilitySearchStorage()

    const personLabel = translateByKey('person', false)
    const peopleLabel = translateByKey('person', true)

    paxSelectorOptions.value = Array
      .from(
        { length: 30 },
        (_, index) => 2 + (index * 2)
      )
      .map((count) => ({
        value: count + '',
        label: count === 1 ? `1 ${personLabel}` : `${count} ${peopleLabel}`
      }))

    // Disponibilidad

    const {
      isValidAvailability,
      getAvailabilityOf,

      ensureAvailability,

      getAvailabilityByUUIDs,
      generateNextWeekEndFromAndToDates,
    } = useGetAwayAvailability()

    const hasValidAvailability = computed(() => {
      return isValidAvailability(getAvailabilityOf(getAway.uuid))
    })

    const getAwayAvailability = computed(() => {
      return ensureAvailability(getAvailabilityOf(getAway.uuid))
    })

    const selectedRoom = ref<GetAwayAvailabilityRoom | null>(null)
    function onRoomSelected(room: GetAwayAvailabilityRoom) {
      selectedRoom.value = room

      selectselectedBoardtypeOnRoomChanged()
      selectselectedRateFromAvailable()
    }

    const selectedBoardtype = ref<GetAwayAvailabilityBoardtype | null>(null)
    function onBoardtypeSelected(boardtype: GetAwayAvailabilityBoardtype) {
      selectedBoardtype.value = boardtype

      selectselectedRateFromAvailable()
    }

    const selectedRate = ref<GetAwayRate | null>(null)
    function onRateSelected(rate: GetAwayRate) {
      selectedRate.value = rate
    }

    function selectselectedRateFromAvailable() {
      selectedRate.value = null

      for (const rate of availableRates.value) {
        if (!selectedRate.value) {
          selectedRate.value = rate
          continue
        }

        if (rate.basePrice.amount < selectedRate.value.basePrice.amount) {
          selectedRate.value = rate
        }
      }
    }

    function selectselectedBoardtypeOnRoomChanged() {
      if (!boardtypesAvailable.value.length) {
        selectedBoardtype.value = null
        return
      }

      if (!selectedBoardtype.value) {
        selectedBoardtype.value = boardtypesAvailable.value[0]
        return
      }

      let found = false
      boardtypesAvailable.value.forEach((boardtype) => {
        const current = selectedBoardtype.value as GetAwayAvailabilityBoardtype

        if (current.type === boardtype.type) {
          found = true
          selectedBoardtype.value = boardtype
        }
      })

      if (!found) {
        selectedBoardtype.value = boardtypesAvailable.value[0]
      }
    }

    const boardtypesAvailable = computed(() => {
      const room = selectedRoom.value

      if (!room) {
        return getAwayAvailability.value.boardtypesAvailable
      }

      return getAwayAvailability.value.boardtypesAvailable.filter((boardtype) => {
        let rates = getAwayAvailability.value.rates

        rates = rates.filter((rate) => rate.room.type === room.type)
        rates = rates.filter((rate) => rate.boardtype.type === boardtype.type)

        return !!rates.length
      })
    })

    const availableRates = computed(() => {
      let rates = getAwayAvailability.value.rates

      const room = selectedRoom.value
      if (room) {
        rates = rates.filter((rate) => rate.room.type === room.type)
      }

      const boardtype = selectedBoardtype.value
      if (boardtype) {
        rates = rates.filter((rate) => rate.boardtype.type === boardtype.type)
      }

      return rates
    })

    const hasValidPrePurchaseLink = computed(() => {
      if (!hasValidAvailability.value) {
        return false
      }

      if (!selectedRate.value) {
        return false
      }

      return true
    })

    const prePurchaseQuery = computed(() => {
      if (!hasValidAvailability.value) {
        return {}
      }

      if (!selectedRate.value) {
        return {}
      }

      const lang = runtimeConfig.public.language

      const pre = GetAwayPrePurchase.create(getAwayAvailability.value, selectedRate.value, lang)
      return {
        purchase: GetAwayPrePurchase.toEncoded(pre)
      }
    })

    let isFetchingAvailabilityLoading = ref(false)
    async function onDateRangeOrPaxChanged() {
      if (!hasSelectedPax.value) {
        pushPaxSelection({ value: '2', label: 'pax' })
        return
      }

      if (!hasValidDateRange.value) {
        return
      }

      if (isFetchingAvailabilityLoading.value) {
        return
      }

      checkIfIsDate(selectedDateFrom.value)
      checkIfIsDate(selectedDateTo.value)

      isFetchingAvailabilityLoading.value = true
      LoadingEventBus.show()
      try {
        await getAvailabilityByUUIDs(
          [getAway.uuid],
          selectedDateFrom.value,
          selectedDateTo.value,
          Number(selectedPaxValue.value)
        )
      } catch (e) {  }
      LoadingEventBus.hide()
      isFetchingAvailabilityLoading.value = false

      selectedRoom.value = null
      selectedBoardtype.value = null

      if (!hasValidAvailability.value) {
        return
      }

      if (getAwayAvailability.value.roomsAvailable.length) {
        selectedRoom.value = getAwayAvailability.value.roomsAvailable[0]
      }

      selectselectedBoardtypeOnRoomChanged()
      selectselectedRateFromAvailable()
    }

    // Fin Disponibilidad

    onMounted(() => {
      onPaxChanged(onDateRangeOrPaxChanged)
      onDateRangeChanged(onDateRangeOrPaxChanged)

      // Normalizamos el número de personas
      if (!hasSelectedPax.value) {
        pushPaxSelection({ label: 'pax', value: '2' })
      } else {
        const pax = GetAway.normalizePaxNumber(Number(selectedPaxValue.value))
        pushPaxSelection({ label: 'pax', value: pax.toString() })
      }

      // Petición de dispo normal
      setTimeout(onDateRangeOrPaxChanged, 300)

      // Petición de dispo cuando no hay fechas seleccionadas.
      setTimeout(() => {
        if (isFetchingAvailabilityLoading.value) {
          return
        }

        if (!hasSelectedPax.value) {
          return
        }

        if (!hasValidDateRange.value) {
          const { from, to } = generateNextWeekEndFromAndToDates()
          pushDateRangeInput(from, to)
        }
      }, 400)
    })

    const getAway = await useFetchProxy<GetAway>(
      '/api/getaway/get-getaway-by-uri',
      {
        method: 'post',
        body: { uri: path }
      },
    )

    const location = getAway.spa.location

    const title = translate(getAway.texts).name
    const description = translate(getAway.texts).description

    const firstImage = getAway.images.length ? getAway.images[0].url : ''

    const spaName = getAway.spa.name
    const spaDescription = translate(getAway.spa.texts).description

    const hotelDescription = (() => {
      const desc = translateSilent(getAway.hotel.texts)
      if (!desc) {
        return ''
      }

      return desc.description
    })()

    const amenities = getAway.amenities
    const facilities = getAway.facilities

    // Imágenes de habitación, instalaciones y demás
    const miniDataImages = computed(() => {
      const miniData = []

      if (selectedBoardtype.value && selectedBoardtype.value.images.length) {
        const image = selectedBoardtype.value.images[0]
        miniData.push({
          image,
          name: selectedBoardtype.value.name,
        })
      }

      const getAwayMiniData = GetAway.getMiniDataImages(getAway)
      return miniData.concat(getAwayMiniData).slice(0, 3)
    })

    useHead(getGetAwayDetailMetadata(path, getAway))

    // Valores para los Richsnippets de Google
    const score = (() => {
      if (getAway.hotel.category) {
        return getAway.hotel.category
      }

      if (getAway.spa.score) {
        return getAway.spa.score
      }

      // NOTE: Recomendación de David Rodríguez. Si no ha nota que mostrar, ponemos 4 por defecto.
      return 4
    })()
    const maxScore = 5
    const reviewCount = 1

    return {
      title,
      description,
      amenities,
      facilities,
      location,

      spaName,
      spaDescription,
      hotelDescription,

      // Richsnippets
      score,
      maxScore,
      reviewCount,
      firstImage,

      // Hotel
      miniDataImages,

      // Selector Pax
      selectedPaxValue,
      paxOptions,
      selectedPaxOptionLabel,
      pushPaxSelection,

      getAway,
      hasValidAvailability,
      getAwayAvailability,

      // Disponibilidad
      availableRates,
      boardtypesAvailable,

      // Selección de disponibilidad
      selectedRoom,
      selectedBoardtype,
      selectedRate,

      hasValidPrePurchaseLink,
      prePurchaseQuery,

      onRoomSelected,
      onBoardtypeSelected,
      onRateSelected,
    }
  },
})
</script>

<style lang="scss" scoped>
.get-away-details {
  @apply py-2;

  &__header {
    @apply pt-4;
    @apply pb-2;

    @apply px-4;
    @apply mb-2;

    @screen lg {
      @apply px-0;
    }

    &__title {
      @apply font-bold;
      @apply text-2xl;

      @apply text-spl-dark;

      @apply mb-2;
    }

    &__location {

      @screen md {
        @apply flex;
        @apply items-center;
        @apply gap-4;
      }
    }
  }

  &__gallery {
    @apply px-4;

    @screen lg {
      @apply px-0;
    }
  }

  &__amenities {
    &__title {
      @apply font-bold;
      @apply text-xl;

      @apply text-spl-primary-dark;
      @apply mb-2;
    }

    &__tags {
      @apply text-spl-dark;

      @apply grid;
      @apply grid-cols-2;
    }
  }

  &__mini-data-images {
    @apply pt-12;

    @apply grid grid-cols-2;
    @apply gap-6;

    @screen lg {
      @apply grid-cols-3;
    }

    &__img {
      @apply w-full;
      height: 7.4rem;
    }

    &__title {
      @apply py-1;

      @apply text-base;
    }
  }

  &__main-info {
    @apply flex;
    @apply flex-col-reverse;
    @apply gap-4;

    @apply px-4;
    @apply py-4;

    @screen lg {
      @apply grid;
      @apply gap-12;

      @apply grid-cols-2;

      @apply px-0;
      @apply py-8;
    }
  }

  &__availability {
    @apply pt-4;
    @apply pb-8;
    @apply px-0;

    @screen md {
      @apply px-12;
      @apply py-12;
    }

    &__fields {
      @apply flex;
      @apply flex-col;

      @apply gap-2;
      @apply mb-2;
    }
  }

  &__no-availability-primary {
    @apply py-2;
  }

  &__no-availability-secondary {
    @apply py-4;

    @apply text-spl-acent-dark;
    @apply text-center;
  }

  &__booking-link {
    @apply pt-8;
  }

  &__spa {
    @apply grid;
    @apply grid-cols-1;
    @apply gap-4;

    @apply px-4;

    @screen lg {
      @apply gap-12;
      @apply grid;
      grid-template-columns: 60% 40%;

      @apply px-0;
    }

    &__facilities {
      @apply py-8;

      @screen md {
        @apply pt-0;
        @apply pb-4;
      }

      &__title {
        @apply font-semibold text-xl;
        @apply text-spl-primary-dark;

        @apply mb-4;
      }

      &__list {
        @apply text-spl-dark;

        @apply grid;
        @apply grid-cols-2;
      }
    }
  }

  &__hotel {
    @apply pb-4;

    &__description {
      @apply px-4;

      @screen lg {
        @apply px-0;
      }
    }
  }
}
</style>

