<template>
  <div>
    <nav
      v-if="isCheckout"
      class="menu-checkout"
    >
      <img src="~/assets/images/MobileLogoColor.svg">
    </nav>
    <nav
      v-else
      class="menu-mobile"
    >
      <div class="menu-header">
        <div class="menu-header__logo">
          <a :href="domain">
            <img
              src="~/assets/images/MobileLogoNegative.svg"
              alt="logo"
            >
          </a>
        </div>
        <div class="menu-header__options">
          <div class="menu-header__options__location-selector">
            <div v-if="langSelectorItems.length">
              <DesktopLinkCollection
                :content="langSelectorItems"
                rel-alternate
                no-lang
                hide-labels
              />
            </div>
            <SessionLocationSelector />
          </div>
          <i @click="toggleMenu()">
            <MenuIcon class="menu-header__options__button" />
          </i>
        </div>
      </div>
      <transition name="fade">
        <div
          v-if="isMenuDisplayed"
          class="menu-body"
        >
          <div class="menu-header">
            <a :href="domain">
              <img
                src="~/assets/images/MobileLogoNegative.svg"
                alt="logo"
                class="logo"
              >
            </a>
            <div class="menu-header__options">
              <i
                class="menu-header__options__close-button"
                @click="toggleMenu()"
              >
                <TimesIcon />
              </i>
            </div>
          </div>
          <div class="menu-body__buttons">
            <i class="menu-body__buttons__shopping-cart">
              <ShoppingCartIcon size="lg" />
            </i>
            <div class="exchange-button">
              <a
                href="https://spalopiabusiness.com/spalopia-com-agenda/"
                target="_blank"
              >
                <DynLang
                  capitalize-first
                  by-key="are-you-spa"
                />
              </a>
            </div>
          </div>

          <div
            v-if="langSelectorItems.length"
            class="menu-body__items"
          >
            <MobileLinkCollection
              :content="langSelectorItems"
              rel-alternate
              no-lang
            />
          </div>

          <hr>

          <div
            v-if="isBlackFriday"
            class="menu-body__items"
            style="margin-bottom: -10px"
          >
            <div class="menu-item">
              <SplNuxtLink
                :to="promoBlackFridayLink"
                is-external
              >
                🛍️ BlackFriday
              </SplNuxtLink>
            </div>
          </div>
          <div
            v-if="isMothersDay"
            class="menu-body__items"
            style="margin-bottom: -10px"
          >
            <div class="menu-item">
              <SplNuxtLink
                :to="`/promociones/dia-de-la-madre/`"
                is-external
              >
                <DynLang
                  capitalize-first
                  by-key="mothers-day"
                /> ❤️
              </SplNuxtLink>
            </div>
          </div>
          <div
            v-if="isFathersDay"
            class="menu-body__items"
            style="margin-bottom: -10px"
          >
            <div class="menu-item">
              <a :href="`${domain}/promociones/dia-del-padre/`"> Día del Padre ❤️ </a>
            </div>
          </div>
          <div
            v-if="isChristmas && promoChristmasLink"
            class="menu-body__items"
            style="margin-bottom: -10px"
          >
            <div class="menu-item-promo">
              <GiftIcon
                class="menu-item-promo__icon"
                variant="christmas"
              />
              <SplNuxtLink
                is-external
                no-lang
                :to="promoChristmasLink"
              >
                <Lang
                  capitalize-all
                  by-key="christmas-special"
                />
              </SplNuxtLink>
            </div>
          </div>
          <div
            v-if="isValentines && promoValentinesLink"
            class="menu-body__items"
            style="margin-bottom: -10px"
          >
            <div class="menu-item">
              <SplNuxtLink
                is-external
                no-lang
                :to="promoValentinesLink"
              >
                ❤️ <Lang by-key="saint-valentines" />
              </SplNuxtLink>
            </div>
          </div>
          <MobileLinkCollection
            :content="menuPrimary"
          />
          <MobileLinkCollection
            :content="menuSecondary"
            class="menu-body__secondary-content"
          />
          <div class="menu-body__footer">
            <div class="menu-body__footer__social-media">
              <div class="social-media">
                <a
                  href="https://www.facebook.com/Spalopia/"
                  target="_blank"
                  rel="nofollow"
                >
                  <img
                    class="social-media__item"
                    src="~/assets/images/mobile/facebook.svg"
                  >
                </a>
                <a
                  href="https://www.instagram.com/Spalopia/"
                  target="_blank"
                  rel="nofollow"
                >
                  <img
                    class="social-media__item"
                    src="~/assets/images/mobile/instagram.svg"
                  >
                </a>
                <a
                  href="https://www.linkedin.com/company/spalopia"
                  target="_blank"
                  rel="nofollow"
                >
                  <img
                    class="social-media__item"
                    src="~/assets/images/mobile/linkedin.svg"
                  >
                </a>
              </div>
            </div>
            <div class="menu-body__footer__slogan">
              <div class="slogan">
                <div>
                  <img
                    src="~/assets/images/mobile/sloganImage.svg"
                    class="menu-body__footer_slogan__image"
                  >
                  <div class="slogan__text">
                    <p>
                      <DynLang
                        capitalize-first
                        by-key="relax"
                        capitalize-all
                      />
                    </p>
                    <p>
                      <DynLang
                        capitalize-first
                        by-key="connect"
                        capitalize-all
                      />
                    </p>
                    <p>
                      <DynLang
                        capitalize-first
                        by-key="share"
                        capitalize-all
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <p class="menu-body__footer__license">
              <DynLang
                capitalize-first
                by-key="footer-company-code"
              />: I-AV-0004809.1
            </p>
            <div
              class="menu-body__footer__sponsors"
            >
              <div class="sponsors">
                <img src="~/assets/images/mobile/visa.svg">
                <img src="~/assets/images/mobile/mastercard.svg">
                <img src="~/assets/images/mobile/pay-pal.svg">
                <img src="~/assets/images/mobile/ssl.png">
              </div>
            </div>
            <div class="menu-body__footer__year">
              <p>spalopia {{ year }}</p>
            </div>
          </div>
        </div>
      </transition>
    </nav>
  </div>
</template>

<script lang="ts">
import config from '@/core/ts/server-config'

import SplNuxtLink from '../shared/SplNuxtLink.vue'

import MenuIcon from '@/core/components/shared/icons/Menu.icon.vue'
import TimesIcon from '@/core/components/shared/icons/Times.icon.vue'
import GiftIcon from '../shared/icons/Gift.icon.vue'
import ShoppingCartIcon from '@/core/components/shared/icons/ShoppingCart.icon.vue'
import SessionLocationSelector from './session-location/SessionLocationSelector.vue'
import MobileLinkCollection from '../shared/link-collection/MobileLinkCollection.vue'
// TODO: Unificar y/o refactorizar los selectores de links y el select-box
import DesktopLinkCollection from '../shared/link-collection/DesktopLinkCollection.vue'

import type { MenuItem } from '~/core/ts/entity/MenuItem'

export default defineNuxtComponent({
  components: {
    SplNuxtLink,
    MenuIcon,
    TimesIcon,
    GiftIcon,
    ShoppingCartIcon,
    SessionLocationSelector,
    MobileLinkCollection,
    DesktopLinkCollection,
  },
  props: {
    menuPrimary: {
      type: Array as PropType<Array<MenuItem>>,
      default: () => [],
    },
    menuSecondary: {
      type: Array as PropType<Array<MenuItem>>,
      default: () => [],
    },
    langSelectorItems: {
      type: Array as PropType<Array<MenuItem>>,
      default: () => [],
    },
    promoBlackFridayLink: {
      type: String,
      default: () => '',
    },
    promoChristmasLink: {
      type: String,
      default: () => '',
    },
    promoValentinesLink: {
      type: String,
      default: () => '',
    },
  },
  setup() {
    const route = useRoute()

    const runtimeConfig = useRuntimeConfig()
    const domain = runtimeConfig.public.domain

    const isBlackFriday = config.promos.isBlackFriday
    const isChristmas = config.promos.isChristmas
    const isFathersDay = config.promos.isFathersDay
    const isMothersDay = config.promos.isMothersDay
    const isValentines = config.promos.isValentines

    const isMenuDisplayed = ref(false)
    function toggleMenu() {
      isMenuDisplayed.value = !isMenuDisplayed.value
    }

    const isCheckout = route.path.includes('checkout') && !route.path.includes(config.urls.serviceBookingUrl)


    const year = new Date().getFullYear()

    return {
      domain,
      isCheckout,
      isBlackFriday,
      isChristmas,
      isFathersDay,
      isMothersDay,
      isValentines,
      isMenuDisplayed,
      toggleMenu,

      year,
    }
  }
})
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.menu-checkout {
  @apply p-8 pb-3;
}

.menu-mobile {
  background-image: url('~/assets/images/DesktopHeader.webp');
  background-size: auto 100%;
  @apply py-6;
  @apply px-6;
}

.menu-header {
  @apply flex justify-between;
  @apply items-center;
  @apply h-full;

  &__logo {
    @apply w-140p;
    @apply flex-grow;
    @apply flex-shrink-0;
    @apply mt-4;

    img {
      @apply w-140p;
    }
  }

  &__options {
    @apply flex-grow;
    @apply text-right;
    @apply h-70p;
    @apply ml-5;
    @apply inline-flex justify-end;
    @apply pb-0p;

    &__location-selector {
      @apply flex items-center;
      @apply pr-2;
    }

    &__button {
      @apply mt-25p;
      @apply mr-12p;
    }
    &__close-button {
      @apply mt-10p;
    }
  }
}

.menu-item {
  @apply flex;
  @apply items-center;
  @apply gap-2;

  @apply py-4;
  @apply pl-4;

  @apply text-spl-gray-1;
  @apply text-lg;

  &__icon {
    @apply mt-2.5p mr-5p;
  }
}

.menu-item-promo {
  @extend .menu-item;

  @apply font-bold;
}

.menu-body {
  @apply p-8;
  @apply fixed top-0 left-0;
  @apply bg-spl-dark;
  @apply z-10;
  @apply overflow-auto;
  height: 100vh;
  width: 100vw;
  .menu-header {
    @apply h-auto;
    &__options {
      @apply border-none;
    }
  }
  &__items {
    @apply mt-10;
  }

  &__secondary-content {
  }

  &__buttons {
    @apply flex justify-around;
    @apply mt-16;
    @apply mb-40p;
    &__shopping-cart {
      @apply flex items-center;
      @apply px-9;
      @apply border-white border-solid border-r  border-opacity-20;
      @apply my-2;
    }
  }
  &__footer {
    @apply mt-16;
    &__slogan {
      @apply mt-12;

      .slogan {
        // @apply mt-12;
        @apply flex justify-center;
        @apply text-xl;
        &__text {
          @apply mt-8;
          letter-spacing: 5.25px;
          @apply text-white;
        }
      }
    }
    &__license {
      @apply text-spl-gray-3;
      @apply text-xs;
      @apply mt-80p;
      @apply text-center;
      line-height: 0.7rem;
    }
    &__sponsors {
      @apply mt-25p;

      .sponsors {
        @apply flex justify-around;
      }
    }
    &__year {
      @apply mt-6;
      @apply flex justify-center;
      @apply text-white;
      @apply text-xs;
    }
  }
}
.social-media {
  @apply flex justify-center;
  &__item {
    @apply mx-1.5;
  }
}

.exchange-button {
  @apply rounded-xl;
  @apply border-solid;
  @apply border-2;
  @apply border-spl-acent-dark;

  @apply px-8;
  @apply py-2;

  @apply text-white;

  @apply inline-flex;
  @apply items-center;
  @apply whitespace-nowrap;
  @apply font-bold;
}
</style>
